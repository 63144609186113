import { ColumnDef } from '@tanstack/react-table';

import { IMovementsAccount } from '../../Interfaces/IMovementsDay';
import plainText from '../../lang/es.json';
import { formatNumberDecimals, parseNumber } from '../../utils/numbers';

// Columnas
const CMovimientoColumns: ColumnDef<IMovementsAccount>[] = [
  {
    accessorFn: row => row.cuenta.nombreBanco,
    id: 'Banco',
    header: plainText.message.consolidadoTablaConfig.headerBank,
    sortingFn: 'alphanumeric',
  },
  {
    accessorFn: row => row.cuenta.nroCuenta,
    id: 'Cuenta',
    header: plainText.message.consolidadoTablaConfig.headerAccount,
    sortingFn: 'alphanumeric',
  },
  {
    accessorFn: row => row.cuenta.tipoCta,
    id: 'Tipo',
    header: plainText.message.consolidadoTablaConfig.headerType,
    sortingFn: 'alphanumeric',
  },
  {
    accessorFn: row => row.cuenta.cuentaDenom,
    id: 'Denominación',
    header: plainText.message.consolidadoTablaConfig.headerDenomination,
    sortingFn: 'alphanumeric',
  },
  {
    accessorFn: row => row.movimiento.fechaHoraMovimiento.toString().split('T')[1],
    id: 'Hora',
    header: plainText.message.consolidadoTablaConfig.headerHour,
    sortingFn: 'alphanumeric',
  },
  {
    accessorFn: row => row.movimiento.concepto,
    id: 'Concepto (Cod. op.)',
    header: plainText.message.consolidadoTablaConfig.headerConcept,
    sortingFn: 'alphanumeric',
  },
  {
    accessorFn: row => row.movimiento.codigoOperacion,
    id: 'Cod. op.',
    header: plainText.message.consolidadoTablaConfig.headerCodOp,
    sortingFn: 'alphanumeric',
  },
  {
    accessorFn: row => row.movimiento.nroComprobante,
    id: 'Comprobante',
    header: plainText.message.consolidadoTablaConfig.headerVoucher,
    sortingFn: 'alphanumeric',
  },
  {
    accessorFn: row => row.movimiento.depositante ?? '',
    id: 'Depositante',
    header: plainText.message.consolidadoTablaConfig.headerDepositor,
    sortingFn: 'alphanumeric',
  },
  {
    accessorFn: row => row.movimiento.sucursalOrigen,
    id: 'Suc',
    header: plainText.message.consolidadoTablaConfig.headerOffice,
    sortingFn: 'alphanumeric',
  },
  {
    accessorFn: row => parseNumber(`${row.movimiento.importe}`),
    id: 'Importe',
    cell: info => (
      <span style={{ color: (info.getValue() as number) >= 0 ? 'black' : 'red' }}>
        {info.row.original.movimiento.moneda} {formatNumberDecimals({ numberToFormat: info.getValue().toString() })}
      </span>
    ),
    header: plainText.message.consolidadoTablaConfig.headerImport,
    sortingFn: 'basic',
  },
  {
    accessorFn: row => row.movimiento.descripcion ?? '',
    id: 'Descripción',
    header: plainText.message.consolidadoTablaConfig.headerDescription,
    sortingFn: 'alphanumeric',
  },
  {
    accessorFn: row => row.movimiento.codopeban,
    id: 'Cod. op. bco.',
    header: plainText.message.consolidadoTablaConfig.headercodOpeBan,
    sortingFn: 'alphanumeric',
  },
  {
    accessorFn: row => row.movimiento.pcc,
    id: 'P.C.C.',
    header: plainText.message.consolidadoTablaConfig.headerPCC,
    sortingFn: 'alphanumeric',
  },
  {
    accessorFn: row => row.movimiento.cuitDenom,
    id: 'cuit',
    header: plainText.message.consolidadoTablaConfig.headerCUIT,
    sortingFn: 'alphanumeric',
  },
  {
    accessorFn: row => row.movimiento.cuitDenom,
    id: 'Den_ordenante',
    header: plainText.message.consolidadoTablaConfig.headerCUITDenom,
    sortingFn: 'alphanumeric',
  },
];

export default CMovimientoColumns;
