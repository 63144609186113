// eslint-disable-next-line import/no-unresolved
import { getToken } from '@react-ib-mf/style-guide-ui';
import axios from 'axios';

import mockBankData from '../../test/__mocks__/BanksData/mock';
import { mockConsolidadSaldo } from '../../test/__mocks__/ConsolidateBalance/mock';
import consumptionCardResponseMock from '../../test/__mocks__/ConsumptionCard/mock';
import consumptionPlanBannerResponseMock from '../../test/__mocks__/ConsumptionPlanBanner/mock';
import movementsDayFilter from '../../test/__mocks__/MovementsDay/dataFilter';
import offeringNotificationResponseMock from '../../test/__mocks__/OfferingNotification/mock';
import thirdLevelMenuResponseMock from '../../test/__mocks__/thirdLevelMenu/thirdLevelMenu-mock';
import { BankBoxesData } from '../Interfaces/IBankBoxes';
import { ConsolidadoSaldo, IDataBase } from '../Interfaces/IConsolidateBalance';
import { IConsolidadoSaldosInput } from '../Interfaces/IConsSaldos';
import { IConsumptionCard, IConsumptionPlan } from '../Interfaces/IConsumption';
import { IFilteredAccounts, IMovementsDayInput } from '../Interfaces/IMovementsDay';
import IOfferingNotification from '../Interfaces/IOfferingNotification';
import { IThirdLevelMenu } from '../Interfaces/IThirdLevelMenu';
import { ApiPortalSignin } from '../views/signinAPIPortal';

axios.defaults.baseURL = process.env.API_BASE_URL;
axios.defaults.headers.common.Authorization = `Bearer ${getToken()}`;
if (process.env.API_USER_KEY) {
  axios.defaults.headers.common.user_key = process.env.API_USER_KEY;
}

const getAccounts = (
  servCodific: 'IyRMovimientos' | 'IyRConsolidadoSaldos',
  infRepcode: 'CONSOLIDADO_SALDOS' | 'MOVIMIENTOS_DIA',
  moneda: string, // '$' | 'USD'
) => axios.get(`/home/consultas/cuentas/filtradas?codigoServicio=${servCodific}&moneda=${moneda}&codigoReporte=${infRepcode}`);
const getAccountsMock = () => {
  return new Promise<IFilteredAccounts>(resolve => {
    setTimeout(() => {
      return resolve(movementsDayFilter);
    }, 3000);
  });
};

const getBankBalance = () => axios.get('home/bancos/saldos/disponibles');
const getBankBalanceMock = () => {
  return new Promise<BankBoxesData>(resolve => {
    setTimeout(() => {
      return resolve(mockBankData);
    }, 3000);
  });
};

const getUniqueBankSaldo = (bcoIdbco: string) => axios.get(`/home/bancos/${bcoIdbco}/saldos/disponibles`);

const getConsolidadoSaldos = () => axios.get<IDataBase>('home/saldos/consolidado').then(res => res.data);
const getConsolidadoSaldosMock = () => {
  return new Promise<ConsolidadoSaldo[]>(resolve => {
    setTimeout(() => {
      return resolve(mockConsolidadSaldo);
    }, 3000);
  });
};

const getConsultasTransferencias = () => axios.get('/home/consultas/transferencias');

const getConsumptionsCard = () => axios.get('/home/entities/offerings/consumptions');
const getConsumptionsCardMock = () => {
  return new Promise<IConsumptionCard>(resolve => {
    setTimeout(() => {
      return resolve(consumptionCardResponseMock);
    }, 1000);
  });
};

const getConsumptionsPlanBanner = () => axios.get('/home/entities/plans');
const getConsumptionsPlanBannerMock = () => {
  return new Promise<IConsumptionPlan>(resolve => {
    setTimeout(() => {
      return resolve(consumptionPlanBannerResponseMock);
    }, 1000);
  });
};

const getDayMovements = () => axios.get('/home/movimientos/consolidado');
const getHomeAlertEmailsActions = () => axios.get('/home/v1/persons/contacts-info/actions');

const getOfferingNotification = () => axios.get('/home/entities/offerings/notifications');
const getOfferingNotificationMock = () => {
  return new Promise<IOfferingNotification>(resolve => {
    setTimeout(() => {
      return resolve(offeringNotificationResponseMock);
    }, 1000);
  });
};

const getPasswordNotification = () => axios.get('/home/users/notifications');

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getThirdLevelMenu = (idMenu: string) => axios.get(`home/menu/${idMenu}`);

const getThirdLevelMenuMock = () => {
  return new Promise<IThirdLevelMenu>(resolve => {
    setTimeout(() => {
      return resolve(thirdLevelMenuResponseMock);
    }, 1000);
  });
};

const postConsolidatedBalanceTableData = (fetchOptions: IConsolidadoSaldosInput) => axios.post('home/saldos/cuentas', fetchOptions);
const postMovementsDay = (data: IMovementsDayInput) => axios.post('/home/movimientos', data);
const postRegisterApiPortal = (data: ApiPortalSignin) => axios.post('/home/api/users', data);
const postRegisterApiPortalMock = objRequest => {
  return new Promise(resolve => {
    setTimeout(() => {
      return resolve(objRequest);
    }, 3000);
  });
};

const putFavoriteBankBalance = data => axios.put('home/favoritos', data);

export {
  getAccounts,
  getAccountsMock,
  getBankBalance,
  getBankBalanceMock,
  getConsolidadoSaldos,
  getConsolidadoSaldosMock,
  getConsultasTransferencias,
  getConsumptionsCard,
  getConsumptionsCardMock,
  getConsumptionsPlanBanner,
  getConsumptionsPlanBannerMock,
  getDayMovements,
  getHomeAlertEmailsActions,
  getOfferingNotification,
  getOfferingNotificationMock,
  getPasswordNotification,
  getThirdLevelMenu,
  getThirdLevelMenuMock,
  getUniqueBankSaldo,
  postConsolidatedBalanceTableData,
  postMovementsDay,
  postRegisterApiPortal,
  postRegisterApiPortalMock,
  putFavoriteBankBalance,
};
