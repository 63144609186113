// eslint-disable-next-line import/no-unresolved
import { pushAnalyticsEvent } from '@react-ib-mf/style-guide-ui';
import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { getOfferingNotification } from '../../services/api';
import Alert from '../Alert';

function OfferingNotification() {
  const { data: offeringNotificationData } = useQuery('offering-notification', () => getOfferingNotification().then(res => res?.data), {
    retry: false,
  });

  useEffect(() => {
    if (offeringNotificationData?.data?.title) {
      pushAnalyticsEvent({
        event: 'impression_modal',
        modal_name: offeringNotificationData?.data?.title,
      });
    }
  }, [offeringNotificationData]);

  const navigate = useNavigate();

  const navigateConsumption = () => {
    pushAnalyticsEvent({
      event: 'click_modal',
      modal_name: offeringNotificationData?.data?.title,
      item_id: offeringNotificationData?.data?.linkText,
    });

    navigate('consumo');
  };

  const pushAnalyticsCloseAlert = () => {
    pushAnalyticsEvent({
      event: 'click_modal',
      modal_name: offeringNotificationData?.data?.title,
      item_id: 'Cerrar',
    });
  };

  const alertVariant = {
    INFO: {
      variant: 'info',
    },
    UPDATE: {
      variant: 'success',
    },
    WARNING: {
      variant: 'warning',
    },
    CAUTION: {
      variant: 'warning',
    },
  };

  if (!offeringNotificationData?.success) {
    return null;
  }

  return (
    <Alert
      customStyles={{ marginRight: '40px', marginBottom: '16px' }}
      variant={alertVariant[offeringNotificationData?.data?.type].variant || 'info'}
      title={offeringNotificationData?.data?.title}
      description={offeringNotificationData?.data?.description}
      buttonLabel={offeringNotificationData?.data?.linkText}
      OnClick={navigateConsumption}
      onDismiss={pushAnalyticsCloseAlert}
    />
  );
}

export default OfferingNotification;
