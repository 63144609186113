import { Radio, RadioGroup, Stack } from '@chakra-ui/react';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';

import plainText from '../../lang/es.json';
import currenncyFormat from '../../utils/currenncy';
import { formatNumberDecimals } from '../../utils/numbers';
import { IData } from './IMovementsCard';
import StyledBox from './StyledBox';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function MovementsCard({
  data,
  onChange,
  total,
  currencies,
  defaultCurrency,
}: {
  data: IData;
  onChange;
  total: number;
  currencies: Array<{ key: string; text: string; disabled: boolean }>;
  defaultCurrency: string;
}) {
  const [currency, setCurrency] = useState(defaultCurrency);

  const style = getComputedStyle(document.body);

  const max = Math.max(...data.datasets[0].data);

  const getNumbers = (d: number) => {
    const arr = [1];
    for (let i = 1; i < d; i += 1) {
      arr.push(0);
    }
    return Math.trunc(parseFloat(arr.join('')));
  };

  const options = {
    indexAxis: 'y' as const,
    plugins: {
      legend: {
        display: false,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          color: style?.getPropertyValue('--chakra-colors-grey-60'),
          display: true,
          offset: false,
        },
        ticks: {
          callback(value, index) {
            return index % 3 === 0 ? currenncyFormat.currencyFormatDE(value) : '';
          },
          stepSize: 5,
          autoSkip: true,
          maxRotation: 0,
          minRotation: 0,
          display: true,
          maxTicksLimit: 10,
        },
        stacked: true,
        max: max ? Math.trunc(max + getNumbers(Math.trunc(max).toString().length)) : 0,
        min: 0,
      },
      y: {
        grid: {
          color: style?.getPropertyValue('--chakra-colors-grey-60'),
          display: false,
        },
      },
    },
  };

  const setValue = (value: string) => {
    setCurrency(value);
    onChange(value);
  };

  useEffect(() => {
    setCurrency(defaultCurrency);
  }, [defaultCurrency]);

  return (
    <StyledBox>
      <div className='container'>
        <div className='graph-style'>
          <Bar redraw data={data} options={options} />
        </div>
        <div className='currency-box'>
          <span className='currency-title'>{plainText.label.money}</span>
          <RadioGroup onChange={setValue} value={currency}>
            {currencies.map(value => {
              return (
                <div key={value.key}>
                  <Stack direction='column'>
                    <Radio className='currency' isDisabled={value.disabled} value={value.key} name='radioCurrency'>
                      {value.text}
                    </Radio>
                  </Stack>
                </div>
              );
            })}
          </RadioGroup>
          <div className='total-amount'>
            <span className='title'>{plainText.label.netTotal}</span>
            <span
              className={`amount ${total < 0 && 'red-text'}`}
              style={{
                fontSize: (currency === '$' && total > 999999999 && '0.8rem') || (currency === 'USD' && total > 9999999 && '0.8rem'),
              }}
            >
              {`${currency === '$' ? '$' : 'U$S'} ${formatNumberDecimals({
                numberToFormat: total,
                defaultValue: '0,00',
              })}`}
            </span>
          </div>
        </div>
      </div>
    </StyledBox>
  );
}

export default MovementsCard;
