// eslint-disable-next-line import/no-unresolved
import { pushAnalyticsEvent, useCriticalError } from '@react-ib-mf/style-guide-ui';
import { useEffect, useState } from 'react';

import plainText from '../../lang/es.json';
import Alert from '../Alert';

function CriticalError() {
  const [title, setTitle] = useState(null);
  const [criticalError] = useCriticalError();

  const pushAnalyticsReload = () => {
    if (criticalError?.analiticOnReload) {
      pushAnalyticsEvent(criticalError.analiticOnReload);
    }

    window.location.reload();
  };

  useEffect(() => {
    setTitle(criticalError?.title);
  }, [criticalError]);

  if (!title?.length) {
    return null;
  }

  return (
    <Alert
      customStyles={{ marginRight: '40px', marginBottom: '16px' }}
      variant='destructive'
      title={title}
      description={plainText.message.criticalErrorDescription}
      buttonLabel={plainText.button.reload}
      OnClick={pushAnalyticsReload}
    />
  );
}

export default CriticalError;
