import { Button, ButtonGroup, Stack, Text } from '@chakra-ui/react';
import Select from 'react-select';

import { FONT_CUSTOM_SELECT_CHECKBOX } from '../../designTokens/fonts';
import plainText from '../../lang/es.json';
import Option from './Option';

export interface IPropsFilter {
  listOptions?: object[];
  onSelectOption: (e: unknown) => void;
  selectedOptions?: object[];
  onClearOptions: () => void;
  isDisabledFilters: boolean;
  onApplyFilters: () => void;
  isLoadingData: boolean;
}

function CustomSelectCheckbox({
  listOptions,
  onSelectOption,
  selectedOptions,
  onClearOptions,
  isDisabledFilters,
  onApplyFilters,
  isLoadingData,
}: IPropsFilter) {
  const isLoading = isLoadingData || isDisabledFilters;
  const disabledClearFilter = isLoadingData || selectedOptions.length <= 0;

  return (
    <Stack ml='0' mr='30px'>
      <Text textStyle='h4Bold' fontSize={FONT_CUSTOM_SELECT_CHECKBOX.fontSize} m={0}>
        {plainText.message.customSelectCheckbox.title}
      </Text>
      <Select
        components={{ Option }}
        options={listOptions}
        closeMenuOnSelect={false}
        closeMenuOnScroll={false}
        isMulti
        hideSelectedOptions={false}
        noOptionsMessage={() => plainText.message.customSelectCheckbox.noOptionMessage}
        placeholder={plainText.placeHolder.enterAccountOrDenom}
        onChange={e => onSelectOption(e)}
        value={selectedOptions}
        isClearable={false}
        isDisabled={isLoadingData}
      />

      <ButtonGroup>
        <Button variant='tertiary-outline' width='200px' my='10px' disabled={disabledClearFilter} onClick={() => onClearOptions()}>
          {plainText.button.cleanFilter}
        </Button>
        <Button variant='tertiary' width='200px' my='10px' disabled={isLoading} onClick={() => onApplyFilters()}>
          {plainText.button.applyFilter}
        </Button>
      </ButtonGroup>
    </Stack>
  );
}

export default CustomSelectCheckbox;
