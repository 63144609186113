import { Text } from '@chakra-ui/react';
import React, { CSSProperties } from 'react';
import styled from 'styled-components';

interface StyledAmountProps {
  number: string | number;
  symbol?: string;
  wholeNumberTextStyle?: string;
  wholeNumberAdditionalStyle?: CSSProperties;
  decimalnumberTextStyle?: string;
  decimalnumberAdditionalStyle?: CSSProperties;
  customDataTestId?: string;
}

function StyledAmount({
  number,
  symbol,
  wholeNumberTextStyle = 'titleLg',
  wholeNumberAdditionalStyle,
  decimalnumberTextStyle = 'bodySm',
  decimalnumberAdditionalStyle,
  customDataTestId,
}: StyledAmountProps) {
  const finalWholeNumber = String(number).split(',')[0];
  const finalDecimalNumber = String(number).split(',')[1];

  return (
    <StyledStyledAmount data-testid={customDataTestId}>
      <Text textStyle={wholeNumberTextStyle} style={wholeNumberAdditionalStyle}>
        {symbol} {finalWholeNumber}
      </Text>
      <Text textStyle={decimalnumberTextStyle} style={decimalnumberAdditionalStyle}>
        {finalDecimalNumber}
      </Text>
    </StyledStyledAmount>
  );
}

export default StyledAmount;

const StyledStyledAmount = styled.div`
  display: flex;
`;
