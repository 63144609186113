import { Text } from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { HeaderBase } from '@react-ib-mf/header-menu-ui';
import React from 'react';
import styled from 'styled-components';

import plainText from '../lang/es.json';

const frequentQuestions = () => {
  return (
    <StyledfrequentQuestions>
      <HeaderBase
        breadcrumb={[
          { id: '0', name: plainText.breadcrumb.frequentQuestion.itemName1, linkTo: '/' },
          { id: '1', name: plainText.breadcrumb.frequentQuestion.itemName2 },
        ]}
      />
      <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
        <div className='containterTitle'>
          <Text className='containterTitle__titleH1' textStyle='h1'>
            Preguntas frecuentes: operaciones y servicios
          </Text>
          <Text textStyle='bodyMd'>
            ¿Necesitás ayuda o tenés preguntas? ¡Chateá con Ibo, nuestro asistente virtual! Lo encontrás en la parte de abajo del menú
            lateral.
          </Text>
        </div>

        <div className='container'>
          <div className='cardDiv'>
            <Text className='titleDiv' textStyle='titleXl'>
              Pagos AFIP
            </Text>
            <div className='contentDiv'>
              <Text textStyle='bodyLg' className='subTextItem'>
                Para retomar un VEP:
              </Text>
              <Text textStyle='bodyLg' className='textItem'>
                1. Ingresá a <span className='textSpan'>Pagos</span>.
              </Text>
              <Text textStyle='bodyLg' className='textItem'>
                2. Elegí la opción <span className='textSpan'>AFIP</span>.
              </Text>
            </div>
          </div>

          <div className='cardDiv'>
            <Text textStyle='titleXl' className='titleDiv'>
              Interpagos
            </Text>
            <div className='contentDiv'>
              <Text textStyle='bodyLg' className='subTextItem'>
                Para realizar transferencias desde esta sección, seguí estos pasos:
              </Text>
              <Text textStyle='bodyLg' className='textItem'>
                1. Ingresá al módulo de <span className='textSpan'>Pagos</span>.
              </Text>
              <Text textStyle='bodyLg' className='textItem'>
                2. Elegí <span className='textSpan'>Interpagos</span>.
              </Text>
              <Text textStyle='bodyLg' className='textItem'>
                3. Seleccioná <span className='textSpan'>Consultas y Pagos</span>.
              </Text>
            </div>
          </div>

          <div className='cardDiv'>
            <Text textStyle='titleXl' className='titleDiv'>
              Otros pagos
            </Text>
            <div className='contentDiv'>
              <Text textStyle='bodyLg' className='subTextItem'>
                Dependiendo del servicio contratado, podés realizar transferencias entre cuentas propias, proveedores, sueldos y depósitos
                judiciales en la siguiente sección:
              </Text>
              <Text textStyle='bodyLg' className='textItem'>
                1. Ingresá al módulo de <span className='textSpan'>Transferencias</span>.
              </Text>
              <Text textStyle='bodyLg' className='textItem'>
                2. Seleccioná <span className='textSpan'>Confeccionar</span>.
              </Text>
            </div>
          </div>

          <div className='cardDiv'>
            <Text textStyle='titleXl' className='titleDiv'>
              Transferencias pendientes
            </Text>
            <div className='contentDiv'>
              <Text textStyle='bodyLg' className='textItem'>
                Podés gestionar tus pendientes de autorización o envío en el módulo de <span className='textSpan'>Transferencias</span>.
                Tené en cuenta que la fecha de solicitud no debe ser de días anteriores.
              </Text>
            </div>
          </div>

          <div className='cardDiv'>
            <Text textStyle='titleXl' className='titleDiv'>
              CUIT vinculado para pagos AFIP
            </Text>
            <div className='contentDiv'>
              <Text textStyle='bodyLg' className='subTextItem'>
                Para agregar una persona física o empresa a pagos AFIP:
              </Text>
              <Text textStyle='bodyLg' className='textItem'>
                1. Ingresá a la sección <span className='textSpan'>Administración</span>.
              </Text>
              <Text textStyle='bodyLg' className='textItem'>
                2. Elegí <span className='textSpan'>Bancos y cuentas</span>.
              </Text>
            </div>
          </div>

          <div className='cardDiv'>
            <Text textStyle='titleXl' className='titleDiv'>
              Reporte de transferencias
            </Text>
            <div className='contentDiv'>
              <Text textStyle='bodyLg' className='subTextItem'>
                Para ver tu reporte:
              </Text>
              <Text textStyle='bodyLg' className='textItem'>
                1. Ingresá al módulo de <span className='textSpan'>Transferencias</span>.
              </Text>
              <Text textStyle='bodyLg' className='textItem'>
                2. Seleccioná la opción <span className='textSpan'>Historiales</span>.
              </Text>
            </div>
          </div>

          <div className='cardDiv'>
            <Text textStyle='titleXl' className='titleDiv'>
              Alta o ajuste de servicio y banco
            </Text>
            <div className='contentDiv'>
              <Text textStyle='bodyLg' className='subTextItem'>
                Si necesitás hacer el alta o modificación de servicios o bancos, seguí estos pasos:
              </Text>
              <Text textStyle='bodyLg' className='textItem'>
                1. Ingresá al módulo <span className='textSpan'>Administración</span>.
              </Text>
              <Text textStyle='bodyLg' className='textItem'>
                2. Elegí la opción <span className='textSpan'>ABM Configuración de datos</span>.
              </Text>
              <Text textStyle='bodyLg' className='textItem'>
                3. Ingresá a <span className='textSpan'>Plan contratado</span>.
              </Text>
            </div>
          </div>

          <div className='cardDiv'>
            <Text textStyle='titleXl' className='titleDiv'>
              Datos de empresa
            </Text>
            <div className='contentDiv'>
              <Text textStyle='bodyLg' className='subTextItem'>
                Para acceder a los datos de tu empresa o modificarlos:
              </Text>
              <Text textStyle='bodyLg' className='textItem'>
                1. Ingresá al módulo <span className='textSpan'>Administración</span>.
              </Text>
              <Text textStyle='bodyLg' className='textItem'>
                2. Elegí la opción <span className='textSpan'>Bancos y cuentas</span>.
              </Text>
              <Text textStyle='bodyLg' className='textContainer'>
                En esta sección podés ver los datos generales e impositivos, así como los datos de contacto y de la cuenta de facturación.
              </Text>
            </div>
          </div>

          <div className='cardDiv'>
            <Text textStyle='titleXl' className='titleDiv'>
              Preconfección de acreedor
            </Text>
            <div className='contentDiv'>
              <Text textStyle='bodyLg' className='subTextItem'>
                Para continuar con una preconfección, seguí estos pasos:
              </Text>
              <Text textStyle='bodyLg' className='textItem'>
                1. Ingresá a <span className='textSpan'>Pagos</span>.
              </Text>
              <Text textStyle='bodyLg' className='textItem'>
                2. Elegí <span className='textSpan'>Preconfección BtoB propias</span>.
              </Text>
              <Text textStyle='bodyLg' className='textItem'>
                3. Seleccioná los volantes de pago que quieras abonar.
              </Text>
            </div>
          </div>

          <div className='cardDiv'>
            <Text textStyle='titleXl' className='titleDiv'>
              Pago manual
            </Text>
            <div className='contentDiv'>
              <Text textStyle='bodyLg' className='subTextItem'>
                Para confeccionar una transferencia:
              </Text>
              <Text textStyle='bodyLg' className='textItem'>
                1. Ingresá al módulo de <span className='textSpan'>Transferencias</span>.
              </Text>
              <Text textStyle='bodyLg' className='textItem'>
                2. Elegí la opción <span className='textSpan'>Confeccionar</span>.
              </Text>
              <Text textStyle='bodyLg' className='textContainer'>
                Deberás completar los pasos para autorizar y enviar el pago.
              </Text>
            </div>
          </div>

          <div className='cardDiv'>
            <Text textStyle='titleXl' className='titleDiv'>
              Pago de boleta automática
            </Text>
            <div className='contentDiv'>
              <Text textStyle='bodyLg' className='textItem'>
                Los pagos BtoB automáticos se gestionan desde la página de la empresa y luego utilizan Interbanking como medio de pago, en
                donde se confirma y autoriza el envío. Si tenés dudas sobre cómo hacer estos pagos, verificá el instructivo de tu proveedor.{' '}
              </Text>
            </div>
          </div>
        </div>
      </div>
    </StyledfrequentQuestions>
  );
};

const StyledfrequentQuestions = styled.div`
  .containterTitle {
    width: auto;
    display: flex;
    padding-top: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;

    &__titleH1 {
      max-width: 950px;
      color: var(--chakra-colors-grey-120);
      margin: 0px;
      line-height: 50px;
    }

    &__subtitleH1 {
      color: var(--chakra-colors-grey-120);
      max-width: 950px;
      align-self: stretch;
      line-height: 20px;
      letter-spacing: 0.25px;
      margin: 0px;
    }
  }

  .container {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 24px;
    gap: 12px;
    color: var(--chakra-colors-grey-120);
    align-self: stretch;
  }

  .titleDiv {
    display: flex;
    width: auto;
    padding-top: 24px;
    align-items: center;
    margin: 0px;
  }

  .contentDiv {
    display: flex;
    width: 1032px;
    height: auto;
    flex-direction: column;
    justify-content: center;
    p:first-child {
      margin-bottom: 1rem;
    }
  }

  .textSpan {
    font-weight: 700;
    line-height: 22px;
  }

  .textItem {
    margin: 0px;
  }

  .subTextItem {
    margin-top: 0px;
  }

  .cardDiv {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .textContainer {
    margin-bottom: 0px;
  }
`;

export default frequentQuestions;
