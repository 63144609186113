import styled from 'styled-components';

const StyledConsumption = styled.div`
  margin-right: 30px;

  p {
    margin-top: 0px;
  }

  .consumption {
    &__title {
      margin-bottom: 8px;
    }

    &__description {
      margin-bottom: 12px;
    }

    &__content {
      &__subtitle {
        margin-bottom: 8px;
      }

      &__subtitle-description {
        margin-bottom: 12x;
      }
    }
  }

  .chakra-alert {
    margin-bottom: 16px;
  }
`;

const StyledErrorSectionConsumption = styled.div`
  p {
    margin-top: 0px;
  }

  .error-section {
    padding-left: 0px;

    &__description {
      margin-bottom: 24px;
    }

    &__action {
      color: var(--chakra-colors-primary-100);
      font-family: Inter;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0.1px;
      text-decoration-line: underline;
    }
  }
`;

export { StyledConsumption, StyledErrorSectionConsumption };
