import { Box, Button, Link as ChakraLink } from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { HeaderBase } from '@react-ib-mf/header-menu-ui';
import { Link } from 'react-router-dom';

import plainText from '../../../lang/es.json';
import StyledRegisterSuccess from './StyledRegisterSuccess';

function RegisterSuccess() {
  return (
    <StyledRegisterSuccess>
      <HeaderBase
        breadcrumb={[
          { id: '0', name: plainText.breadcrumb.registerSuccessAPIPortal.itemName1, linkTo: '/' },
          { id: '1', name: plainText.breadcrumb.registerSuccessAPIPortal.itemName2 },
          { id: '2', name: plainText.breadcrumb.registerSuccessAPIPortal.itemName3, linkTo: '/api-portal' },
          { id: '3', name: plainText.breadcrumb.registerSuccessAPIPortal.itemName4 },
        ]}
      />
      <Box className='background' px={4}>
        <div className='bgleft' />
        <div className='mid'>
          <h1 className='title'>{plainText.message.registerSuccessAPIPortal.title}</h1>
          <div className='title noMargin'>{plainText.message.registerSuccessAPIPortal.subtitle}</div>
          <p className='description '>{plainText.message.registerSuccessAPIPortal.description}</p>
          <div className='centered'>
            <ChakraLink href={process.env.API_PORTAL_URL} isExternal>
              <Button colorScheme='blue'>{plainText.button.enterAPIPortal}</Button>
            </ChakraLink>
            <Button as={Link} to='/' className='volver' colorScheme='blue' variant='primary-text'>
              {plainText.button.back}
            </Button>
          </div>
        </div>
        <div className='bgrigth' />
      </Box>
    </StyledRegisterSuccess>
  );
}

export default RegisterSuccess;
