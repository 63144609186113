import 'react-alice-carousel/lib/alice-carousel.css';

import { Button, Modal, ModalOverlay } from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { useWindowDimensions } from '@react-ib-mf/style-guide-ui';
import React, { useContext, useMemo, useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import { IBankBoxes } from '../../Interfaces/IBankBoxes';
import BankBoxesCard from '../BankBoxesCard';
import BankBoxesPopup from '../BankBoxesPopup';
import { BankBoxesContext } from '.';
import { StyledBankBoxes, StyledCarouselDot } from './styled';

function BankBoxes({ bankBalance, putFavoriteBankBalance, isOpen, onClose, bankBoxesStatus }: IBankBoxes) {
  function onSlideChanged(event) {
    setActiveIndex(event.item);
  }

  const { bankBoxes: banks } = useContext(BankBoxesContext);

  let { width } = useWindowDimensions();
  if (width === undefined) width = 900;

  const urlSiib = useMemo(() => {
    return 'menuRoot%3Dconsultas.infoFinancCuentas%26menuSelection%3Dconsultas%7CinfoFinancCuentas%7CconsolidadoDeSaldos';
  }, []);

  // Set the banks array and sort it using favorito value
  const banksArray = useMemo(() => {
    return banks?.data?.bancos
      .sort((a, b) => {
        if (!a.orden) return 1;

        if (!b.orden) return -1;

        return a.orden - b.orden;
      })
      .sort((a, b) => Number(b.favorito) - Number(a.favorito));
  }, [banks]);

  // Define the required states to feed and contorl the carousel
  const [activeIndex, setActiveIndex] = useState(0);

  // Calculate the number of bankboxes per group using carouselWidth/bankBoxWidth
  const BANKBOXES_WIDTH = 318;
  const SIDE_BAR_WIDTH = 185;
  const bankBoxesPerGroup = Math.floor((width - SIDE_BAR_WIDTH) / BANKBOXES_WIDTH);

  // Define if it's required show the carousel
  const showCarousel = bankBoxesPerGroup < banksArray?.length;

  // Create bankboxes cards
  const bankBoxes = banksArray?.map((bankData, index) => {
    return (
      <BankBoxesCard
        bankData={bankData}
        permitirConsolidadoSaldos={bankBalance?.data?.permitirConsolidadoSaldos}
        isLast={index === banksArray.length - 1}
        key={`bank_boxes_card_${bankData.bcoIdbco}`}
        urlToRedirect={urlSiib}
        bankBoxesStatus={bankBoxesStatus}
      />
    );
  });

  // Set a default container
  let bankBoxesContainer = <div className='boxes'>{bankBoxes}</div>;

  // if the carousel is present, it's required to define the alice carousel properties
  if (showCarousel) {
    const carouselBankBoxes = [];
    const numBankBoxesGroup = Math.ceil(bankBoxes.length / bankBoxesPerGroup);
    let bankBoxesCounter = 0;

    for (let i = 0; i < numBankBoxesGroup; i += 1) {
      const carouselBankBoxesAux = [];
      for (let j = 0; j < bankBoxesPerGroup; j += 1) {
        if (bankBoxesCounter < bankBoxes.length) {
          carouselBankBoxesAux.push(bankBoxes[bankBoxesCounter]);
          bankBoxesCounter += 1;
        } else {
          break;
        }
      }
      carouselBankBoxes.push(<div className='carousel-bank-boxes'>{carouselBankBoxesAux}</div>);
    }

    const renderCarouselPrevButton = ({ isDisabled }) => {
      if (!isDisabled) {
        return <Button className='alice-carousel__control-btn' leftIcon={<FiChevronLeft />} />;
      }
      return null;
    };

    const renderCarouselNextButton = ({ isDisabled }) => {
      if (!isDisabled) {
        return <Button className='alice-carousel__control-btn' leftIcon={<FiChevronRight />} />;
      }
      return null;
    };

    const renderCarouselDotsItem = ({ isActive }) => {
      return <StyledCarouselDot className={isActive ? 'active-dot' : 'inactive-dot'} />;
    };

    // Update the container value to have the carousel
    bankBoxesContainer = (
      <AliceCarousel
        data-testid='carousel-bankboxes'
        items={carouselBankBoxes}
        autoWidth
        activeIndex={activeIndex}
        renderDotsItem={renderCarouselDotsItem}
        renderPrevButton={renderCarouselPrevButton}
        renderNextButton={renderCarouselNextButton}
        // eslint-disable-next-line react/jsx-no-bind
        onSlideChanged={onSlideChanged}
      />
    );
  }

  if (banksArray.length === 0) {
    return null;
  }

  return (
    <>
      <StyledBankBoxes>{bankBoxesContainer}</StyledBankBoxes>

      <Modal isOpen={isOpen} onClose={onClose} isCentered preserveScrollBarGap autoFocus={false}>
        <ModalOverlay />
        <BankBoxesPopup onClosePopup={onClose} putFavoriteBankBalance={putFavoriteBankBalance} />
      </Modal>
    </>
  );
}

export default BankBoxes;
