import { Box } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import ContentMovimientosDia from '../components/ConsolidadoMovimientosDia';
import HeaderSib from '../components/HeaderSib/HeaderSib';
import { IMovements } from '../components/MovementsCard/IMovementsCard';
import plainText from '../lang/es.json';
import { getDayMovements } from '../services/api';
import { formatNumberDecimals } from '../utils/numbers';

function MovimientosDia() {
  const { data: dataMovements }: IMovements = useQuery('movimientos', () =>
    getDayMovements().then(res => {
      return { data: res.data.data };
    }),
  );

  // Get current money currency to set the right value
  const location = useLocation();
  const currentPath = '/movimientos-dia/';
  const moneyCurrency = location.pathname.split(currentPath)[1] || '$';

  // Define the values to create the subtitle message
  const [subtitleTotal, setSubtitleTotal] = useState<string>('');
  const [subtitleCurrency, setSubtitleCurrency] = useState<string>('');

  useEffect(() => {
    if (dataMovements !== undefined && dataMovements?.data?.length > 0) {
      const rightDataMovement =
        (dataMovements && dataMovements.data.filter(dataMovement => dataMovement.moneda === moneyCurrency)[0]) || dataMovements?.data[0];

      // Set the values using the right currency
      setSubtitleTotal(formatNumberDecimals({ numberToFormat: rightDataMovement.total }));
      setSubtitleCurrency(rightDataMovement.moneda);
    }
  }, [dataMovements, moneyCurrency]);

  return (
    <Box h='full' w='full'>
      <HeaderSib
        title={plainText.message.movementCardTitle}
        subtitle={
          <span>
            {plainText.label.netTotalTwoPoints}
            <span style={{ color: parseFloat(subtitleTotal) < 0 ? 'red' : 'inherit' }}>{`${subtitleCurrency} ${subtitleTotal}`}</span>
          </span>
        }
      />
      <ContentMovimientosDia />
    </Box>
  );
}

export default MovimientosDia;
