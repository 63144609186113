import styled from 'styled-components';

const StyledPermissionMessage = styled.div`
  color: #2f3034;
  text-align: center;
  display: grid;
  place-items: center;
  margin-top: -15px;
  height: 100%;
  width: 100%;
`;

export default StyledPermissionMessage;
