import { ColumnDef } from '@tanstack/react-table';

import { ConsolidadoSaldosTabla } from '../../Interfaces/IConsSaldos';
import plainText from '../../lang/es.json';
import { formatNumberDecimals, parseNumber } from '../../utils/numbers';

// Columnas
const CSaldosColumns: ColumnDef<ConsolidadoSaldosTabla>[] = [
  {
    accessorFn: row => row.nombreBanco,
    id: 'Banco',
    header: plainText.message.consolidadoTablaConfig.headerBank,
    sortingFn: 'alphanumeric',
  },
  {
    accessorFn: row => row.nroCuenta,
    id: 'Cuenta',
    header: plainText.message.consolidadoTablaConfig.headerAccount,
    sortingFn: 'alphanumeric',
  },
  {
    accessorFn: row => row.tipoCta,
    id: 'Tipo',
    header: plainText.message.consolidadoTablaConfig.headerType,
    sortingFn: 'alphanumeric',
  },
  {
    accessorFn: row => row.cuentaDenom,
    id: 'Denominación',
    header: plainText.message.consolidadoTablaConfig.headerDenomination,
    sortingFn: 'alphanumeric',
  },
  {
    accessorFn: row => row.propVinc,
    id: 'Titularidad',
    header: plainText.message.consolidadoTablaConfig.headerOwnership,
    sortingFn: 'alphanumeric',
  },
  {
    accessorFn: row => parseNumber(row.saldoContable),
    id: 'Saldo contable',
    cell: info => (
      <span style={{ color: (info.getValue() as number) >= 0 ? 'black' : 'red' }}>
        {info.row.original.moneda} {formatNumberDecimals({ numberToFormat: info.getValue().toString() })}
      </span>
    ),
    header: plainText.message.consolidadoTablaConfig.headerCountableBalance,
    sortingFn: 'basic',
  },
  {
    accessorFn: row => parseNumber(row.saldoInicioDia),
    id: 'Saldo inicial',
    cell: info => (
      <span style={{ color: (info.getValue() as number) >= 0 ? 'black' : 'red' }}>
        {info.row.original.moneda} {formatNumberDecimals({ numberToFormat: info.getValue().toString() })}
      </span>
    ),
    header: plainText.message.consolidadoTablaConfig.headerInitialBalance,
    sortingFn: 'basic',
  },
  {
    accessorFn: row => parseNumber(row.saldoOperativoActual),
    id: 'Operativo actual',
    cell: info => (
      <span style={{ color: (info.getValue() as number) >= 0 ? 'black' : 'red' }}>
        {info.row.original.moneda} {formatNumberDecimals({ numberToFormat: info.getValue().toString() })}
      </span>
    ),
    header: plainText.message.consolidadoTablaConfig.headerCurrentOperating,
    sortingFn: 'basic',
  },
  {
    accessorFn: row => parseNumber(row.proyeccion24Hs),
    id: 'Proyectado 24Hs',
    cell: info => (
      <span style={{ color: (info.getValue() as number) >= 0 ? 'black' : 'red' }}>
        {info.row.original.moneda} {formatNumberDecimals({ numberToFormat: info.getValue().toString() })}
      </span>
    ),
    header: plainText.message.consolidadoTablaConfig.headerProjected24H,
    sortingFn: 'basic',
  },
  {
    accessorFn: row => parseNumber(row.proyeccion48Hs),
    id: 'Proyectado 48Hs',
    cell: info => (
      <span style={{ color: (info.getValue() as number) >= 0 ? 'black' : 'red' }}>
        {info.row.original.moneda} {formatNumberDecimals({ numberToFormat: info.getValue().toString() })}
      </span>
    ),
    header: plainText.message.consolidadoTablaConfig.headerProjected48H,
    sortingFn: 'basic',
  },
];

export default CSaldosColumns;
