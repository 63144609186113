import React, { useContext, useMemo } from 'react';

import { CurrencySymbol, IBankBoxesCardAmount } from '../../Interfaces/IBankBoxes';
import plainText from '../../lang/es.json';
import { formatNumberDecimals } from '../../utils/numbers';
import { BankBoxesContext } from '../BankBoxes';

function BankBoxesCardAmount({ amount, currency }: IBankBoxesCardAmount) {
  const { isSaldoVisible } = useContext(BankBoxesContext);
  const {
    message: {
      bankBoxesCard: { hiddenChar },
    },
    label: { currencyKeyU$D, negativeCurrencySymbol, currencyKeyPesos },
  } = plainText;

  const currencySymbol: CurrencySymbol = {
    dollars: currencyKeyU$D,
    pesos: currencyKeyPesos,
  };

  const formattedNumberWithoutNegativeSymbol = useMemo(() => {
    const formattedNumber = formatNumberDecimals({ numberToFormat: amount });
    return formattedNumber.startsWith('-') ? formattedNumber.substring(1) : formattedNumber;
  }, [amount]);

  const isPositiveAmount = parseFloat(amount) >= 0;

  if (!isSaldoVisible) return <p className={`account_box__${currency}`}>{`${currencySymbol[currency]} ${hiddenChar}`}</p>;

  const [amountWithoutCents, cents] = formattedNumberWithoutNegativeSymbol.split(',');

  return (
    <p className={`account_box__${currency} ${isPositiveAmount ? 'positive-balance' : 'negative-balance'}`}>
      {!isPositiveAmount && `${negativeCurrencySymbol}`} {`${currencySymbol[currency]} ${amountWithoutCents} `}
      <sup>{cents}</sup>
    </p>
  );
}

export default BankBoxesCardAmount;
