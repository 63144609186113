export const hasSeeConsolidatedBalances = (permissionList: string[]): boolean => {
  return !!permissionList.find((permission: string) => {
    return permission === 'IR.CuentasCorrientes.ConsolidadoSaldos';
  });
};

export const hasSeeMovementsOfTheDay = (permissionList: string[]): boolean => {
  return !!permissionList.find((permission: string) => {
    return permission.startsWith('IR.CuentasCorrientes.InformeMovimientos');
  });
};

export const hasSeeUnreportedInterfaces = (permissionList: string[]): boolean => {
  return !!permissionList.find((permission: string) => {
    return permission === 'IR.CuentasCorrientes.ConsolidadoSaldos';
  });
};

export const hasSeeStatusOfTransfers = (permissionList: string[]): boolean => {
  return !!permissionList.find((permission: string) => {
    return permission.startsWith('IR.Transferencias');
  });
};

export const hasSeeBankBalances = (permissionList: string[]): boolean => {
  return !!permissionList.find((permission: string) => {
    return permission === 'IR.CuentasCorrientes.ConsolidadoSaldos';
  });
};
