import styled from 'styled-components';

import {
  FONT_BANK_BOXES_POPUP_ACCOUNT_BOX,
  FONT_BANK_BOXES_POPUP_ACCOUNTS,
  FONT_BANK_BOXES_POPUP_AVATAR,
  FONT_BANK_BOXES_POPUP_DOLLARS,
  FONT_BANK_BOXES_POPUP_PESOS,
  FONT_BANK_BOXES_POPUP_SUBTITLE,
  FONT_BANK_BOXES_POPUP_TITLE,
} from '../../designTokens/fonts';

const StyledBankBoxesPopupContent = styled.div`
  .bankboxes-popup-content {
    &__exit {
      z-index: 2;
      & button {
        background: none;
        border: none;
        height: 30px;
        top: 25px;
        right: 25px;
        & svg {
          width: 15px;
          height: 15px;
        }
        :hover {
          cursor: pointer;
          background: var(--chakra-colors-grey-40);
        }
      }
    }
    &__body {
      &__title {
        font-family: ${FONT_BANK_BOXES_POPUP_TITLE.fontFamily};
        font-style: ${FONT_BANK_BOXES_POPUP_TITLE.fontStyle};
        font-weight: ${FONT_BANK_BOXES_POPUP_TITLE.fontWeight};
        font-size: ${FONT_BANK_BOXES_POPUP_TITLE.fontSize};
        line-height: ${FONT_BANK_BOXES_POPUP_TITLE.lineHeight};
        text-align: center;
        margin: 0px 0px 25px 0px;
      }
      &__sub-title {
        font-family: ${FONT_BANK_BOXES_POPUP_SUBTITLE.fontFamily};
        font-style: ${FONT_BANK_BOXES_POPUP_SUBTITLE.fontStyle};
        font-weight: ${FONT_BANK_BOXES_POPUP_SUBTITLE.fontWeight};
        font-size: ${FONT_BANK_BOXES_POPUP_SUBTITLE.fontSize};
        line-height: ${FONT_BANK_BOXES_POPUP_SUBTITLE.lineHeight};
        margin: 12px 0px 33px 20px;
      }
      &__favorites {
        background-color: var(--chakra-colors-gray-50);
        border-radius: 16px;
        border: solid 1px var(--chakra-colors-neutral-40);
        padding: 20px 38px 10px 38px;
      }
      &__non-favorites {
        background-color: white;
        border-radius: 16px;
        border: solid 1px var(--chakra-colors-neutral-40);
        padding: 20px 38px 10px 38px;
      }
      &__actions {
        display: flex;
        align-content: center;
        justify-content: center;
        margin-top: 8px;
      }
    }
  }

  .box-container {
    display: flex;
    justify-content: start;
    margin-top: 20px;
    margin-bottom: 39px;
  }

  .account_box {
    width: 225px;
    height: 115px;
    background: white;
    border: 1px solid var(--chakra-colors-neutral-50);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    padding: 0px 18px;
    position: relative;
    box-shadow: 0px 3.58351px 17.9175px rgba(236, 226, 255, 0.6);
    font-family: ${FONT_BANK_BOXES_POPUP_ACCOUNT_BOX.fontFamily};
    font-style: ${FONT_BANK_BOXES_POPUP_ACCOUNT_BOX.fontStyle};
    & .chakra-avatar {
      position: absolute;
      left: 19px;
      top: -20px;
      width: 39.54px;
      height: 39.54px;
      border: solid 1px var(--chakra-colors-secondary-100);

      &__initials {
        color: var(--chakra-colors-tertiary-100);
        font-weight: ${FONT_BANK_BOXES_POPUP_AVATAR.fontWeight};
        font-size: ${FONT_BANK_BOXES_POPUP_AVATAR.fontSize};
      }
    }
    &__empty {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
    &__accounts {
      font-weight: ${FONT_BANK_BOXES_POPUP_ACCOUNTS.fontWeight};
      font-size: ${FONT_BANK_BOXES_POPUP_ACCOUNTS.fontSize};
      line-height: ${FONT_BANK_BOXES_POPUP_ACCOUNTS.lineHeight};
      color: var(--chakra-colors-tertiary-120);
      margin: 26px 0px 8px 0px;
    }
    &__pesos {
      font-weight: ${FONT_BANK_BOXES_POPUP_PESOS.fontWeight};
      font-size: ${FONT_BANK_BOXES_POPUP_PESOS.fontSize};
      line-height: ${FONT_BANK_BOXES_POPUP_PESOS.lineHeight};
      color: var(--chakra-colors-tertiary-120);
      margin: 0px 0px 4px 0px;
    }
    &__dollars {
      font-weight: ${FONT_BANK_BOXES_POPUP_DOLLARS.fontWeight};
      font-size: ${FONT_BANK_BOXES_POPUP_DOLLARS.fontSize};
      line-height: ${FONT_BANK_BOXES_POPUP_DOLLARS.lineHeight};
      color: var(--chakra-colors-neutral-90);
      margin: 0;
    }
    .negative-balance {
      color: var(--chakra-colors-danger-110);
    }
  }

  .alice-carousel {
    height: 174px;

    &__stage-item {
      width: auto !important;
    }

    &__stage {
      top: 20px;
    }
    &__wrapper {
      height: 136px; // 146px;
    }
    &__dots {
      // margin: 0px;
      margin: 11px 0px 0px 0px;
    }
    &__control-btn {
      position: absolute;
      top: 67px;
      cursor: pointer;
      color: var(--chakra-colors-tertiary-110);
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: var(--chakra-colors-grey-40);
        border-radius: 8px;
      }
    }
    &__prev-btn {
      padding: 0px;
      width: 0px;
      & svg {
        left: -36px;
      }
    }
    &__next-btn {
      padding: 0px;
      width: 0px;
      & svg {
        right: -36px;
      }
    }
  }

  .carousel-bank-boxes {
    display: flex;
    justify-content: start;

    & .box {
      margin-right: 24px;
    }
  }
`;

const CarouselDot = styled.div`
  margin: 0 5px;

  &.active-dot {
    background: var(--chakra-colors-tertiary-110);
    border-radius: 99px;
    height: 10px;
    width: 25px;
  }
  &.inactive-dot {
    background: var(--chakra-colors-secondary-60);
    border-radius: 50%;
    height: 10px;
    width: 10px;
  }
`;

export { CarouselDot, StyledBankBoxesPopupContent };
