// eslint-disable-next-line import/no-unresolved
import { HeaderBase } from '@react-ib-mf/header-menu-ui';
import React, { useEffect } from 'react';

import { IThirdLevelMenu } from '../../Interfaces/IThirdLevelMenu';
import plainText from '../../lang/es.json';
import iconsImages from '../../utils/iconsImages';
import ThirdLevelCard from './ThirdLevelCard';

function ThirdLevelMenu(dataThirdLevelMenu: IThirdLevelMenu) {
  const { data: dataThirdLevel } = dataThirdLevelMenu;
  const isValidPath = (url: string): boolean => {
    // You can add another element in this array depending the url that require images.
    const validPaths = ['/menu/Pagos/ddjjAfip'];
    return validPaths.some(validPath => validPath === url);
  };

  useEffect(() => {
    const breadcrumb: HTMLElement = document.querySelector('.title');
    if (breadcrumb) {
      breadcrumb.style.paddingTop = '12px';
    }
  }, []);
  const renderIcon = (index: number): string | null => {
    const validIcons = iconsImages.afip(dataThirdLevel.items.length);
    const pathName = window.location.pathname;
    const validParam = isValidPath(pathName);

    if (validParam) {
      return validIcons[index];
    }

    return null;
  };

  const thirdLevelCards = dataThirdLevel.items.map((item, index) => (
    <ThirdLevelCard
      key={item.id}
      titulo={item.titulo}
      descripcion={item.descripcion}
      url={item.url}
      openMode={item.openMode}
      breadcrumb={`${plainText.breadcrumb.thirdLevelMenu.itemName1}, ${dataThirdLevel.nameSecondLevel}, ${dataThirdLevel.titulo}`}
      newOption={item.newOption}
      icono={item.icono || renderIcon(index)}
    />
  ));

  return (
    <div className='third-level-menu'>
      <div className='third-level-menu__header'>
        <HeaderBase
          title={dataThirdLevel.titulo}
          description={dataThirdLevel.descripcion}
          breadcrumb={[
            { id: 1, name: plainText.breadcrumb.thirdLevelMenu.itemName1, linkTo: '/' },
            { id: 2, name: dataThirdLevel.nameSecondLevel },
            { id: 3, name: dataThirdLevel.titulo },
          ]}
        />
      </div>

      <div className='third-level-menu__cards-container'>{thirdLevelCards}</div>
    </div>
  );
}

export default ThirdLevelMenu;
