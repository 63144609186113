import 'react-alice-carousel/lib/alice-carousel.css';

import { Button, Icon, ModalBody, ModalCloseButton, ModalContent } from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { pushAnalyticsEvent, useErrorMessage, useToastOptions, useWindowDimensions } from '@react-ib-mf/style-guide-ui';
import React, { useContext, useEffect, useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import { FiChevronLeft, FiChevronRight, FiMinus, FiPlus, FiPlusCircle } from 'react-icons/fi';
import { useMutation } from 'react-query';

import { BankBoxesPopupInt, PutFavoriteBanks } from '../../Interfaces/IBankBoxes';
import plainText from '../../lang/es.json';
import { formatNumberDecimals } from '../../utils/numbers';
import { BankBoxesContext } from '../BankBoxes';
import Logo from '../BankBoxes/Logo';
import { CarouselDot, StyledBankBoxesPopupContent } from './styled';

function BankBoxesPopup({ onClosePopup, putFavoriteBankBalance }: BankBoxesPopupInt) {
  const pushAnalyticsAddFavorite = () => {
    pushAnalyticsEvent({
      event: 'add_shortcuts',
      content_group: 'Home',
      item_id: 'Agregar nuevo banco',
    });
  };

  const pushAnalyticsDeleteFavorite = () => {
    pushAnalyticsEvent({
      event: 'delete_shortcuts',
      content_group: 'Home',
      item_id: 'Quitar tarjeta banco',
    });
  };

  const pushAnalyticsClosePopup = () => {
    pushAnalyticsEvent({
      event: 'select_content',
      content_group: 'Home',
      content_type: 'Editar accesos directos',
      item_id: 'Cerrar',
    });
  };

  const pushAnalyticsErrorSaving = () => {
    pushAnalyticsEvent({
      event: 'impression_modal',
      content_group: 'Home',
      modal_name: 'Error de Servicio - home/favoritos',
    });
  };

  const { bankBoxes: banks, setBankBoxes } = useContext(BankBoxesContext);

  function getAddRemoveHandler(bankBoxId: string, remove: boolean, isBlocked: boolean) {
    if (!isBlocked) {
      if (remove) {
        return () => deleteFavorite(bankBoxId);
      }
      return () => addFavorite(bankBoxId);
    }
    return undefined;
  }

  function addFavorite(bankBoxId) {
    // Add analytics
    pushAnalyticsAddFavorite();

    // Get the selected bank
    const selectedBank = banksList.filter(bank => bank.id === bankBoxId)[0];
    // Get the favorite banks number before the change
    const favoriteBanksCounter = banksList.filter(bank => bank.favorito).length;

    // Update favorites order
    const favoriteBanksList = banksList.filter(bank => bank.favorito);
    favoriteBanksList.map((bank, index) => {
      const bankRef = bank;
      bankRef.orden = index + 1;
      return bank;
    });

    // Change the selected bank properties
    selectedBank.favorito = true;
    selectedBank.orden = favoriteBanksCounter + 1;
    // Sort all banks
    banksList.sort((a, b) => a.orden - b.orden);
    // Sort favorites
    favoriteBanksList.sort((a, b) => a.orden - b.orden);

    // Set new list
    setBanksList([...banksList]);
  }

  function deleteFavorite(bankBoxId) {
    // Add analytics
    pushAnalyticsDeleteFavorite();

    // Get the selected bank
    const selectedBank = banksList.filter(bank => bank.id === bankBoxId)[0];
    // Change the selected bank properties
    selectedBank.favorito = false;

    // Update favorites order
    banksList.map((bank, index) => {
      const bankRef = bank;
      bankRef.orden = bank.favorito ? index + 1 : 0;
      return bank;
    });

    // Put selected bank at the end
    banksList.push(banksList.splice(banksList.indexOf(selectedBank), 1)[0]);
    // Sort all banks
    banksList.sort((a, b) => a.orden - b.orden);

    // Set new list
    setBanksList([...banksList]);
  }

  function bankBoxesCreation(bankArray, isFavorite) {
    const favoriteBanksCounter = banksList.filter(bank => bank.favorito).length;
    const isBlocked = !!((isFavorite && favoriteBanksCounter === 1) || (!isFavorite && favoriteBanksCounter === maxFavoriteBankBoxes));

    const buttonBackgroundProp = isFavorite ? 'danger.100' : 'success.100';

    const bucleTimes = isFavorite ? maxFavoriteBankBoxes : bankArray.length;
    const bankBoxes = [];

    for (let index = 0; index < bucleTimes; index += 1) {
      const bankData = bankArray[index];
      const pesosBalance = formatNumberDecimals({ numberToFormat: bankData?.saldoPesos });
      const dollarBalance = formatNumberDecimals({ numberToFormat: bankData?.saldoDolares });

      bankBoxes.push(
        <div className='account_box' key={index} style={{ marginRight: index === bucleTimes - 1 ? '0px' : '24px' }}>
          {isFavorite && !bankData ? (
            <div className='account_box__empty'>
              <Icon as={FiPlusCircle} color='primary.100' h={10} w={10} />
            </div>
          ) : (
            <div>
              <Logo bank={bankData} showTooltip />
              <Button
                sx={actionBtn}
                background={buttonBackgroundProp}
                disabled={isBlocked}
                data-testid={`btn-${isFavorite ? 'deleteBank' : 'addBank'}-${bankData?.id}`}
                onClick={getAddRemoveHandler(bankData?.id, isFavorite, isBlocked)}
              >
                <Icon as={isFavorite ? FiMinus : FiPlus} color='white' />
              </Button>

              <p className='account_box__accounts'> {plainText.message.bankBoxesCard.tittle} </p>
              {bankData.saldoPesos !== '' && (
                <p className={`account_box__pesos ${parseFloat(bankData.saldoPesos) >= 0 ? 'positive-balance' : 'negative-balance'}`}>
                  {plainText.label.currencyKeyPesos} {pesosBalance}
                </p>
              )}

              {bankData.saldoDolares !== '' && (
                <p className={`account_box__dollars ${parseFloat(bankData.saldoDolares) >= 0 ? 'positive-balance' : 'negative-balance'}`}>
                  {plainText.label.currencyKeyU$D} {dollarBalance}
                </p>
              )}
            </div>
          )}
        </div>,
      );
    }

    return bankBoxes;
  }

  function carouselCreation(bankBoxesArray, activeIndex, onSlideChanged, numBankBoxesPerGroup) {
    const carouselBankBoxes = [];
    const numBankBoxesGroups = Math.ceil(bankBoxesArray.length / numBankBoxesPerGroup);
    let bankBoxesCounter = 0;

    for (let i = 0; i < numBankBoxesGroups; i += 1) {
      const carouselBankBoxesAux = [];
      for (let j = 0; j < numBankBoxesPerGroup; j += 1) {
        if (bankBoxesCounter < bankBoxesArray.length) {
          carouselBankBoxesAux.push(bankBoxesArray[bankBoxesCounter]);
          bankBoxesCounter += 1;
        } else {
          break;
        }
      }
      carouselBankBoxes.push(<div className='carousel-bank-boxes'>{carouselBankBoxesAux}</div>);
    }

    const renderCarouselPrevButton = ({ isDisabled }) => {
      if (!isDisabled) {
        return <Icon as={FiChevronLeft} h={9} w={9} className='alice-carousel__control-btn' />;
      }
      return null;
    };

    const renderCarouselNextButton = ({ isDisabled }) => {
      if (!isDisabled) {
        return <Icon as={FiChevronRight} h={9} w={9} className='alice-carousel__control-btn' />;
      }
      return null;
    };

    const renderCarouselDotsItem = ({ isActive }) => {
      return <CarouselDot className={isActive ? 'active-dot' : 'inactive-dot'} />;
    };

    return (
      <AliceCarousel
        items={carouselBankBoxes}
        autoWidth
        activeIndex={activeIndex}
        renderDotsItem={renderCarouselDotsItem}
        renderPrevButton={renderCarouselPrevButton}
        renderNextButton={renderCarouselNextButton}
        // eslint-disable-next-line react/jsx-no-bind
        onSlideChanged={onSlideChanged}
      />
    );
  }

  const saveChanges = () => {
    // Build the object to send
    const favoriteBanks = banksList.filter(bank => bank.favorito);
    const favoriteBanksObjList = favoriteBanks.map(bank => {
      return { idFavorito: bank.id, orden: bank.orden };
    });

    const requestBody = {
      tipo: 'banco',
      favoritos: favoriteBanksObjList,
    };

    onSubmit(requestBody);
  };

  const onSubmit = (newFavoriteBanks: PutFavoriteBanks) => {
    mutate(newFavoriteBanks);
  };

  const {
    mutate,
    error: errorFavoriteBanks,
    isError: isErrorFavoriteBanks,
    isSuccess: isSuccessFavoriteBanks,
    isLoading,
  } = useMutation(
    (newFavoriteBanks: PutFavoriteBanks) => {
      return putFavoriteBankBalance(newFavoriteBanks);
    },
    {
      onSuccess: () => {
        banksList.sort((a, b) => a.orden - b.orden);
        banksList.sort((a, b) => Number(b.favorito) - Number(a.favorito));

        setBankBoxes(prevState => {
          return {
            ...prevState,
            data: {
              ...prevState.data,
              bancos: [...banksList],
            },
          };
        });

        onClosePopup();
      },
      onError: () => {
        // Add analytics
        pushAnalyticsErrorSaving();
      },
    },
  );

  const [, setToastOptions] = useToastOptions();
  const { showErrorMessage } = useErrorMessage();

  useEffect(() => {
    if (isErrorFavoriteBanks) {
      showErrorMessage({ title: plainText.toast.error.bankBoxesPopup, error: errorFavoriteBanks });
    } else if (isSuccessFavoriteBanks) {
      setToastOptions({
        description: plainText.toast.success.bankBoxesPopup,
        status: 'success',
        autoClose: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorFavoriteBanks, errorFavoriteBanks, isSuccessFavoriteBanks]);

  const actionBtn = {
    height: '16px',
    width: '16px',
    minW: '0',
    p: '0',
    border: '0',
    borderRadius: '50%',
    position: 'absolute',
    top: '12px',
    right: '12px',
    _hover: {
      bg: 'var(--chakra-colors-tertiary-110)',
    },
    _focus: {
      bg: 'parent',
    },
    _disabled: {
      bg: 'var(--chakra-colors-grey-60)',
      cursor: 'default',
      _hover: {
        bg: 'var(--chakra-colors-grey-60)',
      },
    },
    _active: {},
  };

  const maxFavoriteBankBoxes = 4;
  const BANKBOXES_WIDTH = 249;
  const POPUP_WIDTH = 1127;
  const SIDE_BAR_WIDTH = 136;

  let { width } = useWindowDimensions();
  if (width === undefined) width = 900;

  const bankBoxesPerGroup = width > POPUP_WIDTH ? maxFavoriteBankBoxes : Math.floor((width - SIDE_BAR_WIDTH) / BANKBOXES_WIDTH);

  // All bank list creation
  const auxBanksArray = JSON.parse(JSON.stringify(banks?.data?.bancos));
  const [banksList, setBanksList] = useState(auxBanksArray);

  // Favorite bankboxes creation
  let favoriteBankList = banksList.filter(bank => bank.favorito);

  if (favoriteBankList.length === 0) {
    for (let i = 0; i < maxFavoriteBankBoxes; i += 1) {
      banksList[i].favorito = true;
    }
  }
  favoriteBankList = banksList.filter(bank => bank.favorito);
  favoriteBankList.sort((a, b) => a.orden - b.orden);

  const favoriteBanksBoxes = bankBoxesCreation(favoriteBankList, true);
  const showFavoriteBanksCarousel = bankBoxesPerGroup < favoriteBanksBoxes.length;

  let favoriteBanksContent = <div className='box-container'>{favoriteBanksBoxes}</div>;

  const [activeIndexFavoriteBanks, setActiveIndexFavoriteBanks] = useState(0);
  function onSlideChangedFavoriteBanks(event) {
    setActiveIndexFavoriteBanks(event.item);
  }

  if (showFavoriteBanksCarousel) {
    favoriteBanksContent = carouselCreation(favoriteBanksBoxes, activeIndexFavoriteBanks, onSlideChangedFavoriteBanks, bankBoxesPerGroup);
  }

  // Non-favorite bankboxes creation
  const nonFavoriteBanksBoxes = bankBoxesCreation(
    banksList.filter(bank => !bank.favorito),
    false,
  );
  const showNonFavoriteBanksCarousel = bankBoxesPerGroup < nonFavoriteBanksBoxes.length;

  let nonFavoriteBanksContent = <div className='box-container'>{nonFavoriteBanksBoxes}</div>;

  const [activeIndexNonFavoriteBanks, setActiveIndexNonFavoriteBanks] = useState(0);
  function onSlideChangedNonFavoriteBanks(event) {
    setActiveIndexNonFavoriteBanks(event.item);
  }

  if (showNonFavoriteBanksCarousel) {
    nonFavoriteBanksContent = carouselCreation(
      nonFavoriteBanksBoxes,
      activeIndexNonFavoriteBanks,
      onSlideChangedNonFavoriteBanks,
      bankBoxesPerGroup,
    );
  }

  return (
    <StyledBankBoxesPopupContent>
      <ModalContent className='bankboxes-popup-content' data-testid='bankboxes-popup' maxW='1110px' borderRadius='16px'>
        <div className='bankboxes-popup-content__exit'>
          <ModalCloseButton aria-label='bankboxes_popup-exit-button' onClick={pushAnalyticsClosePopup} />
        </div>

        <ModalBody className='bankboxes-popup-content__body' p='25px 30px 18px 30px'>
          <div className='bankboxes-popup-content__body__title'>
            <span>{plainText.message.bankBoxesPopup.title}</span>
          </div>

          <div className='bankboxes-popup-content__body__favorites'>{favoriteBanksContent}</div>

          <div className='bankboxes-popup-content__body__sub-title'>
            <span>{plainText.message.bankBoxesPopup.subtitle}</span>
          </div>

          <div className='bankboxes-popup-content__body__non-favorites'>{nonFavoriteBanksContent}</div>

          <div className='bankboxes-popup-content__body__actions'>
            <Button data-testid='btn-saveChanges' onClick={saveChanges} disabled={isLoading}>
              {plainText.button.saveChanges}
            </Button>
          </div>
        </ModalBody>
      </ModalContent>
    </StyledBankBoxesPopupContent>
  );
}

export default BankBoxesPopup;
