import { useEffect, useState } from 'react';

import { IAccount } from '../Interfaces/IMovementsDay';

function useSelectCheckbox() {
  const [dataOptions, setDataOptions] = useState([]);
  const [selectedOptions, setOptionsSelected] = useState([]);
  const [isDisabledFilters, setIsDisabledFilters] = useState(true);
  const [accounts, setAccounts] = useState<IAccount[]>([]);

  const onSelectOption = e => {
    setOptionsSelected(e);
    setIsDisabledFilters(e.length <= 0);
  };

  const onClearOptions = () => {
    setOptionsSelected([]);
    setIsDisabledFilters(true);
  };

  useEffect(() => {
    if (accounts.length > 0) {
      const options = accounts.map(account => {
        const data = {
          value: account,
          label: `${account.nroCuenta} | ${account.cuentaDenom !== undefined ? account.cuentaDenom : ''}`,
        };
        if (account.interfazNoInformada) {
          return { ...data, isDisabled: true };
        }
        return data;
      });
      setDataOptions(options);
    } else {
      setDataOptions([]);
    }
  }, [accounts]);

  return {
    dataOptions,
    selectedOptions,
    isDisabledFilters,
    onSelectOption,
    onClearOptions,
    setAccounts,
  };
}

export default useSelectCheckbox;
