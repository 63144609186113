import styled from 'styled-components';

const StyledConsumptionCards = styled.div`
  margin-bottom: 30px;

  .consumption-card-container {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 24px;

    @media (max-width: 1374px) {
      grid-template-columns: auto auto;
    }

    @media (max-width: 967px) {
      grid-template-columns: auto;
    }

    &__card {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: space-between;
      border-radius: 12px;
      border: 1px solid var(--chakra-colors-secondary-60);
      background: white;
      padding: 16px 16px 6px 16px;
      height: fit-content;
      min-width: 400px;

      &__title {
        display: flex;
        align-items: center;
        margin-top: 5px;
        margin-bottom: 12px;
        position: relative;

        &__text {
          margin: 0px 0px 0px 16px;
        }

        & .chakra-avatar {
          background-color: white;
          width: 44px;
          height: 44px;
          border: 2px solid var(--chakra-colors-secondary-60);

          & img {
            width: 28px;
            height: 28px;
            border-radius: 0px;
          }
        }

        &__tooltip {
          color: var(--chakra-colors-primary-100);
          background-color: transparent;
          border: 0;
          position: absolute;
          right: -12px;

          &:hover {
            background-color: transparent;
          }
          &:focus {
            background-color: transparent;
          }
        }
      }

      &__content {
        border-radius: 12px;
        background: var(--chakra-colors-primary-50);
        padding: 16px;
        margin-bottom: 12px;

        &__text {
          display: flex;
          justify-content: space-between;
          align-items: center;

          &__title {
            color: var(--chakra-colors-primary-90);
            margin-bottom: 5px;
          }

          &__current-value {
            font-weight: 700;
          }

          & p {
            margin: 0px;
          }
        }
      }
    }
  }
`;

export default StyledConsumptionCards;
