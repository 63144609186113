import styled from 'styled-components';

const StyledBankBoxesSkeleton = styled.div`
  .alice-carousel {
    height: 235px;

    &__stage-item {
      width: auto !important;
    }

    &__stage {
      top: 24px;
    }

    &__wrapper {
      height: 195px;
    }

    &__dots {
      margin: 0px;
    }

    &__control-btn {
      position: absolute;
      top: 83px;
      color: var(--chakra-colors-grey-120);
    }

    &__prev-btn {
      padding: 0px;
      width: 0px;
      & svg {
        left: -32px;
      }
    }

    &__next-btn {
      padding: 0px;
      width: 0px;
      & svg {
        right: -32px;
      }
    }
  }

  .carousel-bank-boxes {
    display: flex;
    justify-content: start;
  }
`;

const StyledCarouselDot = styled.div`
  margin: 0 5px;

  &.active-dot {
    background: var(--chakra-colors-grey-60);
    border-radius: 99px;
    height: 10px;
    width: 25px;
  }
  &.inactive-dot {
    background: var(--chakra-colors-grey-60);
    border-radius: 50%;
    height: 10px;
    width: 10px;
  }
`;

export { StyledBankBoxesSkeleton, StyledCarouselDot };
