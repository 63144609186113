import { Box, Button } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

import plainText from '../../../lang/es.json';
import StyledIntegrations from './StyledIntegrations';

function Integrations() {
  return (
    <StyledIntegrations>
      <Box className='containerTitle'>
        <h1 className='title'>{plainText.message.integrationsAPIPortal.title}</h1>
        <Button as={Link} to='/' className='instructivo' colorScheme='blue' variant='primary-text'>
          {plainText.message.integrationsAPIPortal.linkInstructions}
        </Button>
      </Box>
      <p className='description'>{plainText.message.integrationsAPIPortal.description}</p>
      <Box className='banner' />
    </StyledIntegrations>
  );
}

export default Integrations;
