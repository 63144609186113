// eslint-disable-next-line import/no-unresolved
import { OpenMode } from '@react-ib-mf/style-guide-ui';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import plainText from '../lang/es.json';

function EmbeddedView() {
  const [loading, setLoading] = useState(true);
  const { search } = useLocation();
  const urlComplete = encodeURIComponent(new URLSearchParams(search).get('src'));

  useEffect(() => {
    setLoading(true);
  }, [urlComplete]);

  const src = OpenMode({
    module: 'urlComplete',
    urlComplete,
  });

  function onload() {
    setLoading(false);
  }

  return (
    <>
      {loading && <p>{plainText.message.loadingSIB}</p>}
      <iframe src={src} height='1200px' width='98%' onLoad={onload} title='Embedded Content' />
    </>
  );
}

export default EmbeddedView;
