import {
  hasSeeBankBalances,
  hasSeeConsolidatedBalances,
  hasSeeMovementsOfTheDay,
  hasSeeStatusOfTransfers,
  hasSeeUnreportedInterfaces,
} from '../utils/permissionsUtilts';
import useOidcStorage from './useOidcStorage';

export default function usePermissions(): {
  hasConsolidatedBalancesPermission: boolean;
  hasMovementsOfTheDayPermission: boolean;
  hasUnreportedInterfacesPermission: boolean;
  hasStatusOfTransfersPermission: boolean;
  hasSeeBankBalancesPermission: boolean;
  permissions: string[];
} {
  const permissions = useOidcStorage().oidc?.profile?.permissions || [];

  return {
    hasConsolidatedBalancesPermission: hasSeeConsolidatedBalances(permissions),
    hasSeeBankBalancesPermission: hasSeeBankBalances(permissions),
    hasMovementsOfTheDayPermission: hasSeeMovementsOfTheDay(permissions),
    hasUnreportedInterfacesPermission: hasSeeUnreportedInterfaces(permissions),
    hasStatusOfTransfersPermission: hasSeeStatusOfTransfers(permissions),
    permissions,
  };
}
