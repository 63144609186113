// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Button, Image, Radio, RadioGroup, Skeleton, Stack } from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { isForbiddenError, pushAnalyticsEvent, pushAnalyticsFromError, useErrorMessage } from '@react-ib-mf/style-guide-ui';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import rightArrow from '../../assets/images/right-arrow.svg';
import usePermissions from '../../hooks/usePermissions';
import { ConsolidadoSaldo, IConsolidatedBalancesQuery } from '../../Interfaces/IConsolidateBalance';
import plainText from '../../lang/es.json';
import { getBancosSinActualizar } from '../../services/apiConsolidadoSaldos';
import { getConsolidadoSaldos } from '../../services/apiConsolidateBalance';
import { formatNumberDecimals } from '../../utils/numbers';
import StyledBox from '../../views/StyledBox';
import ChartCardError from '../ChartCardError/ChartCardError';
import PermissionMessage from '../PermissionMessage';
import ConsolidatedBalances from './consolidatedBalances';
import ShowBanksNotUpdated from './ShowBanksNotUpdated';
import { ConsolidateBalanceStyledBox, StyledErrorBar } from './styled';

ContainerConsolidateBalance.propTypes = {
  userConsolidatePermission: PropTypes.bool,
  userUnreportedInterfacesPermission: PropTypes.bool,
};

function ContainerConsolidateBalance(props) {
  const { showErrorMessage } = useErrorMessage();

  const {
    data: dataConsolidateBalance,
    isFetching,
    error,
  }: IConsolidatedBalancesQuery = useQuery(
    'chart',
    async () => {
      if (userConsolidatePermission) {
        const res = await getConsolidadoSaldos();
        return res;
      }
      return {
        data: [],
        error: false,
        isFetching: false,
      };
    },
    { retry: 2, onError: pushAnalyticsFromError },
  );

  const userConsolidatedBalancesCard = 'IR.CuentasCorrientes.ConsolidadoSaldos';
  const [showModal, setShowModal] = useState(false);

  const [totalAmount, setTotalAmount] = useState(0);
  const [balanceSelected, setBalanceSelected] = useState(null);
  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [disabledARCurrency, setDisabledARCurrency] = useState(true);
  const [disabledUSDCurrency, setDisabledUSDCurrency] = useState(true);
  const { userConsolidatePermission, userUnreportedInterfacesPermission } = props;
  const { permissionMessage } = plainText.message.chartCardConsolidateBalanceError;

  const currencyKeys = useMemo(
    () => ({
      AR: plainText.label.currencyKeyPesos,
      USD: plainText.label.currencyKeyUSD,
    }),
    [],
  );
  const currencies = [
    { key: currencyKeys.AR, text: `${plainText.label.pesos} ${plainText.label.currencyKeyARS}`, disabled: disabledARCurrency },
    { key: currencyKeys.USD, text: `${plainText.label.dollars} (${plainText.label.currencyKeyUSD})`, disabled: disabledUSDCurrency },
  ];

  const [currency, setCurrency] = useState('$');

  const handlerChange = value => {
    setCurrency(value);
  };

  const onOpen = () => {
    setShowModal(true);
  };

  const { hasUnreportedInterfacesPermission } = usePermissions();

  const {
    data: banks,
    status: bankStatus,
    error: bankStatusError,
  } = useQuery(
    ['BancosSinActualizar'],
    () => (hasUnreportedInterfacesPermission ? getBancosSinActualizar('CONSOLIDADO_SALDOS', currency).then(res => res.data.data) : []),
    {
      onError: pushAnalyticsFromError,
    },
  );

  useEffect(() => {
    if (error) {
      const errorDescription = error?.response?.data ? error?.response?.data.exception : error?.message;
      setShowError(true);
      setErrorText(errorDescription);

      if (!isForbiddenError(error)) {
        showErrorMessage({ error });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (bankStatus === 'error' && !isForbiddenError(bankStatusError)) {
      showErrorMessage({
        title: plainText.toast.error.consolidateBalanceTitle,
        description: plainText.toast.error.consolidateBalanceDescription,
        error: bankStatusError,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankStatus]);

  useEffect(() => {
    let balance: ConsolidadoSaldo;
    if (dataConsolidateBalance !== undefined && userConsolidatePermission) {
      balance = { ...dataConsolidateBalance.filter(item => item.moneda === currency)[0] };
      if (Object.keys(balance).length !== 0) {
        setCurrency(balance.moneda);
        setTotalAmount(balance.saldoOperativoActual);
        setBalanceSelected(balance);
      }
      if (dataConsolidateBalance?.length === 0) {
        setShowError(true);
        showErrorMessage();
      }
    }
  }, [currency, isFetching, dataConsolidateBalance, showErrorMessage, userConsolidatePermission]);

  useEffect(() => {
    if (!isFetching) {
      if (dataConsolidateBalance?.length) {
        dataConsolidateBalance.forEach(d => {
          if (d.moneda === currencyKeys.AR) {
            setDisabledARCurrency(false);
          }
          if (d.moneda === currencyKeys.USD) {
            setDisabledUSDCurrency(false);
          }
        });
        setCurrency(dataConsolidateBalance[0]?.moneda);
      }
    }
  }, [isFetching, dataConsolidateBalance, currencyKeys]);

  if (isForbiddenError(error)) {
    return null;
  }
  const pushAnalyticsSaldos = () => {
    pushAnalyticsEvent({
      event: 'select_content',
      content_group: 'Home',
      content_type: 'Tarjetas personalizadas',
      item_id: 'Card - Consolidado saldos',
    });
  };

  return (
    <StyledBox className='two_of_four px-0 pb-0 chart_box'>
      <Skeleton isLoaded={!isFetching} startColor='grey.50' endColor='grey.30' height='100%'>
        <div className='box_head heading-title'>
          <div className='header-title'>
            <h3 className='card-title'>{plainText.message.consolidadoSaldosTitle}</h3>
            <Link className='link' to={`consolidado-saldos/${currency}`}>
              <Image onClick={pushAnalyticsSaldos} src={rightArrow} />
            </Link>
          </div>
        </div>
        <div className='box_content'>
          <ConsolidateBalanceStyledBox>
            {userConsolidatePermission && !showError && (
              <div className='container'>
                {userConsolidatedBalancesCard === 'IR.CuentasCorrientes.ConsolidadoSaldos' && !isFetching && balanceSelected && (
                  <ConsolidatedBalances data={balanceSelected} />
                )}
                <div className='currency-box'>
                  <span className='currency-title'>{plainText.label.money}</span>
                  <RadioGroup onChange={value => handlerChange(value)} value={currency}>
                    {currencies.map(value => {
                      return (
                        <div key={value.key}>
                          <Stack direction='column'>
                            <Radio className='currency' value={value.key} name='radioCurrency' isDisabled={value.disabled}>
                              {value.text}
                            </Radio>
                          </Stack>
                        </div>
                      );
                    })}
                  </RadioGroup>
                  <div className='total-amount'>
                    <span className='title'>{plainText.label.currentOperatingBalance}</span>
                    <span
                      className={`amount ${totalAmount < 0 && 'red-text'}`}
                      style={{
                        fontSize:
                          (currency === '$' && totalAmount > 999999999 && '0.8rem') ||
                          (currency === 'USD' && totalAmount > 9999999 && '0.8rem'),
                      }}
                    >
                      {`${currency === '$' ? '$' : 'U$S'} ${formatNumberDecimals({
                        numberToFormat: totalAmount,
                        defaultValue: '0,00',
                      })}`}
                    </span>
                  </div>
                </div>
              </div>
            )}
            {showError && <ChartCardError info={errorText} />}
          </ConsolidateBalanceStyledBox>
        </div>
        {userUnreportedInterfacesPermission && banks && banks.length > 0 && !showError && (
          <div className='bank_box'>
            <StyledErrorBar>
              <p>{plainText.message.chartCardConsolidateBalanceError.titleBanksToUpdate}</p>
              <Button _focus={{ boxShadow: 'none' }} onClick={onOpen}>
                {plainText.button.checkBanks}
              </Button>
            </StyledErrorBar>
            <ShowBanksNotUpdated showModal={showModal} callBack={() => setShowModal(false)} banks={banks} />
          </div>
        )}
        {!userConsolidatePermission && <PermissionMessage message={permissionMessage} />}
      </Skeleton>
    </StyledBox>
  );
}

export default ContainerConsolidateBalance;
