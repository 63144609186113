import { Icon } from '@chakra-ui/react';
import React from 'react';
import { FiClock } from 'react-icons/fi';
import styled from 'styled-components';

import {
  FONT_API_PORTAL_SOLUTION_CARDS_DESCRIPTION,
  FONT_API_PORTAL_SOLUTION_CARDS_FOOTER,
  FONT_API_PORTAL_SOLUTION_CARDS_SUBTITLE,
  FONT_API_PORTAL_SOLUTION_CARDS_TITLE,
} from '../../../designTokens/fonts';

interface ICardInfo {
  order: number;
  subtitle: string;
  title: string;
  description: string;
  version: string;
}

interface ISolutionSectionCards {
  cardInfo: ICardInfo;
  cardIndex: number;
  maxCardNum: number;
}

function SolutionSectionCards({ cardInfo, cardIndex, maxCardNum }: ISolutionSectionCards) {
  return (
    <StyledInformativeCards
      key={cardInfo.order}
      style={{
        marginRight: cardIndex === maxCardNum - 1 ? '0px' : '24px',
      }}
    >
      <div className='informative-card'>
        <div className='informative-card__content'>
          <div className='informative-card__content__subtitle'>{cardInfo.subtitle}</div>
          <div className='informative-card__content__title'>{cardInfo.title}</div>
          <div className='informative-card__content__description'>{cardInfo.description}</div>
        </div>

        <div className='informative-card__footer'>
          <div className='informative-card__footer__version'>
            <Icon className='informative-card__footer__version__icon' as={FiClock} h={4} w={4} color='neutral.100' />
            <div className='informative-card__footer__version__text'>{cardInfo.version}</div>
          </div>
          {/* <Icon className='informative-card__footer__link' as={FiExternalLink} h={5} w={5} color='primary.100' /> */}
        </div>
      </div>
    </StyledInformativeCards>
  );
}

const StyledInformativeCards = styled.div`
  .informative-card {
    border: 1px solid var(--chakra-colors-grey-60);
    height: 345px;
    width: 294px;
    background: var(--chakra-colors-white);
    box-shadow: 0px 1px 3px 1px rgb(0 0 0 / 30%);
    border-radius: 12px;
    color: var(--chakra-colors-grey-120);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 3px;

    &__content {
      margin: 12px;

      &__subtitle {
        font-family: ${FONT_API_PORTAL_SOLUTION_CARDS_SUBTITLE.fontFamily};
        font-style: ${FONT_API_PORTAL_SOLUTION_CARDS_SUBTITLE.fontStyle};
        font-weight: ${FONT_API_PORTAL_SOLUTION_CARDS_SUBTITLE.fontWeight};
        font-size: ${FONT_API_PORTAL_SOLUTION_CARDS_SUBTITLE.fontSize};
        line-height: ${FONT_API_PORTAL_SOLUTION_CARDS_SUBTITLE.lineHeight};
        letter-spacing: ${FONT_API_PORTAL_SOLUTION_CARDS_SUBTITLE.letterSpacing};
        text-transform: uppercase;
        margin-bottom: 8px;
      }

      &__title {
        font-family: ${FONT_API_PORTAL_SOLUTION_CARDS_TITLE.fontFamily};
        font-style: ${FONT_API_PORTAL_SOLUTION_CARDS_TITLE.fontStyle};
        font-weight: ${FONT_API_PORTAL_SOLUTION_CARDS_TITLE.fontWeight};
        font-size: ${FONT_API_PORTAL_SOLUTION_CARDS_TITLE.fontSize};
        line-height: ${FONT_API_PORTAL_SOLUTION_CARDS_TITLE.lineHeight};
        margin-bottom: 18px;
        margin-right: 10px;
      }

      &__description {
        font-family: ${FONT_API_PORTAL_SOLUTION_CARDS_DESCRIPTION.fontFamily};
        font-style: ${FONT_API_PORTAL_SOLUTION_CARDS_DESCRIPTION.fontStyle};
        font-weight: ${FONT_API_PORTAL_SOLUTION_CARDS_DESCRIPTION.fontWeight};
        font-size: ${FONT_API_PORTAL_SOLUTION_CARDS_DESCRIPTION.fontSize};
        line-height: ${FONT_API_PORTAL_SOLUTION_CARDS_DESCRIPTION.lineHeight};
        letter-spacing: ${FONT_API_PORTAL_SOLUTION_CARDS_DESCRIPTION.letterSpacing};
        height: 140px;
      }
    }

    &__footer {
      background: var(--chakra-colors-grey-40);
      display: flex;
      height: 60px;
      padding: 23px;
      padding-left: 12px;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      align-items: center;
      justify-content: space-between;
      top: -2px;
      position: relative;

      &__version {
        display: flex;
        align-items: center;

        &__icon {
          stroke-width: 3;
        }
        &__text {
          font-family: ${FONT_API_PORTAL_SOLUTION_CARDS_FOOTER.fontFamily};
          font-style: ${FONT_API_PORTAL_SOLUTION_CARDS_FOOTER.fontStyle};
          font-weight: ${FONT_API_PORTAL_SOLUTION_CARDS_FOOTER.fontWeight};
          font-size: ${FONT_API_PORTAL_SOLUTION_CARDS_FOOTER.fontSize};
          line-height: ${FONT_API_PORTAL_SOLUTION_CARDS_FOOTER.lineHeight};
          color: var(--chakra-colors-neutral-120);
          margin-left: 11px;
          position: relative;
          top: 1px;
        }
      }
      &__link {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
`;

export default SolutionSectionCards;
