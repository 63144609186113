import { Icon } from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { useWindowDimensions } from '@react-ib-mf/style-guide-ui';
import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import { IBankBoxesSkeleton } from '../../Interfaces/IBankBoxes';
import BankBoxesCard from '../BankBoxesCard';
import { StyledBankBoxesSkeleton, StyledCarouselDot } from './styled';

function BankBoxesSkeleton({ bankBoxesStatus }: IBankBoxesSkeleton) {
  const MAX_BANKBOXES_SKELETON = 10;

  // Create each card in its skeleton mode
  const bankBoxes = [];
  for (let index = 0; index < MAX_BANKBOXES_SKELETON; index += 1) {
    bankBoxes.push(
      <BankBoxesCard isLast={index === MAX_BANKBOXES_SKELETON - 1} key={`bank_boxes_card_${index}`} bankBoxesStatus={bankBoxesStatus} />,
    );
  }

  // Calculate the number of bankboxes per group using carouselWidth/bankBoxWidth
  let { width } = useWindowDimensions();
  if (width === undefined) width = 900;
  const BANKBOXES_WIDTH = 318;
  const SIDE_BAR_WIDTH = 185;
  const bankBoxesPerGroup = Math.floor((width - SIDE_BAR_WIDTH) / BANKBOXES_WIDTH);

  // Create groups of card in order to feed the carousel
  const carouselBankBoxesGroup = [];
  const numBankBoxesGroup = Math.ceil(bankBoxes.length / bankBoxesPerGroup);
  let bankBoxesCounter = 0;

  for (let i = 0; i < numBankBoxesGroup; i += 1) {
    const carouselBankBoxesGroupAux = [];
    for (let j = 0; j < bankBoxesPerGroup; j += 1) {
      if (bankBoxesCounter < bankBoxes.length) {
        carouselBankBoxesGroupAux.push(bankBoxes[bankBoxesCounter]);
        bankBoxesCounter += 1;
      } else {
        break;
      }
    }
    carouselBankBoxesGroup.push(<div className='carousel-bank-boxes'>{carouselBankBoxesGroupAux}</div>);
  }

  // Custom rendering the required elements
  const renderCarouselPrevButton = ({ isDisabled }) => {
    if (!isDisabled) {
      return <Icon as={FiChevronLeft} h={8} w={8} className='alice-carousel__control-btn' />;
    }
    return null;
  };

  const renderCarouselNextButton = ({ isDisabled }) => {
    if (!isDisabled) {
      return <Icon as={FiChevronRight} h={8} w={8} className='alice-carousel__control-btn' />;
    }
    return null;
  };

  const renderCarouselDotsItem = ({ isActive }) => {
    return <StyledCarouselDot className={isActive ? 'active-dot' : 'inactive-dot'} />;
  };

  // Create the container with the skeleton carousel
  const bankBoxesContainer = (
    <AliceCarousel
      data-testid='carousel-bankboxes'
      items={carouselBankBoxesGroup}
      autoWidth
      renderDotsItem={renderCarouselDotsItem}
      renderPrevButton={renderCarouselPrevButton}
      renderNextButton={renderCarouselNextButton}
    />
  );

  return <StyledBankBoxesSkeleton>{bankBoxesContainer}</StyledBankBoxesSkeleton>;
}

export default BankBoxesSkeleton;
