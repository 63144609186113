import { Button, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { OpenLink, pushAnalyticsEvent } from '@react-ib-mf/style-guide-ui';
import React, { useEffect } from 'react';

import plainText from '../../lang/es.json';
import StyledPostOnboardingSection from './StyledPostOnboardingSection';

function PostOnboardingSection() {
  const PostOnboardingSectionData = [
    {
      tabTitle: plainText.postOnboardingSection.assignOperators.tabTitle,
      tabDataTestId: 'tab-assignOperators',
      tabPanelTitle: plainText.postOnboardingSection.assignOperators.tabPanelTitle,
      tabPanelDescription: plainText.postOnboardingSection.assignOperators.tabPanelDescription,
      tabPanelAction: plainText.postOnboardingSection.assignOperators.tabPanelAction,
      url: 'menuRoot%3Dadmin.abm%26menuSelection%3Dadmin%7Cabm%7CadmOperador',
    },
    {
      tabTitle: plainText.postOnboardingSection.signatureScheme.tabTitle,
      tabDataTestId: 'tab-signatureScheme',
      tabPanelTitle: plainText.postOnboardingSection.signatureScheme.tabPanelTitle,
      tabPanelDescription: plainText.postOnboardingSection.signatureScheme.tabPanelDescription,
      tabPanelAction: plainText.postOnboardingSection.signatureScheme.tabPanelAction,
      url: 'menuRoot%3Dadmin.abm%26menuSelection%3Dadmin%7Cabm%7CadmEsquemaFirmas',
    },
    {
      tabTitle: plainText.postOnboardingSection.createToken.tabTitle,
      tabDataTestId: 'tab-createToken',
      tabPanelTitle: plainText.postOnboardingSection.createToken.tabPanelTitle,
      tabPanelDescription: plainText.postOnboardingSection.createToken.tabPanelDescription,
      tabPanelAction: plainText.postOnboardingSection.createToken.tabPanelAction,
      url: 'menuRoot%3Dadmin.datosPersonales%26menuSelection%3Dadmin%7CdatosPersonales%7CintegracionGTD',
    },
    {
      tabTitle: plainText.postOnboardingSection.createMACKey.tabTitle,
      tabDataTestId: 'tab-createMACKey',
      tabPanelTitle: plainText.postOnboardingSection.createMACKey.tabPanelTitle,
      tabPanelDescription: plainText.postOnboardingSection.createMACKey.tabPanelDescription,
      tabPanelAction: plainText.postOnboardingSection.createMACKey.tabPanelAction,
      url: 'menuRoot%3Dadmin.abm%26menuSelection%3Dadmin%7Cabm%7CadmOfferingContratado',
    },
  ];

  useEffect(() => {
    pushAnalyticsEvent({
      event: 'pos_onboarding_component_view',
      content_group: 'Home',
    });
  }, []);

  const clickButtonWithAnalitycs = buttonLabel => {
    pushAnalyticsEvent({
      event: 'select_content',
      conten_type: 'Click botones Pos Onboarding',
      item_id: buttonLabel,
    });
  };

  const clickTabWithAnalitycs = tabLabel => {
    pushAnalyticsEvent({
      event: 'select_content',
      conten_type: 'Click pestañas Pos Onboarding',
      item_id: tabLabel,
    });
  };

  return (
    <StyledPostOnboardingSection className='post-onboarding-section'>
      <Text className='post-onboarding-section__title' textStyle='h4Bold' data-testid='txt-postOnboardingTitle'>
        {plainText.postOnboardingSection.title}
      </Text>
      <Tabs>
        <TabList className='post-onboarding-section__tablist'>
          {PostOnboardingSectionData.map((sectionData, index, sectionDataArray) => (
            <Tab
              className={`post-onboarding-section__tablist__tab ${index === sectionDataArray.length - 1 ? 'last-tab' : ''}`}
              key={sectionData.tabDataTestId}
              data-testid={sectionData.tabDataTestId}
              onClick={() => clickTabWithAnalitycs(sectionData.tabTitle)}
            >
              {sectionData.tabTitle}
            </Tab>
          ))}
        </TabList>

        <TabPanels>
          {PostOnboardingSectionData.map(sectionData => (
            <TabPanel key={sectionData.tabDataTestId}>
              <div className='post-onboarding-section__tab-panel'>
                <div className='post-onboarding-section__tab-panel__text'>
                  <Text
                    className='post-onboarding-section__tab-panel__text__title'
                    textStyle='h5Bold'
                    data-testid='txt-postOnboardingTabTitle'
                  >
                    {sectionData.tabPanelTitle}
                  </Text>
                  <Text
                    className='post-onboarding-section__tab-panel__text__description'
                    textStyle='bodyMdSemi'
                    data-testid='txt-postOnboardingTabDescription'
                  >
                    {sectionData.tabPanelDescription}
                  </Text>
                </div>

                <div className='post-onboarding-section__tab-panel__action'>
                  <OpenLink url={sectionData.url} openMode='urlComplete' reuseTab>
                    <Button
                      onClick={() => clickButtonWithAnalitycs(sectionData.tabPanelAction)}
                      size='lg'
                      variant='primary'
                      data-testid='btn-postOnboardingTabAction'
                    >
                      {sectionData.tabPanelAction}
                    </Button>
                  </OpenLink>
                </div>
              </div>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </StyledPostOnboardingSection>
  );
}

export default PostOnboardingSection;
