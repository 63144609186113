import { Image } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import alertIcon from '../../assets/images/alert-triangle-outline.svg';
import { FONT_CHART_CARD_ERROR_BOX } from '../../designTokens/fonts';
import plainText from '../../lang/es.json';

interface IErrorProps {
  info: string;
}

function ChartCardError({ ...props }: IErrorProps) {
  const [text, setText] = useState<string>('');
  const [title, setTitle] = useState<string>('');

  const getMessage = useCallback(() => {
    const { info } = props;
    if (info === 'noInfo') {
      setTitle(plainText.message.chartCardConsolidateBalanceError.titleNoInfo);
      setText(plainText.message.chartCardConsolidateBalanceError.textNoInfo);
    } else if (info === 'En este momento la información no está disponible') {
      setTitle(plainText.message.chartCardConsolidateBalanceError.titleUnavailableInfo);
      setText(plainText.message.chartCardConsolidateBalanceError.textUnavailableInfo);
    } else if (info === 'noUptadedInfo') {
      setTitle(plainText.message.chartCardConsolidateBalanceError.titleNotUpdatedInfo);
      setText(plainText.message.chartCardConsolidateBalanceError.textNotUpdatedInfo);
    } else if (info === 'noMovementsAccount') {
      setTitle(plainText.message.chartCardConsolidateBalanceError.titleNoMovementsAccount);
    } else {
      setTitle(plainText.message.chartCardConsolidateBalanceError.titleUnavailableInfo);
      setText(plainText.message.chartCardConsolidateBalanceError.textUnavailableInfo);
    }
  }, [props]);

  useEffect(() => {
    getMessage();
  }, [getMessage]);

  return (
    <Box>
      <Image className='alert-img' src={alertIcon} alt='Error Image' />
      <div className='info-box'>
        <h2>{title}</h2>
        <p>{text}</p>
      </div>
    </Box>
  );
}

export default ChartCardError;

const Box = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  min-height: 300px;
  align-items: center;
  padding: 0 10% 10% 10%;

  .alert-img {
    padding-right: 40px;
    display: block;
  }

  h2 {
    color: var(--chakra-colors-danger-100);
    font-size: ${FONT_CHART_CARD_ERROR_BOX.fontSizeTitle};
  }
  p {
    font-size: ${FONT_CHART_CARD_ERROR_BOX.fontSize};
  }
  .info-box {
    padding-right: 53px;

    & p {
      color: black;
    }
  }
`;
