export const permissionsApiPortal = [
  'c726ff38248796a800276651da8490978c6f98a5f4e43c426caf6eeb2b05776c',
  'd7d28ded7f9321a43b3dde7c201541360e2378d255fd2821a1949bd4624c0747',
  '0bb068cf11bc5af0596f7ed5262a072b306fb56f37489a4b2782a6d27d575443',
  'd4c8c75223db90ee836600fb8cca85cc90f640109e80d2276e97339b1dad0c49',
  '8630602a8ff0dd802c5768050dd97d96687dfa130b154f0a0df9e7f4571b6e40',
  '5d2864b3498156906dc224d713767393e0f424160d96c71d79cdde20708eabeb',
];
export const permissionBannerSuscription = [
  '8fbd331d39c1b0060b4a41c76746afb4c9430ef7e953375ccfea79cc60d26db3',
  '4b4174940ccdbaa135f022c6ea4b7bd4fffe135eae7c5cc820a55b24fd02ab1a',
  '9c8f5e973031da344c0ed90c5a3330102524ace93afb656986bd45d536d7cc68',
  'af314dd9352260f21c14121cde7cf36256cf7fdd52678717a1447a249fcce876',
  '3de184b7888f83d88c52ee5a93aa74f9b1c275570d34a9582515e4a9db1de403',
];
