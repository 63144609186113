// eslint-disable-next-line import/no-unresolved
import { getToken } from '@react-ib-mf/style-guide-ui';
import axios from 'axios';

import { mockBanksAct } from '../../test/__mocks__/ConsolidateBalance/mock';

axios.defaults.baseURL = process.env.API_BASE_URL;
axios.defaults.headers.common.Authorization = `Bearer ${getToken()}`;
if (process.env.API_USER_KEY) {
  axios.defaults.headers.common.user_key = process.env.API_USER_KEY;
}

const getBancosSinActualizar = (
  CONSOLIDADO_SALDOS: 'CONSOLIDADO_SALDOS' | 'MOVIMIENTOS_DIA',
  moneda: string, // '$' | 'USD'
) => axios.get(`/home/consultas/interfaces_no_informadas?codigoReporte=${CONSOLIDADO_SALDOS}&moneda=${moneda}`);

const getBancosSinActualizarMock = () => {
  return new Promise(resolve => {
    setTimeout(() => {
      return resolve({
        data: mockBanksAct,
      });
    }, 3000);
  });
};
// ejemplo de llamado al mock en otro componente
// const {
//   data: banks,
//   status:bankStatus,
// } = useQuery(['BancosSinActualizarMock'], () => getBancosSinActualizarMock('CONSOLIDADO_SALDOS', moneda).then((res:any) => res.data.data));

export { getBancosSinActualizar, getBancosSinActualizarMock };
