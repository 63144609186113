import { Icon, Text } from '@chakra-ui/react';
import { Skeleton } from '@chakra-ui/skeleton';
// eslint-disable-next-line import/no-unresolved
import { formatDateTime, OpenLink } from '@react-ib-mf/style-guide-ui';
import { useContext, useMemo, useState } from 'react';
import { FiClock } from 'react-icons/fi';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { BankBoxData, IBankBoxesCard } from '../../Interfaces/IBankBoxes';
import plainText from '../../lang/es.json';
import { getUniqueBankSaldo } from '../../services/api';
import { BankBoxesContext } from '../BankBoxes';
import Logo from '../BankBoxes/Logo';
import BankBoxesCardAmount from '../BankBoxesCardAmount';
import StyledBankBoxesCard from './StyledBankBoxesCard';

function BankBoxesCard({ bankData, permitirConsolidadoSaldos, isLast, bankBoxesStatus, urlToRedirect }: IBankBoxesCard) {
  const { setBankBoxes } = useContext(BankBoxesContext);
  const navigate = useNavigate();
  const [doRefetch, setDoRefetch] = useState<boolean>(false);

  function consolidadoSaldoRedirect() {
    navigate('/consolidado-saldos/$');
  }

  const hasSaldo = useMemo(() => {
    return !!bankData?.saldoDolares || !!bankData?.saldoPesos;
  }, [bankData]);

  const onSuccess = async (fetchedBank: BankBoxData) => {
    const { data: banco } = fetchedBank;

    setBankBoxes(prevState => {
      return {
        ...prevState,
        data: {
          ...prevState.data,
          bancos: [...prevState.data.bancos].map(bco => (bco?.bcoIdbco === bankData?.bcoIdbco ? banco : bco)),
        },
      };
    });

    setDoRefetch(false);
  };

  const onError = () => setDoRefetch(false);

  useQuery(['saldo-unico', bankData], () => getUniqueBankSaldo(bankData?.bcoIdbco).then(res => res.data), {
    onError,
    onSuccess,
    enabled: doRefetch,
  });

  const { date = null, time = null } = bankData?.lastUpdateBalance ? formatDateTime(new Date(bankData.lastUpdateBalance)) : {};

  if (bankBoxesStatus === 'loading' || bankBoxesStatus === 'idle' || doRefetch) {
    return (
      <Skeleton
        startColor='grey.50'
        endColor='grey.30'
        width='294px'
        height='150px'
        borderRadius='20px'
        marginRight={isLast ? '0px' : '24px'}
      />
    );
  }

  if (!hasSaldo || !bankData?.bcoNomcor || !bankData?.lastUpdateBalance) {
    return (
      <StyledBankBoxesCard>
        <Logo bank={bankData} showTooltip={false} shouldRenderDefaultLogo={!bankData?.bcoNomcor} />
        <div
          className='account_box'
          key={bankData.id}
          aria-hidden='true'
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            marginRight: isLast ? '0px' : '24px',
            zIndex: 1,
            position: 'relative',
          }}
        >
          {/* cambiar el estilo en linea por estilos en styled */}
          {bankData?.bcoNomcor && (
            <Text textStyle='bodySmSemi' style={{ position: 'absolute', left: '28px', top: '28px', margin: 0 }}>
              {bankData.bcoNomcor}
            </Text>
          )}
          <div>
            <Text textStyle='labelMdBold'>{plainText.message.unavailableInformation}</Text>
          </div>
          {/* <Button
            variant='link'
            shadow='none'
            backgroundColor='transparent'
            color='primary.100'
            size='md'
            textDecoration='underline'
            onClick={() => {
              setDoRefetch(true);
            }}
          >
            {plainText.button.retry}
          </Button> */}
        </div>
      </StyledBankBoxesCard>
    );
  }

  return (
    <StyledBankBoxesCard>
      <OpenLink url={urlToRedirect} openMode='newTab' reuseTab>
        <Logo bank={bankData} showTooltip={false} />
        <div
          className='account_box'
          key={bankData.id}
          onClick={permitirConsolidadoSaldos ? consolidadoSaldoRedirect : undefined}
          aria-hidden='true'
          style={{
            zIndex: 1,
            cursor: permitirConsolidadoSaldos ? 'pointer' : 'default',
            marginRight: isLast ? '0px' : '24px',
          }}
        >
          <div className='account_box__body'>
            <Text textStyle='bodySmSemi' className='account_box__accounts'>
              {bankData.bcoNomcor}
            </Text>

            {bankData.saldoPesos !== '' && bankData?.saldoPesos && <BankBoxesCardAmount amount={bankData?.saldoPesos} currency='pesos' />}

            {bankData.saldoDolares !== '' && bankData?.saldoDolares && (
              <BankBoxesCardAmount amount={bankData?.saldoDolares} currency='dollars' />
            )}
          </div>

          {bankData?.lastUpdateBalance && (
            <div className='account_box__footer' data-testid='div-updatedBankContainer'>
              <Icon as={FiClock} h={4} w={4} color='secondary.110' data-testid='ico-updatedBankClock' />
              <Text m='8px 0px' textStyle='labelXs' data-testid='lbl-updatedBank'>
                {plainText.label.updated}
              </Text>
              <Text m='8px 0px' textStyle='labelXs' data-testid='lbl-updatedBankDate'>
                {`${date} - ${time} horas`}
              </Text>
            </div>
          )}
        </div>
      </OpenLink>
    </StyledBankBoxesCard>
  );
}

export default BankBoxesCard;
