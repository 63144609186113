// eslint-disable-next-line import/no-unresolved
import { pushAnalyticsEvent } from '@react-ib-mf/style-guide-ui';
import { FiEdit2, FiEye, FiEyeOff, FiHelpCircle, FiRefreshCcw } from 'react-icons/fi';

import { IBankBoxesHeader } from '../../Interfaces/IBankBoxes';
import plainText from '../../lang/es.json';
import ActionIcon from './ActionIcon';
import StyledBankBoxesHeader from './StyledBankBoxesHeader';

function BankBoxesHeader({
  isBalanceVisible,
  setIsBalanceVisible,
  allowEdit,
  allowRefresh,
  allowHideAndShowBalance,
  onOpen,
  refresh,
}: IBankBoxesHeader) {
  const pushAnalyticsHideShowBalance = () => {
    pushAnalyticsEvent({
      event: 'select_content',
      content_group: 'Home',
      content_type: 'Tarjetas saldos bancarios',
      item_id: isBalanceVisible ? 'Ocultar' : 'Mostrar',
    });
  };

  const pushAnalyticsOpenEditBalance = () => {
    pushAnalyticsEvent({
      event: 'select_content',
      content_group: 'Home',
      content_type: 'Editar accesos directos y tarjetas',
      item_id: 'Editar tarjetas saldos bancarios',
    });
  };

  const pushAnalyticsUpdateBalance = () => {
    pushAnalyticsEvent({
      event: 'select_content',
      content_group: 'Home',
      content_type: 'Tarjetas saldos bancarios',
      item_id: 'Actualizar saldos bancarios',
    });
  };

  const switchBalanceVisible = () => {
    pushAnalyticsHideShowBalance();
    setIsBalanceVisible(value => !value);
  };

  const handleOpenEditBalance = () => {
    pushAnalyticsOpenEditBalance();
    onOpen();
  };

  const refreshBalance = () => {
    pushAnalyticsUpdateBalance();
    refresh();
  };

  return (
    <StyledBankBoxesHeader>
      <div className='actions'>
        <div className='actions__my-balance'>
          <span className='actions__my-balance__text'>{plainText.message.bankBoxesHeader.title}</span>
          <ActionIcon
            customClassName='actions__my-balance__icon'
            icon={FiHelpCircle}
            tooltip={plainText.message.bankBoxesHeader.helpTooltip}
            tooltipPlacement='right'
          />
        </div>

        <div className='actions__order-cards'>
          <ActionIcon
            isDisabled={!allowRefresh}
            icon={FiRefreshCcw}
            onClick={refreshBalance}
            aria-label='refresh_accounts_button'
            data-testid='btn-RefreshAccounts'
            tooltip={plainText.message.bankBoxesHeader.refreshTooltip}
          />

          <ActionIcon
            isDisabled={!allowHideAndShowBalance}
            icon={isBalanceVisible ? FiEye : FiEyeOff}
            onClick={switchBalanceVisible}
            aria-label='balance_visible_button'
            data-testid='btn-isBalanceVisible'
            tooltip={plainText.message.bankBoxesHeader.hideShowTooltip}
          />

          <ActionIcon
            isDisabled={!allowEdit}
            icon={FiEdit2}
            onClick={handleOpenEditBalance}
            aria-label='order_accounts_button'
            data-testid='btn-orderAccounts'
            tooltip={plainText.message.bankBoxesHeader.sortTooltip}
          />
        </div>
      </div>
    </StyledBankBoxesHeader>
  );
}

export default BankBoxesHeader;
