import styled from 'styled-components';

import {
  FONT_CONSOLIDATE_BALANCE,
  FONT_CONSOLIDATE_BALANCE_AMOUNT,
  FONT_CONSOLIDATE_BALANCE_CURRENCY,
  FONT_CONSOLIDATE_BALANCE_CURRENCY_TITLE,
  FONT_CONSOLIDATE_BALANCE_ERROR_BAR,
  FONT_CONSOLIDATE_BALANCE_MODAL_BODY,
  FONT_CONSOLIDATE_BALANCE_MODAL_HEADER,
  FONT_CONSOLIDATE_BALANCE_TITLE,
} from '../../designTokens/fonts';

const ConsolidateBalanceStyledBox = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  color: var(--chakra-colors-secondary-110);

  .bank_box {
    background-color: green;
  }

  * {
    -webkit-tap-highlight-color: transparent;
  }

  @media (max-width: 1200px) {
    flex-direction: column;
  }

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 220px;
    padding-top: 10px;
  }

  canvas {
    margin-right: 27px;

    @media (max-width: 1280px) {
      margin-right: 17px;
    }
  }

  .graph-style {
    height: 22vh;
    width: 31vw;

    @media (max-width: 1900px) {
      width: 30vw;
    }

    @media (max-width: 1040px) {
      width: 28vw;
      height: 20vw;
    }
  }

  .currency-box {
    margin-top: 15px;
  }

  .error-bar {
    display: flex;
    flex-direction: row;
    background-color: var(--chakra-colors-danger-100);
    width: 100%;
    color: white;
    padding-left: 10px;
    align-items: center;
    font-size: ${FONT_CONSOLIDATE_BALANCE.fontSize};
    height: 27px;
    Button {
      font-weight: ${FONT_CONSOLIDATE_BALANCE.fontWeight};
      font-size: ${FONT_CONSOLIDATE_BALANCE.fontSize};
      background: none;
      color: white;
      border: none;
    }
    Button:hover {
      border: none;
    }
    Button::focus,
    .css-taj3dd[data-focus] {
      box-shadow: none;
    }

    @media (max-width: 810px) {
      font-size: ${FONT_CONSOLIDATE_BALANCE.fontSizeMedia};
    }
  }

  .bottom {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .total-amount {
    display: flex;
    flex-direction: column;
    padding-right: 27px;

    .amount {
      font-weight: ${FONT_CONSOLIDATE_BALANCE_AMOUNT.fontWeight};
      font-size: ${FONT_CONSOLIDATE_BALANCE_AMOUNT.fontSize};
      padding-top: 3px;
    }

    .red-text {
      color: red;
    }

    .title {
      font-size: ${FONT_CONSOLIDATE_BALANCE_TITLE.fontSize};
      padding-top: 18px;
      display: inline-block;
      text-align: start;
      width: 150%;

      @media (max-width: 1040px) {
        font-size: ${FONT_CONSOLIDATE_BALANCE_TITLE.fontSizeMedia};
      }
    }

    .currency {
      font-size: ${FONT_CONSOLIDATE_BALANCE_CURRENCY.fontSize};

      @media (max-width: 1040px) {
        font-size: ${FONT_CONSOLIDATE_BALANCE_CURRENCY.fontSizeMedia};
      }
    }

    .currency-title {
      font-size: ${FONT_CONSOLIDATE_BALANCE_CURRENCY_TITLE.fontSize};
      padding: 88px 0 16px 0;

      @media (max-width: 1040px) {
        font-size: ${FONT_CONSOLIDATE_BALANCE_CURRENCY_TITLE.fontSizeMedia};
      }
    }
  }
`;

const StyledModalFooter = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  Button {
    padding: 12px 50px;
  }
`;

const StyledModalHeader = styled.div`
  h1 {
    font-weight: ${FONT_CONSOLIDATE_BALANCE_MODAL_HEADER.fontWeight};
    font-size: ${FONT_CONSOLIDATE_BALANCE_MODAL_HEADER.fontSize};
    text-align: center;
    padding-top: 31px;
  }
`;

const StyledModalBody = styled.div`
  font-weight: ${FONT_CONSOLIDATE_BALANCE_MODAL_BODY.fontWeight};
  font-size: ${FONT_CONSOLIDATE_BALANCE_MODAL_BODY.fontSize};
  padding: 0 62px 28px 62px;
  text-align: center;
`;

const StyledErrorBar = styled.div`
  display: flex;
  flex-direction: row;
  background-color: var(--chakra-colors-danger-100);
  width: 100%;
  color: white;
  padding-left: 10px;
  align-items: center;
  font-size: ${FONT_CONSOLIDATE_BALANCE_ERROR_BAR.fontSize};
  height: 27px;
  Button {
    font-weight: ${FONT_CONSOLIDATE_BALANCE_ERROR_BAR.fontWeight};
    font-size: ${FONT_CONSOLIDATE_BALANCE_ERROR_BAR.fontSize};
    background: none;
    color: white;
    border: none;
  }
  Button:hover {
    font-weight: ${FONT_CONSOLIDATE_BALANCE_ERROR_BAR.fontWeight};
    font-size: ${FONT_CONSOLIDATE_BALANCE_ERROR_BAR.fontSize};
    background: none;
    color: white;
    border: none;
  }
  Button::focus,
  .css-taj3dd[data-focus] {
    box-shadow: none;
  }

  @media (max-width: 810px) {
    font-size: ${FONT_CONSOLIDATE_BALANCE_ERROR_BAR.fontSizeMedia};
  }
`;

export { ConsolidateBalanceStyledBox, StyledErrorBar, StyledModalBody, StyledModalFooter, StyledModalHeader };
