import styled from 'styled-components';

const StyledErrorPage = styled.div`
  .error-section {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 24px;
    text-align: center;

    &__image {
      margin-bottom: 16px;
      width: 80px;
      height: 80px;
    }

    &__title {
      margin-bottom: 8px;
    }

    &__description {
      margin-bottom: 15px;
    }
  }

  .unmargined-text {
    margin: 0px;
  }
`;

export default StyledErrorPage;
