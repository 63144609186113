import { Button, Icon, PlacementWithLogical, Tooltip } from '@chakra-ui/react';
import { IconType } from 'react-icons';

interface ActionIconProps {
  onClick?: () => void;
  isDisabled?: boolean;
  icon: IconType;
  tooltip?: string;
  tooltipPlacement?: PlacementWithLogical;
  'aria-label'?: string;
  'data-testid'?: string;
  customClassName?: string;
}

function ActionIcon({
  icon,
  isDisabled = false,
  onClick,
  'aria-label': ariaLabel,
  'data-testid': dataTestId,
  tooltip,
  tooltipPlacement,
  customClassName,
}: ActionIconProps) {
  const renderButton = () => (
    <Button
      className={customClassName || ''}
      onClick={isDisabled ? undefined : onClick}
      data-testid={dataTestId}
      disabled={isDisabled}
      aria-label={ariaLabel}
    >
      <Icon as={icon} h={6} w={6} />
    </Button>
  );

  if (tooltip) {
    return (
      <Tooltip placement={tooltipPlacement} hasArrow label={tooltip}>
        {renderButton()}
      </Tooltip>
    );
  }

  return renderButton();
}

export default ActionIcon;
