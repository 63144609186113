import styled from 'styled-components';

const StyledAlert = styled.div`
  .alert-wrapper {
    width: 100%;
    max-width: none;
  }

  button[aria-label='Close'] {
    margin-top: 7px;
  }
`;

export default StyledAlert;
