import styled from 'styled-components';

import backGroundLeft from '../../../assets/images/API-portal-signin-BG-image-left.svg';
import backGroundRigth from '../../../assets/images/API-portal-signin-BG-image-rigth.svg';
import {
  FONT_API_PORTAL_REGISTER_SUCCESS_BTN,
  FONT_API_PORTAL_REGISTER_SUCCESS_DESCRIPTION,
  FONT_API_PORTAL_REGISTER_SUCCESS_TITLE,
} from '../../../designTokens/fonts';

const StyledRegisterSuccess = styled.div`
  .bgleft {
    background-image: url(${backGroundLeft});

    background-repeat: no-repeat;

    background-position: left;
  }

  .bgrigth {
    background-image: url(${backGroundRigth});

    background-repeat: no-repeat;

    background-position: right;
  }
  .background {
    display: grid;

    grid-template-columns: 0.5fr 1fr 0.5fr;
  }
  .title {
    font-family: ${FONT_API_PORTAL_REGISTER_SUCCESS_TITLE.fontFamily};
    font-weight: ${FONT_API_PORTAL_REGISTER_SUCCESS_TITLE.fontWeight};
    font-size: ${FONT_API_PORTAL_REGISTER_SUCCESS_TITLE.fontSize};
    line-height: ${FONT_API_PORTAL_REGISTER_SUCCESS_TITLE.lineHeight};
    text-align: center;
    width: 100%;
    margin: auto;
    max-width: 638px;
    margin-top: 175px;
  }

  .description {
    font-family: ${FONT_API_PORTAL_REGISTER_SUCCESS_DESCRIPTION.fontFamily};
    font-style: ${FONT_API_PORTAL_REGISTER_SUCCESS_DESCRIPTION.fontStyle};
    font-weight: ${FONT_API_PORTAL_REGISTER_SUCCESS_DESCRIPTION.fontWeight};
    font-size: ${FONT_API_PORTAL_REGISTER_SUCCESS_DESCRIPTION.fontSize};
    line-height: ${FONT_API_PORTAL_REGISTER_SUCCESS_DESCRIPTION.lineHeight};
    margin-top: 0px;
    margin-bottom: 0px;
    align-items: center;
    color: var(--chakra-colors-grey-120);
    width: 100%;
    max-width: 588px;
    margin: auto;
    margin-top: 24px;
    text-align: center;
  }
  .noMargin {
    margin-top: 1px;
  }
  .chakra-button {
    font-family: ${FONT_API_PORTAL_REGISTER_SUCCESS_BTN.fontFamily};
    font-style: ${FONT_API_PORTAL_REGISTER_SUCCESS_BTN.fontStyle};
    font-weight: ${FONT_API_PORTAL_REGISTER_SUCCESS_BTN.fontWeight};
    font-size: ${FONT_API_PORTAL_REGISTER_SUCCESS_BTN.fontSize};
    text-align: center;
    padding: 10px 24px;
    position: relative;
    width: 204px;
    height: 40px;

    border: 0;
  }
  .centered {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 100%;
    align-items: center;
    text-align: center;
    margin-top: 45px;
  }
  .volver {
    margin-top: 10px;
  }
`;
export default StyledRegisterSuccess;
