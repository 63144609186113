import styled from 'styled-components';

import {
  FONT_BANK_BOXES_CARD,
  FONT_BANK_BOXES_CARD_AVATAR,
  FONT_BANK_BOXES_CARD_DOLLARS,
  FONT_BANK_BOXES_CARD_PESOS,
  FONT_BANK_BOXES_CARD_SUPERSCRIPT,
} from '../../designTokens/fonts';

const StyledBankBoxesCard = styled.div`
  position: relative;

  a {
    text-decoration: none;
    color: inherit;
  }

  .chakra-avatar {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px var(--chakra-colors-secondary-100);
    background-color: var(--chakra-colors-tertiary-30);
    position: absolute;
    left: 28px;
    top: -18px;
    .chakra-avatar__initials {
      color: var(--chakra-colors-tertiary-100);
      font-weight: ${FONT_BANK_BOXES_CARD_AVATAR.fontWeight};
      font-size: ${FONT_BANK_BOXES_CARD_AVATAR.fontSize};
    }
    img {
      display: block;
      width: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .account_box {
    z-index: -1;
    width: 294px;
    height: 150px;
    background: white;
    border: 1px solid var(--chakra-colors-neutral-50);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    padding: 0px;
    position: relative;
    box-shadow: 0px 3.58351px 17.9175px rgba(236, 226, 255, 0.6);
    font-family: ${FONT_BANK_BOXES_CARD.fontFamily};
    font-style: ${FONT_BANK_BOXES_CARD.fontStyle};
    overflow: hidden;

    &__body {
      padding: 0px 28px;
      height: 89px;
      margin-bottom: 0px;
    }

    &__footer {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 0px 28px;
      color: var(--chakra-colors-secondary-110);
      background-color: var(--chakra-colors-tertiary-30);
    }

    &__footer--updated {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 0px 28px;
      color: white;
      background-color: var(--chakra-colors-success-100);
    }

    &__accounts {
      margin: 28px 0px 8px 0px;
      color: var(--chakra-colors-neutral-100);
    }

    &__pesos {
      font-weight: ${FONT_BANK_BOXES_CARD_PESOS.fontWeight};
      font-size: ${FONT_BANK_BOXES_CARD_PESOS.fontSize};
      line-height: ${FONT_BANK_BOXES_CARD_PESOS.lineHeight};
      color: var(--chakra-colors-tertiary-100);
      margin: 0px 0px 3px 0px;
    }

    &__dollars {
      font-weight: ${FONT_BANK_BOXES_CARD_DOLLARS.fontWeight};
      font-size: ${FONT_BANK_BOXES_CARD_DOLLARS.fontSize};
      line-height: ${FONT_BANK_BOXES_CARD_DOLLARS.lineHeight};
      color: var(--chakra-colors-grey-100);
      margin: 0px;
    }

    .negative-balance {
      color: var(--chakra-colors-danger-100);
    }

    sup {
      font-family: ${FONT_BANK_BOXES_CARD_SUPERSCRIPT.fontWeight};
      font-weight: ${FONT_BANK_BOXES_CARD_SUPERSCRIPT.fontWeight};
      font-size: ${FONT_BANK_BOXES_CARD_SUPERSCRIPT.fontSize};
      line-height: ${FONT_BANK_BOXES_CARD_SUPERSCRIPT.lineHeight};
      letter-spacing: 0.4px;
    }
  }
`;

export default StyledBankBoxesCard;
