import styled from 'styled-components';

import backGroundLeft from '../assets/images/API-portal-signin-BG-image-left.svg';
import backGroundRigth from '../assets/images/API-portal-signin-BG-image-rigth.svg';
import {
  FONT_SIGNIN_API_PORTAL_BODY_ACCESS,
  FONT_SIGNIN_API_PORTAL_BODY_ACCESS_LINK,
  FONT_SIGNIN_API_PORTAL_BODY_ACCESS_TEXT,
  FONT_SIGNIN_API_PORTAL_BODY_TITLE,
  FONT_SIGNIN_API_PORTAL_BREADCRUMB,
  FONT_SIGNIN_API_PORTAL_CONTAINER,
  FONT_SIGNIN_API_PORTAL_FORM_USER,
  FONT_SIGNIN_API_PORTAL_INPUT_PASSWORD,
  FONT_SIGNIN_API_PORTAL_PASSWORD_VERIFICATION,
  FONT_SIGNIN_API_PORTAL_PASSWORD_WARNING,
} from '../designTokens/fonts';

const StyledSigninAPIPortal = styled.div`
  .signin {
    display: grid;
    grid-template-columns: 0.5fr 1fr 0.5fr;

    &__bg-left {
      background-image: url(${backGroundLeft});
      background-repeat: no-repeat;
      background-position: left;
    }

    &__bg-rigth {
      background-image: url(${backGroundRigth});
      background-repeat: no-repeat;
      background-position: right;
    }

    &__breadcrum {
      font-family: ${FONT_SIGNIN_API_PORTAL_BREADCRUMB.fontFamily};
      font-style: ${FONT_SIGNIN_API_PORTAL_BREADCRUMB.fontStyle};
      font-weight: ${FONT_SIGNIN_API_PORTAL_BREADCRUMB.fontWeight};
      font-size: ${FONT_SIGNIN_API_PORTAL_BREADCRUMB.fontSize};
      letter-spacing: ${FONT_SIGNIN_API_PORTAL_BREADCRUMB.letterSpacing};
      align-items: center;
      color: var(--chakra-colors-tertiary-100);
      margin-bottom: 55px;

      &__item-active {
        color: var(--chakra-colors-secondary-100);
      }

      & ol {
        margin: 0px;

        svg {
          top: 3px;
          position: relative;
        }
      }
    }

    &__body {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-bottom: 69px;

      &__title {
        font-family: ${FONT_SIGNIN_API_PORTAL_BODY_TITLE.fontFamily};
        font-style: ${FONT_SIGNIN_API_PORTAL_BODY_TITLE.fontStyle};
        font-weight: ${FONT_SIGNIN_API_PORTAL_BODY_TITLE.fontWeight};
        font-size: ${FONT_SIGNIN_API_PORTAL_BODY_TITLE.fontSize};
        line-height: ${FONT_SIGNIN_API_PORTAL_BODY_TITLE.lineHeight};
        max-width: 530px;
        text-align: center;
        color: var(--chakra-colors-grey-120);
        margin-bottom: 20px;
      }

      &__portal-access {
        display: flex;
        align-items: center;
        margin-bottom: 37px;
        font-family: ${FONT_SIGNIN_API_PORTAL_BODY_ACCESS.fontFamily};
        font-style: ${FONT_SIGNIN_API_PORTAL_BODY_ACCESS.fontStyle};
        letter-spacing: ${FONT_SIGNIN_API_PORTAL_BODY_ACCESS.letterSpacing};

        &__text {
          font-weight: ${FONT_SIGNIN_API_PORTAL_BODY_ACCESS_TEXT.fontWeight};
          font-size: ${FONT_SIGNIN_API_PORTAL_BODY_ACCESS_TEXT.fontSize};
          line-height: ${FONT_SIGNIN_API_PORTAL_BODY_ACCESS_TEXT.lineHeight};
          color: var(--chakra-colors-grey-120);
          margin-right: 5px;
        }
        &__link {
          font-weight: ${FONT_SIGNIN_API_PORTAL_BODY_ACCESS_LINK.fontWeight};
          font-size: ${FONT_SIGNIN_API_PORTAL_BODY_ACCESS_LINK.fontSize};
          line-height: ${FONT_SIGNIN_API_PORTAL_BODY_ACCESS_LINK.lineHeight};
          text-decoration: underline;
          color: var(--chakra-colors-primary-100);
          cursor: pointer;
        }
      }

      &__form {
        max-width: 295px;

        &__input-user {
          padding: 0px 10px;
          margin-bottom: 12px;
        }

        &__text-user {
          font-family: ${FONT_SIGNIN_API_PORTAL_FORM_USER.fontFamily};
          font-style: ${FONT_SIGNIN_API_PORTAL_FORM_USER.fontStyle};
          font-weight: ${FONT_SIGNIN_API_PORTAL_FORM_USER.fontWeight};
          font-size: ${FONT_SIGNIN_API_PORTAL_FORM_USER.fontSize};
          line-height: ${FONT_SIGNIN_API_PORTAL_FORM_USER.lineHeight};
          color: var(--chakra-colors-secondary-100);
          text-align: justify;
          margin-bottom: 29px;
          height: 30px;
        }

        &__input-password {
          margin-bottom: 5px;

          &__box {
            padding: 0px 40px 0px 10px;
          }

          &__icon {
            z-index: 0;
            width: 32px;
            height: 32px;
            right: 2px;
            top: 2px;
            cursor: pointer;
            border-radius: 5px;
            transition: all 0.3s ease-in-out;

            &:hover {
              background-color: var(--chakra-colors-grey-40);
            }
          }

          &__text {
            font-family: ${FONT_SIGNIN_API_PORTAL_INPUT_PASSWORD.fontFamily};
            font-style: ${FONT_SIGNIN_API_PORTAL_INPUT_PASSWORD.fontStyle};
            font-weight: ${FONT_SIGNIN_API_PORTAL_INPUT_PASSWORD.fontWeight};
            font-size: ${FONT_SIGNIN_API_PORTAL_INPUT_PASSWORD.fontSize};
            line-height: ${FONT_SIGNIN_API_PORTAL_INPUT_PASSWORD.lineHeight};
            color: var(--chakra-colors-secondary-100);
            text-align: justify;
            margin-bottom: 5px;
            height: 30px;
          }
        }

        &__input-password-confirm {
          &__box {
            padding: 0px 40px 0px 10px;
          }

          &__icon {
            z-index: 0;
            width: 32px;
            height: 32px;
            right: 2px;
            top: 2px;
            cursor: pointer;
            border-radius: 5px;
            transition: all 0.3s ease-in-out;

            &:hover {
              background-color: var(--chakra-colors-grey-40);
            }
          }
        }

        &__password-warning {
          font-family: ${FONT_SIGNIN_API_PORTAL_PASSWORD_WARNING.fontFamily};
          font-style: ${FONT_SIGNIN_API_PORTAL_PASSWORD_WARNING.fontStyle};
          font-weight: ${FONT_SIGNIN_API_PORTAL_PASSWORD_WARNING.fontWeight};
          font-size: ${FONT_SIGNIN_API_PORTAL_PASSWORD_WARNING.fontSize};
          line-height: ${FONT_SIGNIN_API_PORTAL_PASSWORD_WARNING.lineHeight};
          letter-spacing: ${FONT_SIGNIN_API_PORTAL_PASSWORD_WARNING.letterSpacing};
          color: var(--chakra-colors-error-100);
          margin-bottom: 15px;
          margin-left: 10px;
        }
      }

      &__password-verification {
        width: 295px;
        margin-bottom: 25px;

        &__title {
          font-family: ${FONT_SIGNIN_API_PORTAL_PASSWORD_VERIFICATION.fontFamily};
          font-style: ${FONT_SIGNIN_API_PORTAL_PASSWORD_VERIFICATION.fontStyle};
          font-weight: ${FONT_SIGNIN_API_PORTAL_PASSWORD_VERIFICATION.fontWeight};
          font-size: ${FONT_SIGNIN_API_PORTAL_PASSWORD_VERIFICATION.fontSize};
          line-height: ${FONT_SIGNIN_API_PORTAL_PASSWORD_VERIFICATION.lineHeight};
          letter-spacing: ${FONT_SIGNIN_API_PORTAL_PASSWORD_VERIFICATION.letterSpacing};
          width: 241px;
          color: var(--chakra-colors-secondary-100);
          margin-bottom: 17.5px;
        }

        &__container {
          &__item {
            display: flex;
            align-items: center;
            margin-bottom: 17px;

            &__icon {
              display: flex;
              justify-content: center;
              align-items: center;
              display: flex;
              width: 20px;
              height: 20px;
              color: var(--chakra-colors-grey-60);
            }

            &__text {
              font-family: ${FONT_SIGNIN_API_PORTAL_CONTAINER.fontFamily};
              font-style: ${FONT_SIGNIN_API_PORTAL_CONTAINER.fontStyle};
              font-weight: ${FONT_SIGNIN_API_PORTAL_CONTAINER.fontWeight};
              font-size: ${FONT_SIGNIN_API_PORTAL_CONTAINER.fontSize};
              line-height: ${FONT_SIGNIN_API_PORTAL_CONTAINER.lineHeight};
              text-align: justify;
              margin-left: 5px;
              width: 270px;
            }
          }
        }
      }

      &__create-account-btn {
        width: 204px;
        height: 40px;
      }
    }
  }

  .text-resolved {
    color: var(--chakra-colors-grey-120);
  }
  .text-unresolved {
    color: var(--chakra-colors-secondary-100);
  }

  .show-password-warning {
    visibility: visible;
  }
  .hide-password-warning {
    visibility: hidden;
  }

  .text-incorrect {
    color: var(--chakra-colors-error-100);
  }

  .chakra-input {
    &:focus {
      z-index: 0;
    }
  }
`;

export default StyledSigninAPIPortal;
