/* eslint-disable react/jsx-no-useless-fragment */
import { Button, Tooltip } from '@chakra-ui/react';
import {
  Column,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  RowData,
  SortingState,
  Updater,
  useReactTable,
} from '@tanstack/react-table';
import React, { useEffect, useRef, useState } from 'react';
import { FiChevronDown, FiChevronLeft, FiChevronRight, FiChevronUp, FiSettings } from 'react-icons/fi';
import styled from 'styled-components';

import { FONT_TABLE_COMPONENT, FONT_TABLE_COMPONENT_PAGINATION } from '../../designTokens/fonts';
import plainText from '../../lang/es.json';

interface Page {
  cantidadElementosXPagina: number;
  paginaActual: number;
  totalElementos: number;
  totalPaginas: number;
}
export interface TableProps<T> {
  inputData: T[];
  defaultColumns: ColumnDef<RowData>[];
  hiddenColumns?: Record<string, boolean>;
  defaultColumnsCheckbox?: Array<string>;
  setPagination: (updater: Updater<PaginationState>) => void;
  pagination: PaginationState;
  paginationData: Page;
}

function TableComponent<T>({
  inputData,
  defaultColumns,
  hiddenColumns,
  defaultColumnsCheckbox = [],
  setPagination,
  pagination,
  paginationData,
}: TableProps<T>) {
  const [showPersonalizar, setShowPersonalizar] = useState(false);
  const [columns] = useState<typeof defaultColumns>(() => [...defaultColumns]);
  const [sorting] = React.useState<SortingState>([]);
  const { getAllLeafColumns, getHeaderGroups, getRowModel, nextPage, previousPage } = useReactTable({
    data: inputData ? (inputData as unknown as RowData[]) : [],
    columns,
    initialState: {
      columnVisibility: hiddenColumns,
      pagination,
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    sortDescFirst: false,
  });

  const columnsDefaultTable = (column: Column<RowData>) => {
    if (!defaultColumnsCheckbox.includes(column.id)) {
      return columnCheckboxComponent(column);
    }
    return false;
  };

  const columnCheckboxComponent = (column: Column<RowData>) => {
    return (
      <div key={column.id} className='px-1 custom-hover'>
        <label htmlFor={`check-settings-${column.id}`}>
          <>
            <input
              {...{
                id: `check-settings-${column.id}`,
                type: 'checkbox',
                checked: column.getIsVisible(),
                onChange: column.getToggleVisibilityHandler(),
              }}
            />{' '}
            {column.columnDef.header}
          </>
        </label>
      </div>
    );
  };
  const currentPage = paginationData && paginationData.paginaActual + 1;
  const totalPages = paginationData?.totalPaginas;

  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
        setShowPersonalizar(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef, buttonRef, setShowPersonalizar]);

  return (
    <>
      <Personalizar>
        <div>
          <Button
            variant='tertiary-outline'
            leftIcon={<FiSettings />}
            onClick={() => {
              setShowPersonalizar(!showPersonalizar);
            }}
            ref={buttonRef}
          >
            {plainText.button.customize}
          </Button>

          <PersonalizarDropDown>
            <div ref={dropdownRef} className={showPersonalizar ? 'show-dropdown' : 'hide'}>
              {getAllLeafColumns().map(column => columnsDefaultTable(column))}
            </div>
          </PersonalizarDropDown>
        </div>
      </Personalizar>
      <div style={{ overflowX: 'scroll' }}>
        <Table>
          <thead>
            {getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    role='button'
                    colSpan={header.colSpan}
                    onClick={header.column.getToggleSortingHandler()}
                    onKeyDown={header.column.getToggleSortingHandler()}
                    tabIndex={0}
                  >
                    <span>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: <FiChevronUp />,
                        desc: <FiChevronDown />,
                      }[header.column.getIsSorted() as string] ?? null}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {getRowModel().rows.map(row => (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <PaginationController>
        <div className='wrapper'>
          {inputData ? (
            <span>
              {Number.isNaN(currentPage) ? 0 : currentPage} de {Number.isNaN(totalPages) ? 0 : totalPages}
            </span>
          ) : (
            <div className='space-holder' />
          )}
          {currentPage && currentPage > 1 ? (
            <Tooltip label='Anterior'>
              <button onClick={() => previousPage()}>
                <FiChevronLeft />
              </button>
            </Tooltip>
          ) : (
            <div className='space-holder' />
          )}
          {currentPage < totalPages ? (
            <div>
              {totalPages && totalPages > 1 ? (
                <Tooltip label='Siguiente'>
                  <button onClick={() => nextPage()}>
                    <FiChevronRight />
                  </button>
                </Tooltip>
              ) : (
                <div className='space-holder' />
              )}
            </div>
          ) : (
            <div className='space-holder' />
          )}
        </div>
      </PaginationController>
    </>
  );
}

export default TableComponent;

const Personalizar = styled.div`
  margin: 20px 46px 20px 0;
  display: flex;
  justify-content: flex-end;
`;
const PersonalizarDropDown = styled.div`
  div {
    position: relative;
    &.hide {
      display: none;
    }
    &.show-dropdown {
      right: 30px;
      margin-top: 8px;
      position: absolute;
      display: flex;
      flex-direction: column;
      z-index: 1;
      width: 185px;
      background-color: white;
      border-radius: 8px;
      box-shadow: rgb(106 122 138 / 36%) 0px 4px 17px;
    }
    &.custom-hover label {
      cursor: pointer;
    }
  }
`;

const Table = styled.table`
  margin: 0;
  width: 100%;
  border-collapse: collapse;
  font-size: ${FONT_TABLE_COMPONENT.fontSizeTable};
  overflow-x: scroll;
  tr {
    border-bottom: 1px solid var(--chakra-colors-grey-40);
    height: 55px;
  }
  td {
    padding: 0;
    width: 60px;
    &:nth-child(2) {
      width: 70px;
    }
  }
  thead {
    background-color: var(--chakra-colors-grey-40);
    height: 55px;
    span {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      font-size: ${FONT_TABLE_COMPONENT.fontSizeThead};
      cursor: pointer;
    }
  }
  tbody td {
    text-align: center;
    height: 35px;
    font-size: ${FONT_TABLE_COMPONENT.fontSizeTBody};
  }
`;
const PaginationController = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 20px 0 35px 0;
  .wrapper {
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      margin-left: 18px;
      border: none;
      display: grid;
      align-content: center;
      background-color: transparent;
      font-size: ${FONT_TABLE_COMPONENT_PAGINATION.fontSizeButton};
      cursor: pointer;
    }
    .space-holder {
      margin-left: 18px;
      border: none;
      display: grid;
      align-content: center;
      background-color: transparent;
      width: 18px;
      height: 18px;
    }
    span {
      text-align: center;
      font-size: ${FONT_TABLE_COMPONENT_PAGINATION.fontSizeSpan};
    }
  }
`;
