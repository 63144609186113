import { ColumnDef, PaginationState, RowData, Updater } from '@tanstack/react-table';
import { useEffect, useState } from 'react';

import { IConsolidadoSaldos, IConsolidatedBalanceTable } from '../../Interfaces/IConsSaldos';
import TableComponent from '../TableComponent/TableComponent';
import CSaldosColumns from './ColumnsConfig';

interface IInputDataTable {
  datosTabla: IConsolidatedBalanceTable;
  setPagination: (updater: Updater<PaginationState>) => void;
  pagination: PaginationState;
}

function TablaConsolidado({ datosTabla, pagination, setPagination }: IInputDataTable) {
  const [finalData, setFinalData] = useState([]);

  // une los 2 objetos que vienen del endpoint seprados, juntos construyen cada fila de la tabla
  useEffect(() => {
    if (datosTabla) {
      const dataConcat =
        datosTabla &&
        datosTabla?.consolidadoSaldos?.map(e => {
          return Object.assign(e.cuenta, e.saldos);
        });

      // ordenamiento por default de la tabla de mayor a menor segun saldos
      const sortedData =
        dataConcat &&
        dataConcat.sort((a, b) => {
          if (a.saldoContable < b.saldoContable) return 1;
          if (a.saldoContable > b.saldoContable) return -1;
          return 0;
        });
      setFinalData(sortedData);
    }
  }, [datosTabla]);
  return (
    <TableComponent<IConsolidadoSaldos[]>
      inputData={finalData}
      defaultColumns={CSaldosColumns as ColumnDef<RowData>[]}
      defaultColumnsCheckbox={['Banco', 'Cuenta', 'Operativo actual']}
      setPagination={setPagination}
      pagination={pagination}
      paginationData={datosTabla.page}
    />
  );
}

export default TablaConsolidado;
