import { Text } from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { HeaderBase } from '@react-ib-mf/header-menu-ui';
// eslint-disable-next-line import/no-unresolved
import { pushAnalyticsEvent, useHasNewPlan } from '@react-ib-mf/style-guide-ui';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import noAccessPicture from '../assets/images/no-access.svg';
import Alert from '../components/Alert';
import ConsumptionCards from '../components/ConsumptionCards';
import ConsumptionPlanBanner from '../components/ConsumptionPlanBanner';
import ErrorSection from '../components/ErrorSection';
import plainText from '../lang/es.json';
import { getConsumptionsCard, getConsumptionsPlanBanner, getOfferingNotification } from '../services/api';
import { StyledConsumption, StyledErrorSectionConsumption } from './StyledConsumption';

function Consumption() {
  const [hasNewPlan] = useHasNewPlan();

  // Consumption card api call
  const {
    data: consumptionCardsDataResponse,
    status: consumptionCardsStatus,
    refetch: consumptionCardsRefetch,
    isFetching: consumptionCardsIsFetching,
  } = useQuery('consumption-cards', () => getConsumptionsCard().then(res => res.data.data), {
    refetchOnMount: 'always',
    cacheTime: 100, // The time in milliseconds that unused/inactive cache data remains in memory.
    enabled: hasNewPlan || false,
  });

  // Consumption offering notification api call
  const {
    data: consumptionPlanBannerDataResponse,
    status: consumptionPlanBannerStatus,
    refetch: consumptionPlanBannerRefetch,
  } = useQuery('consumption-plan', () => getConsumptionsPlanBanner().then(res => res.data.data), {
    refetchOnMount: 'always',
    cacheTime: 100, // The time in milliseconds that unused/inactive cache data remains in memory.
    enabled: hasNewPlan || false,
  });

  // Consumption alert notification api call
  const { data: offeringNotificationDataResponse } = useQuery(
    'offering-notification',
    () => getOfferingNotification().then(res => res?.data),
    {
      retry: false,
      enabled: hasNewPlan || false,
    },
  );

  const alertVariant = {
    INFO: {
      variant: 'info',
    },
    UPDATE: {
      variant: 'success',
    },
  };

  const showAlert = offeringNotificationDataResponse?.data?.type === 'INFO' || offeringNotificationDataResponse?.data?.type === 'UPDATE';
  const [showContent, setShowContent] = useState(true);
  const [showContentCards, setShowContentCards] = useState(true);
  const [showContentPlanBanner, setShowContentPlanBanner] = useState(true);

  useEffect(() => {
    if (!showContentPlanBanner && !showContentCards) {
      setShowContent(false);
    } else {
      setShowContent(true);
    }
  }, [showContentPlanBanner, showContentCards]);

  useEffect(() => {
    pushAnalyticsEvent({
      event: 'trackContentGroup',
      content_group: 'Mi consumo',
    });
  }, []);

  useEffect(() => {
    if (
      offeringNotificationDataResponse?.data?.title &&
      (offeringNotificationDataResponse?.data?.type === 'INFO' || offeringNotificationDataResponse?.data?.type === 'UPDATE')
    ) {
      pushAnalyticsEvent({
        event: 'impression_modal',
        modal_name: offeringNotificationDataResponse?.data?.title,
      });
    }
  }, [offeringNotificationDataResponse]);

  const renderConsumptionContent = () => {
    if (showContent) {
      return (
        <div className='consumption__content'>
          {showAlert && (
            <Alert
              variant={alertVariant[offeringNotificationDataResponse?.data?.type].variant}
              title={offeringNotificationDataResponse?.data?.title}
              description={offeringNotificationDataResponse?.data?.description}
            />
          )}

          <ConsumptionPlanBanner
            consumptionPlanBannerDataResponse={consumptionPlanBannerDataResponse}
            consumptionPlanBannerStatus={consumptionPlanBannerStatus}
            consumptionPlanBannerRefetch={consumptionPlanBannerRefetch}
            showContentPlanBanner={showContentPlanBanner}
            setShowContentPlanBanner={setShowContentPlanBanner}
          />

          <Text className='consumption__content__subtitle' textStyle='h4Bold' data-testid='txt-consumptionSubtitle'>
            {plainText.consumption.subtitle}
          </Text>
          <Text className='consumption__content__subtitle-description' textStyle='bodyMd' data-testid='txt-consumptionSubtitleDescription'>
            {plainText.consumption.subtitleDescription}
          </Text>

          <ConsumptionCards
            consumptionCardsDataResponse={consumptionCardsDataResponse}
            consumptionCardsStatus={consumptionCardsStatus}
            consumptionCardsRefetch={consumptionCardsRefetch}
            consumptionCardsIsFetching={consumptionCardsIsFetching}
            showContentCards={showContentCards}
            setShowContentCards={setShowContentCards}
          />
        </div>
      );
    }

    return (
      <ErrorSection
        errorPictureDataTestId='icon-errorSection'
        titleText={plainText.consumption.errorPage.title}
        titleDataTestId='txt-errorSectionTitle'
        descriptionText={plainText.consumption.errorPage.description}
        descriptionDataTestId='txt-errorSectionDescription'
        buttonText={plainText.consumption.errorPage.buttonText}
        buttonDataTestId='btn-errorSection'
        callbackAction={() => window.location.reload()}
      />
    );
  };

  // Empty state when the user has no new plan
  if (hasNewPlan === false) {
    const descriptionElement = <div dangerouslySetInnerHTML={{ __html: plainText.consumption.errorSection.description }} />;

    return (
      <StyledErrorSectionConsumption>
        <ErrorSection errorPicture={noAccessPicture} descriptionText={descriptionElement} redirectTo='/' />
      </StyledErrorSectionConsumption>
    );
  }

  if (!hasNewPlan) {
    return null;
  }

  return (
    <StyledConsumption>
      <HeaderBase
        breadcrumb={[
          { id: '0', name: plainText.breadcrumb.consumption.itemName1, linkTo: '/' },
          { id: '1', name: plainText.breadcrumb.consumption.itemName2 },
        ]}
      />

      <Text className='consumption__title' textStyle='h2Bold' data-testid='txt-consumptionTitle'>
        {plainText.consumption.title}
      </Text>
      <Text className='consumption__description' textStyle='bodyMd' data-testid='txt-consumptionDescription'>
        {plainText.consumption.description}
      </Text>

      {renderConsumptionContent()}
    </StyledConsumption>
  );
}

export default Consumption;
