import { ColumnDef, PaginationState, RowData, Updater } from '@tanstack/react-table';
import { useEffect, useState } from 'react';

import { IMovementsDayTable } from '../../Interfaces/IMovementsDay';
import TableComponent from '../TableComponent/TableComponent';
import CMovimientoColumns from './ColumsConfig';

interface TableMovimientosProps {
  setPagination: (updater: Updater<PaginationState>) => void;
  data: IMovementsDayTable;
  pagination: PaginationState;
}

function TablaMovimientos({ data, setPagination, pagination }: TableMovimientosProps) {
  const [finalData, setFinalData] = useState([]);

  useEffect(() => {
    // Ordenamiento por default de la tabla de mayor a menor segun saldos
    if (data) {
      const sortedData =
        data &&
        data?.movimientosCuentas?.sort((a, b) => {
          if (a.movimiento.importe < b.movimiento.importe) return 1;
          if (a.movimiento.importe > b.movimiento.importe) return -1;
          return 0;
        });

      setFinalData(sortedData);
    }
  }, [data]);
  return (
    <TableComponent<IMovementsDayTable>
      inputData={finalData}
      defaultColumns={CMovimientoColumns as ColumnDef<RowData>[]}
      hiddenColumns={{ cuit: false, Den_ordenante: false }}
      defaultColumnsCheckbox={['Banco', 'Cuenta', 'Importe']}
      setPagination={setPagination}
      pagination={pagination}
      paginationData={data.page}
    />
  );
}

export default TablaMovimientos;
