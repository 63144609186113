export function containValueInJSON(json, valuesToFind) {
  const valuesArray = json ? Object.values(json) : [];

  // Check each value in the json looking for any of the values to find
  let hasValue = valuesArray.some(value => valuesToFind.includes(value));

  valuesArray.forEach(function (value) {
    if (typeof value === 'object') hasValue = hasValue || containValueInJSON(value, valuesToFind);
  });

  return hasValue;
}

export const groupBy = (collection, iteratee = x => x) => {
  // Manual JS groupBy
  const it = typeof iteratee === 'function' ? iteratee : ({ [iteratee]: prop }) => prop;

  const array = Array.isArray(collection) ? collection : Object.values(collection);

  return array.reduce((r, e) => {
    const k = it(e);
    // eslint-disable-next-line no-param-reassign
    r[k] = r[k] || [];
    r[k].push(e);
    return r;
  }, {});
};
