import { Box, Text } from '@chakra-ui/react';
import styled from 'styled-components';

import BannerBackground from '../../../assets/images/register-banner-background.png';
import {
  FONT_API_PORTAL_SANDBOX_DESCRIPTION,
  FONT_API_PORTAL_SANDBOX_SUBTITLE,
  FONT_API_PORTAL_SANDBOX_TITLE,
} from '../../../designTokens/fonts';
import plainText from '../../../lang/es.json';
import Button from './Button';

interface SandboxProps {
  onCreateAccount: () => void;
}

function Sandbox({ onCreateAccount }: SandboxProps) {
  return (
    <Background>
      <Box px='8' py='6'>
        <Text
          fontSize={FONT_API_PORTAL_SANDBOX_TITLE.fontSize}
          fontWeight={FONT_API_PORTAL_SANDBOX_TITLE.fontWeight}
          letterSpacing={FONT_API_PORTAL_SANDBOX_TITLE.letterSpacing}
          color='secondary.130'
          mb={3}
          mt={1}
        >
          {plainText.message.sandboxAPIPortal.title}
        </Text>
        <Text
          fontSize={FONT_API_PORTAL_SANDBOX_SUBTITLE.fontSize}
          fontWeight={FONT_API_PORTAL_SANDBOX_SUBTITLE.fontWeight}
          m='0'
          maxW='500px'
        >
          {plainText.message.sandboxAPIPortal.subtitle}
        </Text>
        <Text
          fontSize={FONT_API_PORTAL_SANDBOX_DESCRIPTION.fontSize}
          fontWeight={FONT_API_PORTAL_SANDBOX_DESCRIPTION.fontWeight}
          letterSpacing={FONT_API_PORTAL_SANDBOX_DESCRIPTION.letterSpacing}
          maxW='25%'
          mb={8}
        >
          {plainText.message.sandboxAPIPortal.description}
        </Text>
        <Button onClick={onCreateAccount} text={plainText.button.createTestAccount} />
      </Box>
    </Background>
  );
}

const Background = styled.div`
  background: linear-gradient(90deg, rgba(218, 226, 249, 1) 25%, rgba(0, 212, 255, 0) 75%), url(${BannerBackground});
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3));
  background-position: 28% 72%;
  background-size: cover;
  width: 100%;
  border-radius: 12px;
  margin-top: 30px;
  margin-bottom: 30px;
`;

export default Sandbox;
