import { AlertComponent, AlertDescription, AlertTitle } from '@wow/alert';
import React, { useState } from 'react';

import StyledAlert from './StyledAlert';

interface AlertProps {
  id?: string;
  customClassName?: string;
  customStyles?: Record<string, unknown>;
  variant?: 'success' | 'information' | 'brand' | 'warning' | 'destructive';
  title?: string;
  description?: string;
  buttonLabel?: string;
  alertDataTestId?: string;
  alertTitleDataTestId?: string;
  alertDescriptionDataTestId?: string;
  OnClick?: () => void;
  onDismiss?: () => void;
}

function Alert({
  id,
  customClassName,
  customStyles,
  variant = 'information',
  title,
  description,
  buttonLabel,
  alertDataTestId,
  alertTitleDataTestId,
  alertDescriptionDataTestId,
  OnClick,
  onDismiss,
}: AlertProps) {
  const [showAlert, setShowAlert] = useState(true);
  const hideAlert = () => {
    onDismiss?.();
    setShowAlert(false);
  };

  if (!showAlert) {
    return null;
  }

  return (
    <StyledAlert style={customStyles}>
      <AlertComponent
        id={id}
        className={`alert-wrapper ${customClassName}`}
        variant={variant}
        buttonLabel={buttonLabel}
        onClick={OnClick}
        onClose={hideAlert}
        data-testid={alertDataTestId}
      >
        <AlertTitle data-testid={alertTitleDataTestId}>{title}</AlertTitle>
        <AlertDescription data-testid={alertDescriptionDataTestId}>{description}</AlertDescription>
      </AlertComponent>
    </StyledAlert>
  );
}

export default Alert;
