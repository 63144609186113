import { Box, Button, FormControl, Icon, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { HeaderBase, useAuthorizer } from '@react-ib-mf/header-menu-ui';
// eslint-disable-next-line import/no-unresolved
import { hasConsecutiveChars, hasEqualsChars, useErrorMessage } from '@react-ib-mf/style-guide-ui';
import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { FiCheck, FiEye, FiEyeOff, FiX } from 'react-icons/fi';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import plainText from '../lang/es.json';
import { postRegisterApiPortal } from '../services/api';
import StyledSigninAPIPortal from './StyledSigninAPIPortal';

export interface ApiPortalSignin {
  username: string;
  password: string;
  // eslint-disable-next-line camelcase
  confirm_password: string;
}

interface ApiPortalSigninRespError {
  response: {
    data: {
      data: {
        error: string;
      };
    };
  };
}

function SigninAPIPortal() {
  const navigate = useNavigate();

  const [{ validateSession }] = useAuthorizer();
  useEffect(() => {
    validateSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const handleShowPassword = () => setShowPassword(!showPassword);
  const handleShowPasswordConfirm = () => setShowPasswordConfirm(!showPasswordConfirm);

  const [finalUserName, setFinalUserName] = useState('');
  const [finalPassword, setFinalPassword] = useState('');
  const [finalPasswordConfirm, setFinalPasswordConfirm] = useState('');

  const [passwordsMatchStatus, setpasswordsMatchStatus] = useState('empty');
  const [createButtonStatus, setCreateButtonStatus] = useState('btn-disabled');

  const [userNameStatus, setUserNameStatus] = useState('empty');
  const [passwordStatus, setPasswordStatus] = useState('empty');
  const [userNamePasswordEqualityStatus, setUserNamePasswordEquality] = useState('empty');

  const { showErrorMessage } = useErrorMessage();

  const passwordVerification = [
    {
      id: 1,
      text: plainText.passwordVerificationAPIPortal.verificationAlphanumericLimit,
      status: 'empty',
    },
    {
      id: 2,
      text: plainText.passwordVerificationAPIPortal.verificationSpecialChar,
      status: 'empty',
    },
    {
      id: 3,
      text: plainText.passwordVerificationAPIPortal.verificationConsecutiveEqualChar,
      status: 'empty',
    },
    {
      id: 4,
      text: plainText.passwordVerificationAPIPortal.verificationProgressiveChar,
      status: 'empty',
    },
  ];
  const [passwordVerificationArray, setPasswordVerificationArray] = useState(passwordVerification);

  const userMinLength = 8;
  const userMaxLength = 50;

  const passwordMinLength = 8;
  const passwordMaxLength = 20;

  function buildBreadcrumb() {
    return (
      <Box px={4}>
        <HeaderBase
          breadcrumb={[
            { id: '0', name: plainText.breadcrumb.signinAPIPortal.itemName1, linkTo: '/' },
            { id: '1', name: plainText.breadcrumb.signinAPIPortal.itemName2 },
            { id: '2', name: plainText.breadcrumb.signinAPIPortal.itemName3, linkTo: '/api-portal' },
            { id: '3', name: plainText.breadcrumb.signinAPIPortal.itemName4 },
          ]}
        />
      </Box>
    );
  }

  function buildPasswordVerification() {
    return (
      <div className='signin__body__password-verification__container'>
        {passwordVerificationArray.map(item => (
          <div className='signin__body__password-verification__container__item' key={item.id}>
            <div className='signin__body__password-verification__container__item__icon'>
              {item.status === 'empty' ? (
                <div>•</div>
              ) : (
                <Icon as={item.status === 'correct' ? FiCheck : FiX} h={5} w={5} color={item.status === 'correct' ? 'green' : 'red'} />
              )}
            </div>
            <div
              className={`signin__body__password-verification__container__item__text ${
                item.status === 'empty' ? 'text-unresolved' : 'text-resolved'
              }`}
            >
              {item.text}
            </div>
          </div>
        ))}
      </div>
    );
  }

  function defineUser(event) {
    const requiredRegex = /^[a-zA-Z0-9.,_\-@]*$/;
    const userName = event.target.value;
    setFinalUserName(userName);

    if (userName.length >= userMinLength && userName.length <= userMaxLength && requiredRegex.test(userName)) {
      setUserNameStatus('correct');
    } else if (userName === '') {
      setUserNameStatus('empty');
    } else {
      setUserNameStatus('incorrect');
    }
  }

  function definePassword(event) {
    const password = event.target.value;
    setFinalPassword(password);
  }

  function defineConfirmPassword(event) {
    const confirmedPassword = event.target.value;
    setFinalPasswordConfirm(confirmedPassword);
  }

  function onCopyPasteHandler(event) {
    event.preventDefault();
    return false;
  }

  function submitRegisterData() {
    const builtObj = {
      username: finalUserName,
      password: finalPassword,
      confirm_password: finalPasswordConfirm,
    };

    mutate(builtObj);
  }

  const {
    mutate,
    error: errorApiPortalSignin,
    isError,
    isSuccess,
    isLoading,
  } = useMutation<AxiosResponse, ApiPortalSigninRespError, ApiPortalSignin>((newAPIPortalUser: ApiPortalSignin) => {
    return postRegisterApiPortal(newAPIPortalUser);
  });

  // Change the API call status
  useEffect(() => {
    if (isError) {
      showErrorMessage({ title: plainText.toast.error.signinAPIPortal, error: errorApiPortalSignin });
    } else if (isSuccess) {
      // Redirect confirmation page
      navigate('exitoso');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isSuccess, isError, errorApiPortalSignin]);

  // Password and username equality
  useEffect(() => {
    if (finalUserName !== '' && finalPassword !== '') {
      if (finalUserName === finalPassword) {
        setUserNamePasswordEquality('equal');
      } else {
        setUserNamePasswordEquality('different');
      }
    } else {
      setUserNamePasswordEquality('empty');
    }
  }, [finalUserName, finalPassword]);

  // Password verification
  useEffect(() => {
    const newPasswordVerificationState = [...passwordVerificationArray];

    const validation1 = newPasswordVerificationState.find(item => item.id === 1);
    const validation2 = newPasswordVerificationState.find(item => item.id === 2);
    const validation3 = newPasswordVerificationState.find(item => item.id === 3);
    const validation4 = newPasswordVerificationState.find(item => item.id === 4);

    const requiredRegex = /^[a-zA-Z0-9.,!$#%]*$/;

    // Validation 1 status' change
    if (finalPassword.length >= passwordMinLength && finalPassword.length <= passwordMaxLength) {
      validation1.status = 'correct';
    } else {
      validation1.status = 'incorrect';
    }

    // Validation 2 status' change
    if (requiredRegex.test(finalPassword)) {
      validation2.status = 'correct';
    } else {
      validation2.status = 'incorrect';
    }

    // Validation 3 status' change
    if (!hasEqualsChars(finalPassword)) {
      validation3.status = 'correct';
    } else {
      validation3.status = 'incorrect';
    }

    // Validation 4 status' change
    if (!hasConsecutiveChars(finalPassword, true, true)) {
      validation4.status = 'correct';
    } else {
      validation4.status = 'incorrect';
    }

    // Default status if the input is empty
    if (finalPassword === '') {
      validation1.status = 'empty';
      validation2.status = 'empty';
      validation3.status = 'empty';
      validation4.status = 'empty';
    }

    // Update password status
    setPasswordVerificationArray(newPasswordVerificationState);

    // Global status password validation
    if (newPasswordVerificationState.every(item => item.status === 'correct')) {
      // If all the validation status are correct
      setPasswordStatus('correct');
    } else if (finalPassword.length === 0) {
      setPasswordStatus('empty');
    } else {
      setPasswordStatus('incorrect');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalPassword]);

  // Check password matched
  useEffect(() => {
    if (finalPassword === finalPasswordConfirm) {
      setpasswordsMatchStatus('correct');
    } else {
      setpasswordsMatchStatus('incorrect');
    }

    if (finalPasswordConfirm === '') {
      setpasswordsMatchStatus('empty');
    }
  }, [finalPassword, finalPasswordConfirm]);

  // Set the button enables/disabled
  useEffect(() => {
    if (
      userNameStatus === 'correct' &&
      passwordStatus === 'correct' &&
      passwordsMatchStatus === 'correct' &&
      userNamePasswordEqualityStatus === 'different'
    ) {
      setCreateButtonStatus('btn-enabled');
    } else {
      setCreateButtonStatus('btn-disabled');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userNameStatus, passwordStatus, passwordsMatchStatus, userNamePasswordEqualityStatus]);

  return (
    <StyledSigninAPIPortal>
      {buildBreadcrumb()}
      <div className='signin'>
        <div className='signin__bg-left' />

        <div className='signin__body'>
          <div className='signin__body__title'>{plainText.message.signinAPIPortal.title}</div>

          <div className='signin__body__portal-access'>
            <div className='signin__body__portal-access__text'>{plainText.message.signinAPIPortal.portalAccessText}</div>
            <a href={process.env.API_PORTAL_URL} target='_blank' className='signin__body__portal-access__link' rel='noreferrer'>
              {plainText.message.signinAPIPortal.portalAccessLink}
            </a>
          </div>

          <FormControl className='signin__body__form' isDisabled={isLoading}>
            <Input
              data-testid='inpt-user'
              className='signin__body__form__input-user'
              type='text'
              placeholder={plainText.placeHolder.user}
              onChange={event => defineUser(event)}
              isInvalid={userNameStatus === 'incorrect'}
            />
            <div className={`signin__body__form__text-user ${userNameStatus === 'incorrect' ? 'text-incorrect' : ''}`}>
              {userNameStatus === 'correct' || userNameStatus === 'empty'
                ? plainText.message.signinAPIPortal.userValidCharacters
                : plainText.message.signinAPIPortal.userWarning}
            </div>

            <InputGroup className='signin__body__form__input-password'>
              <Input
                data-testid='inpt-password'
                className='signin__body__form__input-password__box'
                type={showPassword ? 'text' : 'password'}
                placeholder={plainText.placeHolder.password}
                id='password-box'
                onChange={event => definePassword(event)}
                onCopy={event => onCopyPasteHandler(event)}
                onPaste={event => onCopyPasteHandler(event)}
                isInvalid={passwordStatus === 'incorrect' || userNamePasswordEqualityStatus === 'equal'}
              />

              <InputRightElement className='signin__body__form__input-password__icon' onClick={handleShowPassword}>
                <Icon
                  as={showPassword ? FiEye : FiEyeOff}
                  h={5}
                  w={5}
                  color={`${passwordStatus === 'incorrect' || userNamePasswordEqualityStatus === 'equal' ? 'red' : 'black'}`}
                />
              </InputRightElement>
            </InputGroup>

            <div className='signin__body__form__input-password__text text-incorrect'>
              {userNamePasswordEqualityStatus === 'equal' ? 'El nombre de usuario y la contraseña no pueden ser iguales.' : ''}
            </div>

            <InputGroup className='signin__body__form__input-password-confirm'>
              <Input
                data-testid='inpt-repeatPassword'
                className='signin__body__form__input-password-confirm__box'
                type={showPasswordConfirm ? 'text' : 'password'}
                placeholder={plainText.placeHolder.confirmPassword}
                id='confirm-password-box'
                onChange={event => defineConfirmPassword(event)}
                onCopy={event => onCopyPasteHandler(event)}
                onPaste={event => onCopyPasteHandler(event)}
                isInvalid={passwordsMatchStatus === 'incorrect'}
              />

              <InputRightElement className='signin__body__form__input-password-confirm__icon' onClick={handleShowPasswordConfirm}>
                <Icon
                  as={showPasswordConfirm ? FiEye : FiEyeOff}
                  h={5}
                  w={5}
                  color={`${passwordsMatchStatus === 'incorrect' ? 'red' : 'black'}`}
                />
              </InputRightElement>
            </InputGroup>

            <div
              className={`signin__body__form__password-warning ${
                passwordsMatchStatus === 'incorrect' ? 'show-password-warning' : 'hide-password-warning'
              }`}
            >
              {plainText.message.signinAPIPortal.passwordConfirmWarning}
            </div>
          </FormControl>

          <div className='signin__body__password-verification'>
            <div className='signin__body__password-verification__title'>{plainText.message.signinAPIPortal.passwordVerificationTitle}</div>
            {buildPasswordVerification()}
          </div>

          <Button
            className='signin__body__create-account-btn'
            loadingText={plainText.button.process}
            isLoading={isLoading}
            disabled={createButtonStatus === 'btn-disabled'}
            onClick={() => submitRegisterData()}
            variant='primary'
          >
            {plainText.button.createAccount}
          </Button>
        </div>

        <div className='signin__bg-rigth' />
      </div>
    </StyledSigninAPIPortal>
  );
}

export default SigninAPIPortal;
