import { HStack } from '@chakra-ui/react';

import Effective from '../../../assets/images/effective-card-logo.svg';
import Free from '../../../assets/images/free-card-logo.svg';
import Secure from '../../../assets/images/secure-card-logo.svg';
import Simple from '../../../assets/images/simple-card-logo.svg';
import plainText from '../../../lang/es.json';
import Benefit from './Benefit';

const list = [
  {
    id: '01',
    icon: Simple,
    title: plainText.benefitList.benefitSimple.title,
    subtitle: plainText.benefitList.benefitSimple.subtitle,
  },
  {
    id: '02',
    icon: Effective,
    title: plainText.benefitList.benefitEffective.title,
    subtitle: plainText.benefitList.benefitEffective.subtitle,
  },
  {
    id: '03',
    icon: Secure,
    title: plainText.benefitList.benefitSecure.title,
    subtitle: plainText.benefitList.benefitSecure.subtitle,
  },
  {
    id: '04',
    icon: Free,
    title: plainText.benefitList.benefitFree.title,
    subtitle: plainText.benefitList.benefitFree.subtitle,
  },
];

function BenefitList() {
  const renderList = () => list.map(({ id, icon, title, subtitle }) => <Benefit key={id} icon={icon} title={title} subtitle={subtitle} />);

  return (
    <>
      <h1>{plainText.message.benefitsTitle}</h1>
      <HStack
        direction={['column', 'row']}
        borderBottom='1px solid var(--chakra-colors-grey-60)'
        display='flex'
        justifyContent='space-between'
      >
        {renderList()}
      </HStack>
    </>
  );
}

export default BenefitList;
