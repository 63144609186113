import { themeChakra } from '@wow/themes';

// OBJETIVO: Centralizar los estilos de cada componente en un único archivo para poder cambiarlos rápidamente
// sin tener que navegar por el componente. Esto usando la tipografia disponible del theme de wow, Libreria de DS.
// http://devdesignsystem-ui.apps.dev.ib.ar/?path=/docs/design-tokens-typography--page

// APP
export const FONT_APP = {
  letterSpacing: '0.075rem',
};

// API PORTAL BENEFITS
export const FONT_API_PORTAL_BENEFITS = {
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
  fontSizeTitle: themeChakra.textStyles.h6.fontSize,
  fontSizeDescription: themeChakra.textStyles.labelLg.fontSize,
};

// API PORTAL LOGIN BANNER
export const FONT_API_PORTAL_LOGIN_BANNER = {
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
  fontSizeTitle: themeChakra.textStyles.h2.fontSize,
  fontSizeDescription: themeChakra.textStyles.labelSm.fontSize,
  letterSpacing: themeChakra.textStyles.bodySm.letterSpacing,
};

// API PORTAL REGISTER BANNER
export const FONT_API_PORTAL_REGISTER_BANNER = {
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
  fontSizeTitle: themeChakra.textStyles.h2.fontSize,
  fontSizeDescription: themeChakra.textStyles.titleMd.fontSize,
};

// API PORTAL SANDBOX
export const FONT_API_PORTAL_SANDBOX_TITLE = {
  fontWeight: themeChakra.textStyles.bodyMdSemi.fontWeight,
  letterSpacing: themeChakra.textStyles.bodySm.letterSpacing,
  fontSize: themeChakra.textStyles.labelLg.fontSize,
};

export const FONT_API_PORTAL_SANDBOX_SUBTITLE = {
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
  fontSize: themeChakra.textStyles.h2.fontSize,
};

export const FONT_API_PORTAL_SANDBOX_DESCRIPTION = {
  fontWeight: themeChakra.textStyles.bodyMdSemi.fontWeight,
  fontSize: themeChakra.textStyles.labelSm.fontSize,
  letterSpacing: themeChakra.textStyles.bodySm.letterSpacing,
};

// API PORTAL SOLUTION SECTION CARDS
export const FONT_API_PORTAL_SOLUTION_CARDS_TITLE = {
  fontFamily: themeChakra.textStyles.h1.fontFamily,
  fontStyle: 'normal',
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
  fontSize: themeChakra.textStyles.h2.fontSize,
  lineHeight: themeChakra.textStyles.h2.lineHeight,
};

export const FONT_API_PORTAL_SOLUTION_CARDS_SUBTITLE = {
  fontFamily: themeChakra.textStyles.labelLg.fontFamily,
  fontStyle: 'normal',
  fontWeight: themeChakra.textStyles.bodyMdSemi.fontWeight,
  fontSize: themeChakra.textStyles.labelSm.fontSize,
  lineHeight: themeChakra.textStyles.labelSm.lineHeight,
  letterSpacing: '1.2px',
};

export const FONT_API_PORTAL_SOLUTION_CARDS_DESCRIPTION = {
  fontFamily: themeChakra.textStyles.labelLg.fontFamily,
  fontStyle: 'normal',
  fontWeight: themeChakra.textStyles.labelLg.fontWeight,
  fontSize: themeChakra.textStyles.labelLg.fontSize,
  lineHeight: themeChakra.textStyles.labelLg.lineHeight,
  letterSpacing: themeChakra.textStyles.bodyMd.letterSpacing,
};

export const FONT_API_PORTAL_SOLUTION_CARDS_FOOTER = {
  fontFamily: themeChakra.textStyles.labelLg.fontFamily,
  fontStyle: 'normal',
  fontWeight: themeChakra.textStyles.labelSm.fontWeight,
  fontSize: themeChakra.textStyles.labelXs.fontSize,
  lineHeight: themeChakra.textStyles.labelSm.lineHeight,
};

// API PORTAL SOLUTION SECTION
export const FONT_API_PORTAL_SOLUTION_SECTION_TITLE = {
  fontFamily: themeChakra.textStyles.h1.fontFamily,
  fontStyle: 'normal',
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
  fontSize: themeChakra.textStyles.h2.fontSize,
  lineHeight: themeChakra.textStyles.h2.lineHeight,
};

export const FONT_API_PORTAL_SOLUTION_SECTION_DESCRIPTION = {
  fontFamily: themeChakra.textStyles.labelLg.fontFamily,
  fontStyle: 'normal',
  fontWeight: themeChakra.textStyles.labelLg.fontWeight,
  fontSize: themeChakra.textStyles.titleMd.fontSize,
  lineHeight: themeChakra.textStyles.bodyLg.lineHeight,
};

export const FONT_API_PORTAL_SOLUTION_SECTION_LINK = {
  fontFamily: themeChakra.textStyles.labelLg.fontFamily,
  fontStyle: 'normal',
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
  fontSize: themeChakra.textStyles.labelLg.fontSize,
  lineHeight: themeChakra.textStyles.labelLg.lineHeight,
  letterSpacing: themeChakra.textStyles.titleMd.letterSpacing,
};

// API PORTAL INTEGRATIONS
export const FONT_API_PORTAL_INTEGRATIONS_TITLE = {
  fontFamily: themeChakra.textStyles.h1.fontFamily,
  fontStyle: 'normal',
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
  fontSize: themeChakra.textStyles.h2.fontSize,
  lineHeight: themeChakra.textStyles.h2.lineHeight,
};

export const FONT_API_PORTAL_INTEGRATIONS_DESCRIPTION = {
  fontFamily: themeChakra.textStyles.labelLg.fontFamily,
  fontStyle: 'normal',
  fontWeight: themeChakra.textStyles.labelLg.fontWeight,
  fontSize: themeChakra.textStyles.titleMd.fontSize,
  lineHeight: themeChakra.textStyles.bodyLg.lineHeight,
};

// API PORTAL REGISTER SUCCESS
export const FONT_API_PORTAL_REGISTER_SUCCESS_TITLE = {
  fontFamily: themeChakra.textStyles.h1.fontFamily,
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
  fontSize: themeChakra.textStyles.h3.fontSize,
  lineHeight: themeChakra.textStyles.h3.lineHeight,
};

export const FONT_API_PORTAL_REGISTER_SUCCESS_DESCRIPTION = {
  fontFamily: themeChakra.textStyles.labelLg.fontFamily,
  fontStyle: 'normal',
  fontWeight: themeChakra.textStyles.labelSm.fontWeight,
  fontSize: themeChakra.textStyles.titleMd.fontSize,
  lineHeight: themeChakra.textStyles.bodyLg.lineHeight,
};

export const FONT_API_PORTAL_REGISTER_SUCCESS_BTN = {
  fontFamily: themeChakra.textStyles.labelLg.fontFamily,
  fontStyle: 'normal',
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
  fontSize: themeChakra.textStyles.labelLg.fontSize,
};

// BANK BOXES CARD
export const FONT_BANK_BOXES_CARD = {
  fontFamily: themeChakra.textStyles.h1.fontFamily,
  fontStyle: 'normal',
};

export const FONT_BANK_BOXES_CARD_AVATAR = {
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
  fontSize: themeChakra.textStyles.h6.fontSize,
};

export const FONT_BANK_BOXES_CARD_PESOS = {
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
  fontSize: themeChakra.textStyles.h4Bold.fontSize,
  lineHeight: themeChakra.textStyles.titleMd.lineHeight,
};

export const FONT_BANK_BOXES_CARD_DOLLARS = {
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
  fontSize: themeChakra.textStyles.h4Bold.fontSize,
  lineHeight: themeChakra.textStyles.titleMd.lineHeight,
};

export const FONT_BANK_BOXES_CARD_SUPERSCRIPT = {
  fontFamily: themeChakra.textStyles.labelSm.fontFamily,
  fontSize: themeChakra.textStyles.labelSm.fontSize,
  lineHeight: themeChakra.textStyles.labelSm.lineHeight,
  fontWeight: themeChakra.textStyles.labelSm.fontWeight,
};

// BANK BOXES HEADER
export const FONT_BANK_BOXES_HEADER_BTN = {
  fontFamily: themeChakra.textStyles.labelLg.fontFamily,
  fontStyle: 'normal',
  fontWeight: themeChakra.textStyles.bodyMdSemi.fontWeight,
  fontSize: themeChakra.textStyles.labelSm.fontSize,
  lineHeight: themeChakra.textStyles.labelSm.lineHeight,
};

export const FONT_BANK_BOXES_HEADER_TEXT = {
  fontFamily: themeChakra.textStyles.h1.fontFamily,
  fontStyle: 'normal',
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
  fontSize: themeChakra.textStyles.titleXl.fontSize,
  lineHeight: themeChakra.textStyles.h3.fontSize,
};

// BANK BOXES POPUP
export const FONT_BANK_BOXES_POPUP_TITLE = {
  fontFamily: themeChakra.textStyles.h1.fontFamily,
  fontStyle: 'normal',
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
  fontSize: themeChakra.textStyles.titleXl.fontSize,
  lineHeight: themeChakra.textStyles.h3.fontSize,
};

export const FONT_BANK_BOXES_POPUP_SUBTITLE = {
  fontFamily: themeChakra.textStyles.h1.fontFamily,
  fontStyle: 'normal',
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
  fontSize: themeChakra.textStyles.h6.fontSize,
  lineHeight: themeChakra.textStyles.titleMd.lineHeight,
};

export const FONT_BANK_BOXES_POPUP_ACCOUNT_BOX = {
  fontFamily: themeChakra.textStyles.h1.fontFamily,
  fontStyle: 'normal',
};

export const FONT_BANK_BOXES_POPUP_AVATAR = {
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
  fontSize: themeChakra.textStyles.h6.fontSize,
};

export const FONT_BANK_BOXES_POPUP_ACCOUNTS = {
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
  fontSize: themeChakra.textStyles.h6.fontSize,
  lineHeight: themeChakra.textStyles.titleMd.lineHeight,
};

export const FONT_BANK_BOXES_POPUP_PESOS = {
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
  fontSize: themeChakra.textStyles.labelLg.fontSize,
  lineHeight: themeChakra.textStyles.labelLg.lineHeight,
};

export const FONT_BANK_BOXES_POPUP_DOLLARS = {
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
  fontSize: themeChakra.textStyles.labelLg.fontSize,
  lineHeight: themeChakra.textStyles.labelLg.lineHeight,
};

// BANNER SUBSCRIPTION BANNER DEFAULT
export const FONT_BANNER_SUBSCRIPTION_DEFAULT = {
  fontFamily: themeChakra.textStyles.labelLg.fontFamily,
  fontSize: themeChakra.textStyles.h4.fontSize,
};

// BANNER SUBSCRIPTION
export const FONT_BANNER_SUBSCRIPTION = {
  fontStyleContainer: 'normal',
  fontSize: themeChakra.textStyles.labelSm.fontSize,
  fontWeight: themeChakra.textStyles.bodyMdSemi.fontWeight,
  fontStyle: 'unset',
  fontSizeMin: themeChakra.textStyles.labelMd.lineHeight,
  lineHeight: themeChakra.textStyles.labelLg.lineHeight,
};

// BANNER SUBSCRIPTION INDEX
export const FONT_BANNER_SUBSCRIPTION_INDEX = {
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
};

// CHART CARD ERROR
export const FONT_CHART_CARD_ERROR_BOX = {
  fontSizeTitle: themeChakra.textStyles.h6.fontSize,
  fontSize: themeChakra.textStyles.labelLg.fontSize,
  fontWeight: themeChakra.textStyles.labelLg.fontWeight,
};

export const FONT_CHART_CARD_ERROR_MODAL_HEADER = {
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
  fontSize: themeChakra.textStyles.h6.fontSize,
};

export const FONT_CHART_CARD_ERROR_MODAL_BODY = {
  fontWeight: themeChakra.textStyles.labelLg.fontWeight,
  fontSize: themeChakra.textStyles.labelLg.fontSize,
};

export const FONT_CHART_CARD_ERROR_MODAL_FOOTER_BTN = {
  fontWeight: themeChakra.textStyles.labelLg.fontWeight,
  fontSize: themeChakra.textStyles.labelSm.fontSize,
};

// CONSOLIDADO SALDOS
export const FONT_CONSOLIDADO_SALDOS = {
  fontSize: themeChakra.textStyles.titleXl.fontSize,
};

// CONSOLIDATE BALANCE ERROR
export const FONT_CONSOLIDATE_BALANCE_ERROR = {
  fontSizeTitle: themeChakra.textStyles.h6.fontSize,
  fontSize: themeChakra.textStyles.labelLg.fontSize,
  fontWeight: 'normal',
};

export const FONT_CONSOLIDATE_BALANCE_ERROR_MODAL_HEADER = {
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
  fontSize: themeChakra.textStyles.h6.fontSize,
};

export const FONT_CONSOLIDATE_BALANCE_ERROR_MODAL_BODY = {
  fontWeight: themeChakra.textStyles.labelLg.fontWeight,
  fontSize: themeChakra.textStyles.labelLg.fontSize,
};

export const FONT_CONSOLIDATE_BALANCE_ERROR_MODAL_FOOTER_BTN = {
  fontWeight: themeChakra.textStyles.labelLg.fontWeight,
  fontSize: themeChakra.textStyles.labelSm.fontSize,
};

// CONSOLIDATE BALANCE INDEX
export const FONT_CONSOLIDATE_BALANCE = {
  fontWeight: 'normal',
  fontSize: themeChakra.textStyles.labelLg.fontSize,
  fontSizeMedia: themeChakra.textStyles.labelXs.fontSize,
};

export const FONT_CONSOLIDATE_BALANCE_AMOUNT = {
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
  fontSize: themeChakra.textStyles.titleMd.fontSize,
};

export const FONT_CONSOLIDATE_BALANCE_TITLE = {
  fontSize: themeChakra.textStyles.labelLg.fontSize,
  fontSizeMedia: themeChakra.textStyles.labelSm.fontSize,
};

export const FONT_CONSOLIDATE_BALANCE_CURRENCY = {
  fontSize: themeChakra.textStyles.labelLg.fontSize,
  fontSizeMedia: themeChakra.textStyles.labelSm.fontSize,
};

export const FONT_CONSOLIDATE_BALANCE_CURRENCY_TITLE = {
  fontSize: themeChakra.textStyles.titleMd.fontSize,
  fontSizeMedia: themeChakra.textStyles.labelLg.fontSize,
};

export const FONT_CONSOLIDATE_BALANCE_MODAL_HEADER = {
  fontWeight: themeChakra.textStyles.h3.fontWeight,
  fontSize: themeChakra.textStyles.h3.fontSize,
};

export const FONT_CONSOLIDATE_BALANCE_MODAL_BODY = {
  fontWeight: themeChakra.textStyles.bodyMd.fontWeight,
  fontSize: themeChakra.textStyles.bodyMd.fontSize,
};

export const FONT_CONSOLIDATE_BALANCE_ERROR_BAR = {
  fontSize: themeChakra.textStyles.labelLg.fontSize,
  fontWeight: 'normal',
  fontSizeMedia: themeChakra.textStyles.labelXs.fontSize,
};

// CRITICAL ERROR
export const FONT_CRITICAL_ERROR = {
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
};

// CUSTOM SELECT CHECKBOX
export const FONT_CUSTOM_SELECT_CHECKBOX = {
  fontSize: themeChakra.textStyles.titleMdBold.fontSize,
};

// HEADER SIB
export const FONT_HEADER_SIB = {
  fontSize: themeChakra.textStyles.titleXl.fontSize,
};

// MOVEMENTS CARDS
export const FONT_MOVEMENTS_CARDS_ERROR_BAR = {
  fontWeight: 'normal',
  fontSize: themeChakra.textStyles.labelLg.fontSize,
  fontSizeMedia: themeChakra.textStyles.labelXs.fontSize,
};

export const FONT_MOVEMENTS_CARDS_AMOUNT = {
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
  fontSize: themeChakra.textStyles.titleMd.fontSize,
};

export const FONT_MOVEMENTS_CARDS_TITLE = {
  fontSize: themeChakra.textStyles.labelLg.fontSize,
  fontSizeMedia: themeChakra.textStyles.labelSm.fontSize,
};

export const FONT_MOVEMENTS_CARDS_CURRENCY = {
  fontSize: themeChakra.textStyles.labelLg.fontSize,
  fontSizeMedia1040: themeChakra.textStyles.labelSm.fontSize,
  fontSizeMedia900: themeChakra.textStyles.labelXs.fontSize,
};

export const FONT_MOVEMENTS_CARDS_CURRENCY_TITLE = {
  fontSize: themeChakra.textStyles.titleMd.fontSize,
  fontSizeMedia: themeChakra.textStyles.labelLg.fontSize,
};

// TABLE COMPONENT
export const FONT_TABLE_COMPONENT = {
  fontSizeTable: '0.85vw',
  fontSizeThead: themeChakra.textStyles.labelLg.fontSize,
  fontSizeTBody: themeChakra.textStyles.labelLg.fontSize,
};

export const FONT_TABLE_COMPONENT_PAGINATION = {
  fontSizeButton: themeChakra.textStyles.h6.fontSize,
  fontSizeSpan: themeChakra.textStyles.labelSm.fontSize,
};

// THIRD LEVEL MENU
export const FONT_THIRD_LEVEL_MENU_ERROR_TITLE = {
  fontFamily: themeChakra.textStyles.h1.fontFamily,
  fontStyle: 'normal',
  fontWeight: themeChakra.textStyles.h1.fontWeight,
  fontSize: themeChakra.textStyles.h1.fontSize,
  lineHeight: themeChakra.textStyles.h1.lineHeight,
};

export const FONT_THIRD_LEVEL_MENU_ERROR_DESCRIPTION = {
  fontFamily: themeChakra.textStyles.labelLg.fontFamily,
  fontStyle: 'normal',
  fontWeight: themeChakra.textStyles.labelLg.fontWeight,
  fontSize: themeChakra.textStyles.titleMd.fontSize,
  lineHeight: themeChakra.textStyles.bodyLg.lineHeight,
};

export const FONT_THIRD_LEVEL_MENU_ACTION = {
  fontFamily: themeChakra.textStyles.labelLg.fontFamily,
  fontStyle: 'normal',
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
  fontSize: themeChakra.textStyles.labelLg.fontSize,
  lineHeight: themeChakra.textStyles.labelLg.lineHeight,
  letterSpacing: themeChakra.textStyles.titleMd.letterSpacing,
};

export const FONT_THIRD_LEVEL_MENU_CARD_TITLE = {
  fontFamily: themeChakra.textStyles.h6.fontFamily,
  fontStyle: 'normal',
  fontWeight: themeChakra.textStyles.h6.fontWeight,
  fontSize: themeChakra.textStyles.h6.fontSize,
  lineHeight: themeChakra.textStyles.h6.lineHeight,
};

export const FONT_THIRD_LEVEL_MENU_CARD_DESCRIPTION = {
  fontFamily: themeChakra.textStyles.labelLg.fontFamily,
  fontStyle: 'normal',
  fontWeight: themeChakra.textStyles.labelLg.fontWeight,
  fontSize: themeChakra.textStyles.labelLg.fontSize,
  lineHeight: themeChakra.textStyles.labelLg.lineHeight,
  letterSpacing: themeChakra.textStyles.bodyMd.letterSpacing,
};

// WEEKLY TRANSFER
export const FONT_WEEKLY_TRANSFER_MESSAGE_ALERT = {
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
  fontSize: themeChakra.textStyles.h5.fontSize,
};

export const FONT_WEEKLY_TRANSFER_MESSAGE_ALERT_TEXT = {
  fontSize: themeChakra.textStyles.h5.fontSize,
  fontSizeP: themeChakra.textStyles.labelMd.lineHeight,
};

// WELCOME BANNER
export const FONT_WELCOME_BANNER_TITLE_BIGGER = {
  fontFamily: themeChakra.textStyles.h2.fontFamily,
  fontStyle: 'normal',
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
  fontSize: themeChakra.textStyles.h2.fontSize,
  lineHeight: themeChakra.textStyles.h2.lineHeight,
};

export const FONT_WELCOME_BANNER_TITLE_LOWER = {
  fontFamily: themeChakra.textStyles.h2.fontFamily,
  fontStyle: 'normal',
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
  fontSize: themeChakra.textStyles.h6.fontSize,
};

export const FONT_WELCOME_BANNER_DESCRIPTION = {
  fontFamily: themeChakra.textStyles.labelLg.fontFamily,
  fontStyle: 'normal',
  fontWeight: themeChakra.textStyles.bodyMdSemi.fontWeight,
  fontSize: themeChakra.textStyles.labelSm.fontSize,
  lineHeight: themeChakra.textStyles.labelMd.lineHeight,
};

// VIEW HOME
export const FONT_VIEW_HOME = {
  fontFamily: themeChakra.textStyles.h1.fontFamily,
};

export const FONT_VIEW_HOME_CHART_BOXES = {
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
  fontSize: themeChakra.textStyles.labelMd.fontSize,
  fontWeightMedia1024: themeChakra.textStyles.labelSm.fontWeight,
  fontSizeMedia1024: themeChakra.textStyles.labelMd.lineHeight,
  fontWeightMedia768: themeChakra.textStyles.labelLg.fontWeight,
  fontSizeMedia768: themeChakra.textStyles.labelLg.fontSize,
};

export const FONT_VIEW_HOME_TITLES = {
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
  fontSize: themeChakra.textStyles.labelLg.fontSize,
  lineHeight: themeChakra.textStyles.labelMd.lineHeight,
};

export const FONT_VIEW_HOME_FEATURED = {
  fontSize: themeChakra.textStyles.labelMd.fontSize,
};

// SIGNIN API PORTAL
export const FONT_SIGNIN_API_PORTAL_BREADCRUMB = {
  fontFamily: themeChakra.textStyles.labelLg.fontFamily,
  fontStyle: 'normal',
  fontWeight: themeChakra.textStyles.labelLg.fontWeight,
  fontSize: themeChakra.textStyles.labelSm.fontSize,
  letterSpacing: themeChakra.textStyles.bodySm.letterSpacing,
};

export const FONT_SIGNIN_API_PORTAL_BODY_TITLE = {
  fontFamily: themeChakra.textStyles.h1.fontFamily,
  fontStyle: 'normal',
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
  fontSize: themeChakra.textStyles.h3.fontSize,
  lineHeight: themeChakra.textStyles.h3.lineHeight,
};

export const FONT_SIGNIN_API_PORTAL_BODY_ACCESS = {
  fontFamily: themeChakra.textStyles.labelLg.fontFamily,
  fontStyle: 'normal',
  letterSpacing: themeChakra.textStyles.titleMd.letterSpacing,
};

export const FONT_SIGNIN_API_PORTAL_BODY_ACCESS_TEXT = {
  fontWeight: themeChakra.textStyles.labelSm.fontWeight,
  fontSize: themeChakra.textStyles.titleMd.fontSize,
  lineHeight: themeChakra.textStyles.titleMd.lineHeight,
};

export const FONT_SIGNIN_API_PORTAL_BODY_ACCESS_LINK = {
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
  fontSize: themeChakra.textStyles.labelLg.fontSize,
  lineHeight: themeChakra.textStyles.labelLg.lineHeight,
};

export const FONT_SIGNIN_API_PORTAL_FORM_USER = {
  fontFamily: themeChakra.textStyles.labelLg.fontFamily,
  fontStyle: 'normal',
  fontWeight: themeChakra.textStyles.labelLg.fontWeight,
  fontSize: themeChakra.textStyles.labelSm.fontSize,
  lineHeight: themeChakra.textStyles.labelSm.lineHeight,
};

export const FONT_SIGNIN_API_PORTAL_INPUT_PASSWORD = {
  fontFamily: themeChakra.textStyles.labelLg.fontFamily,
  fontStyle: 'normal',
  fontWeight: themeChakra.textStyles.labelLg.fontWeight,
  fontSize: themeChakra.textStyles.labelSm.fontSize,
  lineHeight: themeChakra.textStyles.labelSm.lineHeight,
};

export const FONT_SIGNIN_API_PORTAL_PASSWORD_WARNING = {
  fontFamily: themeChakra.textStyles.labelLg.fontFamily,
  fontStyle: 'normal',
  fontWeight: themeChakra.textStyles.labelLg.fontWeight,
  fontSize: themeChakra.textStyles.labelSm.fontSize,
  lineHeight: themeChakra.textStyles.labelMd.lineHeight,
  letterSpacing: themeChakra.textStyles.bodySm.letterSpacing,
};

export const FONT_SIGNIN_API_PORTAL_PASSWORD_VERIFICATION = {
  fontFamily: themeChakra.textStyles.labelLg.fontFamily,
  fontStyle: 'normal',
  fontWeight: themeChakra.textStyles.labelLg.fontWeight,
  fontSize: themeChakra.textStyles.labelLg.fontSize,
  lineHeight: themeChakra.textStyles.labelLg.lineHeight,
  letterSpacing: themeChakra.textStyles.bodyMd.letterSpacing,
};

export const FONT_SIGNIN_API_PORTAL_CONTAINER = {
  fontFamily: themeChakra.textStyles.labelLg.fontFamily,
  fontStyle: 'normal',
  fontWeight: themeChakra.textStyles.labelLg.fontWeight,
  fontSize: themeChakra.textStyles.labelSm.fontSize,
  lineHeight: themeChakra.textStyles.labelSm.lineHeight,
};
