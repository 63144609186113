import { Skeleton, SkeletonCircle, Stack } from '@chakra-ui/react';
import {
  pushAnalyticsEvent,
  useHasNewPlan,
  usePlanName,
  useShowRelatedToMyBalance,
  useUserRole,
  // eslint-disable-next-line import/no-unresolved
} from '@react-ib-mf/style-guide-ui';
import React, { Suspense, useEffect, useState } from 'react';
import styled from 'styled-components';

import shieldIcon from '../assets/icons/shield.svg';
import BankBoxesContainer from '../components/BankBoxes';
import ContainerConsolidateBalance from '../components/ConsolidateBalance';
import EmailAlertComponent from '../components/EmailAlertComponent';
import MovementsCardsView from '../components/MovementsCard';
import OfferingNotification from '../components/OfferingNotification';
import PasswordNotification from '../components/PasswordNotification';
import PermissionMessageIcon from '../components/PermissionMessageIcon';
import PostOnboardingSection from '../components/PostOnboardingSection';
import WelcomeBanner from '../components/WelcomeBanner';
import usePermissions from '../hooks/usePermissions';
import plainText from '../lang/es.json';
import { getHomeAlertEmailsActions } from '../services/api';

const WeeklyTransfer = React.lazy(() => import('../components/weeklyTransfer'));
const FREE_PLAN_NAME = 'Plan Gratuito';
const GENERAL_ADMIN = 'Administrador General';

function Home() {
  // Show post-onboarding logic
  const [userRole] = useUserRole();
  const [hasNewPlan] = useHasNewPlan();
  const {
    hasConsolidatedBalancesPermission,
    hasMovementsOfTheDayPermission,
    hasUnreportedInterfacesPermission,
    hasStatusOfTransfersPermission,
    hasSeeBankBalancesPermission,
  } = usePermissions();

  const showPostOnboardingSection = hasNewPlan && userRole === GENERAL_ADMIN;

  // Show bankboxes logic
  const [currentPlanName] = usePlanName();
  const [, setShowRelatedToMyBalance] = useShowRelatedToMyBalance();
  const showBankBoxes = currentPlanName && !(currentPlanName === FREE_PLAN_NAME && !hasNewPlan);

  // Data for EmailAlertComponent
  const [dataAlert, setDataAlert] = useState({ addContactInfo: false, validateContactInfo: false });
  const anyDashboardPermissions =
    hasConsolidatedBalancesPermission ||
    hasMovementsOfTheDayPermission ||
    hasUnreportedInterfacesPermission ||
    hasStatusOfTransfersPermission ||
    hasSeeBankBalancesPermission;
  const { permissionGlobalMessageTitle } = plainText.message.chartCardConsolidateBalanceError;
  const { permissionGlobalMessageSubTitle } = plainText.message.chartCardConsolidateBalanceError;

  useEffect(() => {
    setShowRelatedToMyBalance(showBankBoxes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlanName]);

  useEffect(() => {
    pushAnalyticsEvent({
      event: 'trackContentGroup',
      content_group: 'Home',
    });
  }, []);

  // Open code for EmailAlertComponent
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getHomeAlertEmailsActions();
        setDataAlert({ addContactInfo: response.data.data.addContactInfo, validateContactInfo: response.data.data.validateContactInfo });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const settingAlertText = () => {
    if (dataAlert.addContactInfo && !dataAlert.validateContactInfo) {
      return {
        title: plainText.emailAlert.titleAgregar,
        description: plainText.emailAlert.descriptionAgregar,
        button: plainText.emailAlert.buttonAgregar,
      };
    }
    if (!dataAlert.addContactInfo && dataAlert.validateContactInfo) {
      return {
        title: plainText.emailAlert.titleValidar,
        description: plainText.emailAlert.descriptionValidar,
        button: plainText.emailAlert.buttonValidar,
      };
    }
    return null;
  };

  // Close code for EmailAlertComponent
  const borrar = userRole === 'USUARIODESCONOCIDO_';

  return (
    <>
      <PasswordNotification />
      {borrar && (dataAlert.addContactInfo || dataAlert.validateContactInfo) && (
        <EmailAlertComponent
          title={settingAlertText().title}
          description={settingAlertText().description}
          button={settingAlertText().button}
        />
      )}

      {hasNewPlan && <OfferingNotification />}

      {showPostOnboardingSection && <PostOnboardingSection />}

      <WelcomeBanner />

      <div className='dashboard_container'>
        {anyDashboardPermissions ? (
          <>
            {showBankBoxes && <BankBoxesContainer userBankBalancesPermission={hasSeeBankBalancesPermission} />}
            <BoxContainer id='charts_boxes'>
              <ContainerConsolidateBalance
                userConsolidatePermission={hasConsolidatedBalancesPermission}
                userUnreportedInterfacesPermission={hasUnreportedInterfacesPermission}
              />
              <MovementsCardsView userMovementsPermission={hasMovementsOfTheDayPermission} />

              <Suspense
                fallback={
                  <Stack as='section' className='box-loading'>
                    <Skeleton startColor='grey.50' endColor='grey.30' className='bar-loading' />
                    <SkeletonCircle size='200' startColor='grey.50' endColor='grey.30' />
                  </Stack>
                }
              >
                <WeeklyTransfer userStatusOfTransfersPermission={hasStatusOfTransfersPermission} />
              </Suspense>
            </BoxContainer>
          </>
        ) : (
          <PermissionMessageIcon icon={shieldIcon} title={permissionGlobalMessageTitle} message={permissionGlobalMessageSubTitle} />
        )}
      </div>
    </>
  );
}

export default Home;

const BoxContainer = styled.div`
  display: grid;
  margin-right: 40px;
  column-gap: 30px;
  row-gap: 30px;
  margin-bottom: 30px;
  @media (min-width: 768px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }
  > .chart_box {
    min-height: 300px;
  }
`;
