import { Checkbox, Tooltip } from '@chakra-ui/react';
import { components, OptionProps } from 'react-select';
import styled from 'styled-components';

import plainText from '../../lang/es.json';

function Option(props: OptionProps) {
  const { isSelected, label, isDisabled } = props;

  return (
    // Esta advertencia se omite debido a que no encuentro solución por el momento de este caso,
    // ya que la misma documentación de react-select me lo presenta así <components.Option {...props}>

    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.Option {...props}>
      <WrapOption>
        {isDisabled ? (
          <Tooltip label={plainText.label.dataNotUpdate} hasArrow placement='bottom'>
            <span>
              <Checkbox size='md' isChecked={isSelected} className='customCheck' isDisabled mr='2' />
              {label}
            </span>
          </Tooltip>
        ) : (
          <span>
            <Checkbox size='md' isChecked={isSelected} className='customCheck' mr='2' />
            {label}
          </span>
        )}
      </WrapOption>
    </components.Option>
    // Datos sin actualizar
  );
}

const WrapOption = styled.div`
  display: flex;
  align-items: center;
  .customCheck {
    pointer-events: none;
  }
  span {
    display: flex;
    align-items: center;
  }
`;

export default Option;
