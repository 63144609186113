// eslint-disable-next-line import/no-unresolved
import { useNavigate } from 'react-router-dom';

import IEmailAlertComponent from '../../Interfaces/IEmailAlertComponent';
import Alert from '../Alert';

function EmailAlertComponent({ title, description, button }: IEmailAlertComponent) {
  const navigate = useNavigate();

  const navigateProfile = () => {
    navigate('perfil');
  };

  return (
    <Alert
      id='EmailAlert'
      variant='warning'
      title={title}
      description={description}
      customStyles={{ marginRight: '40px', marginBottom: '16px' }}
      buttonLabel={button}
      OnClick={() => navigateProfile()}
    />
  );
}

export default EmailAlertComponent;
