import { Box, Grid, GridItem, Text } from '@chakra-ui/react';

import { FONT_API_PORTAL_BENEFITS } from '../../../designTokens/fonts';

interface BenefitProps {
  icon: string;
  title: string;
  subtitle: string;
}

function Benefit({ icon, title, subtitle }: BenefitProps) {
  return (
    <Box p='4' w='294px' h='133px' overflow='hidden'>
      <Grid templateColumns='repeat(5, 1fr)'>
        <GridItem rowSpan={2} colSpan={1} margin='0 auto'>
          <img src={icon} alt='img' />
        </GridItem>
        <GridItem colSpan={4}>
          <Text fontSize={FONT_API_PORTAL_BENEFITS.fontSizeTitle} fontWeight={FONT_API_PORTAL_BENEFITS.fontWeight} m='0'>
            {title}
          </Text>
        </GridItem>
        <GridItem colSpan={4}>
          <Text fontSize={FONT_API_PORTAL_BENEFITS.fontSizeDescription} m='0'>
            {subtitle}
          </Text>
        </GridItem>
      </Grid>
    </Box>
  );
}

export default Benefit;
