import styled from 'styled-components';

import { FONT_VIEW_HOME, FONT_VIEW_HOME_CHART_BOXES, FONT_VIEW_HOME_FEATURED, FONT_VIEW_HOME_TITLES } from '../designTokens/fonts';

const StyledBox = styled.div`
  font-family: ${FONT_VIEW_HOME.fontFamily};
  background: white;
  box-shadow: 0px 3.58351px 17.9175px var(--chakra-colors-grey-40);
  border-radius: 8px;

  padding-left: 15px;
  padding-top: 6px;
  padding-right: 5px;
  padding-bottom: 15px;

  &.minH-250 {
    min-height: 250px;
  }

  &.pt-0 {
    padding-top: 0px;
  }

  &.shadow-2 {
    box-shadow:
      0px 2px 6px 2px rgba(0, 0, 0, 0.15),
      0px 1px 2px 0px rgba(0, 0, 0, 0.3);
    border-radius: 24px;
  }

  .car-title {
    color: black;
  }
  .box-loading {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bar-loading {
    display: block;
    width: 100%;
    height: 30px;
    margin-bottom: 10px;
  }
  .header-title {
    display: flex;
    flex-direction: row;
    align-content:;
    align-items: center;

    .link {
      padding: 0.7em 0 0 0.7em;
    }
  }

  .card-title,
  .link {
    padding: 0 !important;
  }

  .card-title {
    margin-right: 9px !important;
    line-height: 16px;
  }

  .heading-title {
    margin-left: 15px;
    margin-top: 6px;
    margin-right: 5px;
    display: flex;
  }

  &.d-flex {
    display: flex;
  }

  &.px-0 {
    padding-left: 0;
    padding-right: 0;

    h3 {
      font-weight: ${FONT_VIEW_HOME_CHART_BOXES.fontWeight};
      font-size: ${FONT_VIEW_HOME_CHART_BOXES.fontSize};
      padding: 5px 0 0 5px;
      text-align: left;
      color: var(--chakra-colors-tertiary-100);

      @media (max-width: 1024px) {
        font-weight: ${FONT_VIEW_HOME_CHART_BOXES.fontWeightMedia1024};
        font-size: ${FONT_VIEW_HOME_CHART_BOXES.fontSizeMedia1024};
      }
      @media (max-width: 768px) {
        font-weight: ${FONT_VIEW_HOME_CHART_BOXES.fontWeightMedia768};
        font-size: ${FONT_VIEW_HOME_CHART_BOXES.fontSizeMedia768};
      }
    }
  }

  &.pb-0 {
    padding-bottom: 0;
  }

  @media (min-width: 768px) {
    &.two_of_four {
      grid-column: span 2 / span 2;
    }
  }

  h3 {
    font-weight: ${FONT_VIEW_HOME_TITLES.fontWeight};
    font-size: ${FONT_VIEW_HOME_TITLES.fontSize};
    line-height: ${FONT_VIEW_HOME_TITLES.lineHeight};
    margin: 0;
    color: var(--chakra-colors-tertiary-100);
  }
  .box_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    button {
      width: 35px;
      height: 35px;
      border: 0;
      border-radius: 6px;
      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease-in-out;
      background-color: transparent;

      &:hover {
        background-color: var(--chakra-colors-tertiary-40);
      }
    }
  }

  .graph {
    text-align: center;
    img {
      display: block;
      max-width: 100%;
      margin: auto;
    }
  }

  &.featured {
    background: linear-gradient(129.69deg, var(--chakra-colors-tertiary-40) 0%, var(--chakra-colors-tertiary-60) 104.55%);

    padding: 15px;
    text-align: center;

    img {
      display: block;
      margin: auto;
      max-width: 100%;
    }

    p {
      font-size: ${FONT_VIEW_HOME_FEATURED.fontSize};
      strong {
        display: block;
      }
    }
  }
`;

export default StyledBox;
