function bankNameFormat(bank: string) {
  if (!bank) {
    return ' ';
  }
  const spaceCount = bank.split(' ').length - 1;
  if (spaceCount < 1) {
    return bank.slice(0, 2).split('').join().replace(/[,]/g, ' ');
  }
  if (spaceCount > 1) {
    // eliminar palabras de solo 2 caracteres
    const separatedName = bank.split(' ');
    const nameWOArticles = separatedName.filter(word => word.length > 3);
    const bankAvatarName = nameWOArticles.splice(0, 2);
    return bankAvatarName.join().replace(/[,]/g, ' ');
  }
  return bank;
}

export default bankNameFormat;
