import styled from 'styled-components';

const StyledConsumptionPlanBanner = styled.div`
  .consumption-plan-banner {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px 40px 20px 24px;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-radius: 12px;
    border: 1px solid var(--secondary-60, #bec6dc);
    margin-bottom: 16px;

    &__hired-plan {
      display: flex;
      align-items: center;
      min-width: 157px;

      &__icon {
        width: 40px;
        height: 40px;
        margin-right: 16px;
      }
    }

    &__monthly-payment {
      min-width: 100px;
    }

    &__bonus-data {
      min-width: 177px;

      &__upper-data {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        & .chakra-badge {
          margin-right: 8px;
        }
      }
    }

    &__upgrade-limit {
      min-width: 146px;
      // width: 162px;
      text-align: center;
    }

    &__upgrade-plan {
      min-width: 99px;
      &.chakra-button {
        padding: 0px;
      }
    }
  }

  .unmargined-text {
    margin: 0px;
  }
`;

export default StyledConsumptionPlanBanner;
