import { Flex, Text } from '@chakra-ui/react';

import { FONT_API_PORTAL_REGISTER_BANNER } from '../../../designTokens/fonts';
import plainText from '../../../lang/es.json';
import Button from './Button';

interface RegisterBannerProps {
  onRegister: () => void;
}

function RegisterBanner({ onRegister }: RegisterBannerProps) {
  return (
    <div>
      <Flex align='center' justify='space-between'>
        <Text fontSize={FONT_API_PORTAL_REGISTER_BANNER.fontSizeTitle} fontWeight={FONT_API_PORTAL_REGISTER_BANNER.fontWeight} m='0'>
          {plainText.message.registerBannerAPIPortal.title}
        </Text>
        <Button onClick={onRegister} text={plainText.button.signin} />
      </Flex>
      <Text fontSize={FONT_API_PORTAL_REGISTER_BANNER.fontSizeDescription} mb='4' mt='2'>
        {plainText.message.registerBannerAPIPortal.description}
      </Text>
    </div>
  );
}

export default RegisterBanner;
