import { Box, Flex, Link, Text } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

import { FONT_HEADER_SIB } from '../../designTokens/fonts';
import plainText from '../../lang/es.json';

interface HeaderSibProps {
  title: string;
  subtitle: React.ReactNode;
}

function HeaderSib({ title, subtitle }: HeaderSibProps) {
  return (
    <Box>
      <div style={{ padding: '10px' }}>
        <Link as={RouterLink} to='/'>
          {plainText.button.backWithArrow}
        </Link>
      </div>
      <Flex w='full' boxShadow='11px 10px 14px -9px rgb(135 135 135 / 75%)' minH='101px' mt={2}>
        <Box ml={6}>
          <Text textStyle='h1' fontSize={FONT_HEADER_SIB.fontSize} m={0}>
            {title}
          </Text>
          <Text textStyle='h6' color='tertiary.90' mt='10px'>
            {subtitle}
          </Text>
        </Box>
        {/* Se resuelve de momento que se haga en la tabla el renderizado de columnas */}
        {/* <Button leftIcon={<FiSettings />} variant='outline' colorScheme='primary' my='auto' ml='auto' mr='50px'>
          PERSONALIZAR
        </Button> */}
      </Flex>
    </Box>
  );
}

export default HeaderSib;
