// eslint-disable-next-line import/no-unresolved
import { ReuseSibTab } from '@react-ib-mf/style-guide-ui';
import React from 'react';
import { useQuery } from 'react-query';

import { getPasswordNotification } from '../../services/api';
import Alert from '../Alert';

function PasswordNotification() {
  const { data: passwordNotificationData } = useQuery(
    'password-notification',
    () => getPasswordNotification().then(res => res?.data?.data[0]),
    {
      retry: false,
    },
  );

  const redirectChangePassword = () => {
    const changePasswordUrl =
      'menuSelection.do%3FmenuRoot%3Dadmin.datosPersonales%26menuSelection%3Dadmin%7CdatosPersonales%7CchangePassword';
    ReuseSibTab({ urlToOpen: changePasswordUrl });
  };

  if (!passwordNotificationData) {
    return null;
  }

  return (
    <Alert
      customStyles={{ marginRight: '40px', marginBottom: '16px' }}
      variant='warning' // this could be come from the api call
      title={passwordNotificationData.title}
      description={passwordNotificationData.description}
      buttonLabel={passwordNotificationData.linkText}
      OnClick={() => redirectChangePassword()}
    />
  );
}

export default PasswordNotification;
