// eslint-disable-next-line import/no-unresolved
import { PrivateRender } from '@react-ib-mf/style-guide-ui';
import { QueryClient, QueryClientProvider } from 'react-query';

import App from './App';
import { CustomChakraProvider } from './providers/CustomChakraProvider';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      retry: (failureCount, error: any) => {
        return failureCount < 1 && !['401', '403'].includes(error?.response?.status);
      },
      retryDelay: 2500,
    },
  },
});

export default function Root() {
  return (
    <PrivateRender mode={process.env.NODE_ENV}>
      <QueryClientProvider client={queryClient}>
        <CustomChakraProvider>
          <App />
        </CustomChakraProvider>
      </QueryClientProvider>
    </PrivateRender>
  );
}
