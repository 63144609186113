import styled from 'styled-components';

import {
  FONT_MOVEMENTS_CARDS_AMOUNT,
  FONT_MOVEMENTS_CARDS_CURRENCY,
  FONT_MOVEMENTS_CARDS_CURRENCY_TITLE,
  FONT_MOVEMENTS_CARDS_ERROR_BAR,
  FONT_MOVEMENTS_CARDS_TITLE,
} from '../../designTokens/fonts';

const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  color: var(--chakra-colors-secondary-110);

  * {
    -webkit-tap-highlight-color: transparent;
  }

  @media (max-width: 1200px) {
    flex-direction: column;
  }

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-top: 10px;
  }

  canvas {
    margin-right: 27px;

    @media (max-width: 1280px) {
      margin-right: 17px;
    }
  }

  .graph-style {
    height: 22vh;
    width: 31vw;

    @media (max-width: 1900px) {
      width: 30vw;
    }

    @media (max-width: 1040px) {
      width: 28vw;
      height: 20vw;
    }
  }

  .currency-box {
    margin-top: 15px;
  }

  .error-bar {
    display: flex;
    flex-direction: row;
    background-color: var(--chakra-colors-danger-100);
    width: 100%;
    color: white;
    padding-left: 10px;
    align-items: center;
    font-size: ${FONT_MOVEMENTS_CARDS_ERROR_BAR.fontSize};
    height: 27px;
    Button {
      font-weight: ${FONT_MOVEMENTS_CARDS_ERROR_BAR.fontWeight};
      font-size: ${FONT_MOVEMENTS_CARDS_ERROR_BAR.fontSize};
      background: none;
      color: white;
      border: none;
    }
    Button:hover {
      border: none;
    }
    Button::focus,
    .css-taj3dd[data-focus] {
      box-shadow: none;
    }

    @media (max-width: 810px) {
      font-size: ${FONT_MOVEMENTS_CARDS_ERROR_BAR.fontSizeMedia};
    }
  }

  .bottom {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .total-amount {
    display: flex;
    flex-direction: column;
    padding-right: 27px;

    .amount {
      font-weight: ${FONT_MOVEMENTS_CARDS_AMOUNT.fontWeight};
      font-size: ${FONT_MOVEMENTS_CARDS_AMOUNT.fontSize};
      padding-top: 3px;
    }

    .red-text {
      color: red;
    }

    .title {
      font-size: ${FONT_MOVEMENTS_CARDS_TITLE.fontSize};
      padding-top: 18px;
      display: inline-block;
      text-align: start;
      width: 150%;

      @media (max-width: 1040px) {
        font-size: ${FONT_MOVEMENTS_CARDS_TITLE.fontSizeMedia};
      }
    }

    .currency {
      font-size: ${FONT_MOVEMENTS_CARDS_CURRENCY.fontSize};

      @media (max-width: 1040px) {
        font-size: ${FONT_MOVEMENTS_CARDS_CURRENCY.fontSizeMedia1040};
      }

      @media (max-width: 900px) {
        font-size: ${FONT_MOVEMENTS_CARDS_CURRENCY.fontSizeMedia900};
      }
    }

    .currency-title {
      font-size: ${FONT_MOVEMENTS_CARDS_CURRENCY_TITLE.fontSize};
      padding: 88px 0 16px 0;

      @media (max-width: 1040px) {
        font-size: ${FONT_MOVEMENTS_CARDS_CURRENCY_TITLE.fontSizeMedia};
      }
    }
  }
`;

export default StyledBox;
