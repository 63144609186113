import styled from 'styled-components';

import integrationsBanner from '../../../assets/images/integrationsBanner.png';
import { FONT_API_PORTAL_INTEGRATIONS_DESCRIPTION, FONT_API_PORTAL_INTEGRATIONS_TITLE } from '../../../designTokens/fonts';

const StyledIntegrations = styled.div`
  margin-bottom: 20px;
  .banner {
    background: url(${integrationsBanner});
    box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
    color: white;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    border-radius: 12px;
    height: 300px;
  }
  .title {
    font-family: ${FONT_API_PORTAL_INTEGRATIONS_TITLE.fontFamily};
    font-style: ${FONT_API_PORTAL_INTEGRATIONS_TITLE.fontStyle};
    font-weight: ${FONT_API_PORTAL_INTEGRATIONS_TITLE.fontWeight};
    font-size: ${FONT_API_PORTAL_INTEGRATIONS_TITLE.fontSize};
    line-height: ${FONT_API_PORTAL_INTEGRATIONS_TITLE.lineHeight};
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    color: var(--chakra-colors-grey-120);
  }
  .description {
    font-family: ${FONT_API_PORTAL_INTEGRATIONS_DESCRIPTION.fontFamily};
    font-style: ${FONT_API_PORTAL_INTEGRATIONS_DESCRIPTION.fontStyle};
    font-weight: ${FONT_API_PORTAL_INTEGRATIONS_DESCRIPTION.fontWeight};
    font-size: ${FONT_API_PORTAL_INTEGRATIONS_DESCRIPTION.fontSize};
    line-height: ${FONT_API_PORTAL_INTEGRATIONS_DESCRIPTION.lineHeight};
    margin-top: 0px;
    margin-bottom: 10px;
    align-items: center;
    color: var(--chakra-colors-grey-120);
  }
  .instructivo {
    color: var(--chakra-colors-primary-100);
    display: none;
  }
  .containerTitle {
    display: flex;

    flex-wrap: wrap;
    align-content: stretch;
    justify-content: space-between;
    align-items: baseline;
  }
  @media (max-width: 1300px) {
    .banner {
      height: 220px;
    }
  }
  @media (min-width: 1301px) and (max-width: 1600px) {
    .banner {
      height: 245px;
    }
  }
`;
export default StyledIntegrations;
