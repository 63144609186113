const convertFormatNumbers = (num: number): string => num.toString().replace('.', ',');

function splitNumberParts(numStr: string): { integerPart: string; decimalPart: string } {
  const parts = numStr.split(',');
  const integerPart = parts[0];
  const decimalPart = parts[1] ? `,${parts[1]}` : '';
  return { integerPart, decimalPart };
}

function formatIntegerPart(integerPart: string): string {
  const SEGMENT_SIZE = 3;
  const resultArray = [];
  let tempIntegerPart = integerPart;
  while (tempIntegerPart.length > SEGMENT_SIZE) {
    resultArray.unshift(tempIntegerPart.slice(-SEGMENT_SIZE));
    tempIntegerPart = tempIntegerPart.slice(0, -SEGMENT_SIZE);
  }
  resultArray.unshift(tempIntegerPart);
  return resultArray.join('.');
}

function currencyFormatDE(num: number): string {
  const numStr = convertFormatNumbers(num);
  const { integerPart, decimalPart } = splitNumberParts(numStr);
  const formattedIntegerPart = formatIntegerPart(integerPart);
  return `${formattedIntegerPart}${decimalPart}`;
}

export default { currencyFormatDE };
