import { Button, Icon } from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { OpenLink } from '@react-ib-mf/style-guide-ui';
import { useState } from 'react';
import { FiX } from 'react-icons/fi';

import plainText from '../../lang/es.json';
import StyledWelcomeBanner from './StyledWelcomeBanner';

function WelcomeBanner() {
  const [showBanner, setShowBanner] = useState(true);
  const hideBanner = () => setShowBanner(false);
  const WELCOME_BANNER_URL =
    'https://interbanking.com.ar/interlegajo.html?utm_source=SIB&utm_medium=banner&utm_campaign=InterlegajoSIB&utm_id=InterlegajoSIB';

  if (showBanner) {
    return (
      <StyledWelcomeBanner>
        <div className='welcome-banner' data-testid='div-welcomeBanner'>
          <div className='welcome-banner__content'>
            <div className='welcome-banner__content__text'>
              <div className='welcome-banner__content__text__title' data-testid='lbl-welcomeBannerTitle'>
                <div className='welcome-banner__content__text__title__bigger'>{plainText.message.welcomeBanner.titleBigger}</div>
              </div>
              <div className='welcome-banner__content__text__description' data-testid='lbl-welcomeBannerDescription'>
                {plainText.message.welcomeBanner.description}
              </div>
            </div>

            <OpenLink url={WELCOME_BANNER_URL} openMode='extApplication' data-testid='btn-welcomeBannerAction' reuseTab>
              <Button className='welcome-banner__content__enter-button' size='lg' variant='tertiary'>
                {plainText.button.welcomeBanner}
              </Button>
            </OpenLink>
          </div>

          <button className='welcome-banner__exit-button' data-testid='btn-closeWelcomeBanner' onClick={hideBanner}>
            <Icon as={FiX} h={6} w={6} />
          </button>
        </div>
      </StyledWelcomeBanner>
    );
  }
  return null;
}

export default WelcomeBanner;
