import { useDisclosure } from '@chakra-ui/react';
import {
  isForbiddenError,
  pushAnalyticsFromError,
  useErrorMessage,
  useShowRelatedToMyBalance,
  useToastOptions,
  // eslint-disable-next-line import/no-unresolved
} from '@react-ib-mf/style-guide-ui';
import PropTypes from 'prop-types';
import { createContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import shieldIcon from '../../assets/icons/shield.svg';
import usePermissions from '../../hooks/usePermissions';
import { BankBoxesData, Banks, IBankBoxesContext } from '../../Interfaces/IBankBoxes';
import plainText from '../../lang/es.json';
import { getBankBalance, putFavoriteBankBalance } from '../../services/api';
import BankBoxesHeader from '../BankBoxesHeader';
import BankBoxesSkeleton from '../BankBoxesSkeleton';
import ErrorSection from '../ErrorSection';
import PermissionMessageIcon from '../PermissionMessageIcon';
import BankBoxes from './BankBoxes';
import { StyledBankBoxesContainer, StyledBankBoxesErrorSection } from './styled';

export const BankBoxesContext = createContext<IBankBoxesContext | null>(null);

BankBoxesContainer.propTypes = {
  userBankBalancesPermission: PropTypes.bool,
};

function BankBoxesContainer(props) {
  const { userBankBalancesPermission } = props;
  const { permissionMessageTitle } = plainText.message.chartCardConsolidateBalanceError;
  const { permissionMessageSubTitle } = plainText.message.chartCardConsolidateBalanceError;

  const onError = (error: unknown) => {
    const message = bankBalanceDataDisplayed ? plainText.toast.error.bankBoxesUpdate : plainText.toast.error.bankBoxes;

    pushAnalyticsFromError(error);

    if (isForbiddenError(error)) {
      setHideBankBoxes(true);
    } else {
      showErrorMessage({ title: message, error });
    }
  };

  const onSuccess = newBankBalanceData => {
    if (userBankBalancesPermission) {
      setHideBankBoxes(newBankBalanceData?.data?.bancos.length === 0);
      // Check if the banks have the completed data
      const hasAllBanksCompleteOnSuccess = validateAllBanksHaveCompletedData(newBankBalanceData?.data?.bancos);

      // Update info when the new data is correct
      if (newBankBalanceData) {
        setBankBalanceDataDisplayed(newBankBalanceData);
        setHasUploadedCorrectData(true);
      }

      // Show success toast when it's a refetching and all new data is completed
      if (hasUploadedCorrectData && hasAllBanksCompleteOnSuccess) {
        setToastOptions({
          description: plainText.toast.success.bankBoxesUpdate,
          status: 'success',
          autoClose: true,
        });
      }

      // Show error toast if the new data is incomplete
      // if (!hasAllBanksCompleteOnSuccess) {
      //   showErrorMessage({ title: plainText.toast.error.bankBoxesUpdate });
      // }
    }
  };

  const checkLogicalValue = property => {
    if (!property || property === '') return false;
    return true;
  };

  const validateAllBanksHaveCompletedData = bankData => {
    return !bankData?.some(bank => !checkLogicalValue(bank.lastUpdateBalance));
  };

  const {
    status: bankBoxesStatus,
    refetch,
    isRefetching,
  } = useQuery(
    'saldos-disponibles',
    async () => {
      if (userBankBalancesPermission) {
        const res = await getBankBalance();
        return res?.data;
      }
      return {
        data: [],
        error: false,
        refetchOnMount: true,
      };
    },
    {
      retry: 2,
      onSuccess,
      onError,
    },
  );

  const { showErrorMessage } = useErrorMessage();
  const { hasConsolidatedBalancesPermission } = usePermissions();
  const [, setToastOptions] = useToastOptions();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isBalanceVisible, setIsBalanceVisible] = useState(true);
  const [, setShowRelatedToMyBalance] = useShowRelatedToMyBalance();
  const [bankBalanceDataDisplayed, setBankBalanceDataDisplayed] = useState<BankBoxesData>();
  const [hasUploadedCorrectData, setHasUploadedCorrectData] = useState(false);
  const cardProviderValue = useMemo(
    () => ({ bankBoxes: bankBalanceDataDisplayed, setBankBoxes: setBankBalanceDataDisplayed, isSaldoVisible: isBalanceVisible }),
    [isBalanceVisible, setBankBalanceDataDisplayed, bankBalanceDataDisplayed],
  );
  const [hideBankBoxes, setHideBankBoxes] = useState<boolean>(false);
  // Hide the whole component if the api call is success but with no info
  const MAX_BANKBOXES_TO_ABLE_EDIT_BALANCE = 4;
  const isLoading = bankBoxesStatus === 'loading' || bankBoxesStatus === 'idle' || isRefetching;

  const allowEdit =
    !isLoading && bankBalanceDataDisplayed?.data?.bancos?.length >= MAX_BANKBOXES_TO_ABLE_EDIT_BALANCE && Boolean(bankBalanceDataDisplayed);
  const allowRefresh =
    !isLoading && bankBalanceDataDisplayed?.data?.bancos?.some((bank: Banks) => bank?.isOnline) && Boolean(bankBalanceDataDisplayed);
  const allowHideAndShowBalance = !isLoading && Boolean(bankBalanceDataDisplayed);

  const renderBankBoxes = () => {
    if (isLoading) {
      return <BankBoxesSkeleton bankBoxesStatus='loading' />;
    }

    if (!hasConsolidatedBalancesPermission) {
      return null;
    }

    if (bankBalanceDataDisplayed) {
      return (
        <BankBoxes
          bankBalance={bankBalanceDataDisplayed}
          putFavoriteBankBalance={putFavoriteBankBalance}
          isOpen={isOpen}
          onClose={onClose}
          bankBoxesStatus={bankBoxesStatus}
        />
      );
    }

    return (
      <StyledBankBoxesErrorSection>
        <ErrorSection
          showErrorPicture={false}
          titleText={plainText.message.bankBoxesErrorSection.title}
          descriptionText={plainText.message.bankBoxesErrorSection.description}
          buttonText={plainText.button.retry}
          callbackAction={refetch}
        />
      </StyledBankBoxesErrorSection>
    );
  };
  useEffect(() => {
    // Check reasons to show the wizard step: Bankboxes not hidden and there is data displayed
    const showWizardStep = !hideBankBoxes && Boolean(bankBalanceDataDisplayed);
    setShowRelatedToMyBalance(showWizardStep);
  }, [bankBalanceDataDisplayed, hideBankBoxes, setShowRelatedToMyBalance]);

  if (hideBankBoxes) return null;

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!userBankBalancesPermission ? (
        <PermissionMessageIcon icon={shieldIcon} title={permissionMessageTitle} message={permissionMessageSubTitle} />
      ) : (
        <StyledBankBoxesContainer className='card_boxes'>
          <BankBoxesHeader
            isBalanceVisible={isBalanceVisible}
            setIsBalanceVisible={setIsBalanceVisible}
            allowEdit={allowEdit}
            allowRefresh={allowRefresh}
            allowHideAndShowBalance={allowHideAndShowBalance}
            isLoading={isLoading}
            onOpen={onOpen}
            refresh={refetch}
          />
          <BankBoxesContext.Provider value={cardProviderValue}>
            <div id='bank-boxes-container'>{renderBankBoxes()}</div>
          </BankBoxesContext.Provider>
        </StyledBankBoxesContainer>
      )}
    </>
  );
}

export default BankBoxesContainer;
