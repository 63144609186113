import arrowUpIB from '../assets/images/arrowUp.png';
import paperIB from '../assets/images/paper.png';

const AFIP_IMAGES = [paperIB, arrowUpIB];

const generateImageArray = (numElements, images: Array<string>) =>
  Array.from({ length: numElements }, (_, index) => images[index % images.length]);

export default {
  // You can add another property and make a new array to customize other parts that require images.
  afip: (numElements: number) => generateImageArray(numElements, AFIP_IMAGES),
};
