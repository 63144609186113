// eslint-disable-next-line import/no-unresolved
import { pushAnalyticsEvent, useErrorMessage } from '@react-ib-mf/style-guide-ui';
import { LoadingScreenComponent as LoadingScreen } from '@wow/loading-screen';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { StyledThirdLevel } from '../components/ThirdLevelMenu/styled';
import ThirdLevelMenu from '../components/ThirdLevelMenu/ThirdLevelMenu';
import ThirdLevelMenuError from '../components/ThirdLevelMenu/ThirdLevelMenuError';
import { IThirdLevelMenu } from '../Interfaces/IThirdLevelMenu';
import plainText from '../lang/es.json';
import { getThirdLevelMenu } from '../services/api';

function ThirdLevelMenuView() {
  const { menuId: idThirdLevel, menuName: nameSecondLevel } = useParams();
  const pushAnalyticsThirdLevelOk = breadcrumb => {
    pushAnalyticsEvent({
      event: 'track_content_group',
      content_group: breadcrumb,
    });
  };

  const decodedIdThirdLevel = decodeURIComponent(idThirdLevel);
  const decodedNameSecondLevel = decodeURIComponent(nameSecondLevel);

  const [dataMenu, setDataMenu] = useState<IThirdLevelMenu>();
  const [renderState, setRenderState] = useState('isFetching');

  const { showErrorMessage } = useErrorMessage();
  // API call to get new JSON
  const {
    error: errorThirdLevelMenu,
    data: dataThirdLevelMenu,
    isError,
    isSuccess,
    isFetching,
    refetch,
  } = useQuery('third level menu', () => getThirdLevelMenu(decodedIdThirdLevel).then(res => res));

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decodedIdThirdLevel]);

  // Get the data info
  useEffect(() => {
    if (dataThirdLevelMenu !== undefined && dataThirdLevelMenu?.data !== undefined) {
      setDataMenu(dataThirdLevelMenu.data);
    }
  }, [dataThirdLevelMenu, isFetching, decodedIdThirdLevel]);

  // Change the API call status
  useEffect(() => {
    if (isFetching) {
      setRenderState('isFetching');
    } else if (isError) {
      setRenderState('isError');

      showErrorMessage({ title: plainText.toast.error.thirdLevelMenuView, error: errorThirdLevelMenu });
    } else if (isSuccess) {
      setRenderState('isSuccess');
      pushAnalyticsThirdLevelOk(`Inicio, ${decodedNameSecondLevel}, ${dataThirdLevelMenu?.data?.data.titulo}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching, isSuccess, isError, errorThirdLevelMenu]);

  function renderCurrentState() {
    switch (renderState) {
      case 'isFetching': {
        const loadingScreenData = {
          title: plainText.loadingScreen.thirdLevelMenuView.title,
          description: plainText.loadingScreen.thirdLevelMenuView.description,
        };

        return <LoadingScreen title={loadingScreenData.title} description={loadingScreenData.description} />;
      }
      case 'isSuccess': {
        dataMenu.data.nameSecondLevel = decodedNameSecondLevel;

        return (
          <div className='third-level-menu__is-success'>
            <ThirdLevelMenu data={dataMenu?.data} />
          </div>
        );
      }
      case 'isError': {
        return <ThirdLevelMenuError refetch={refetch} menuId={decodedIdThirdLevel} />;
      }
      default: {
        return null;
      }
    }
  }

  return <StyledThirdLevel className='third-level-menu-container'>{renderCurrentState()}</StyledThirdLevel>;
}

export default ThirdLevelMenuView;
