import { Box } from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { HeaderBase, useAuthorizer } from '@react-ib-mf/header-menu-ui';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import plainText from '../../../lang/es.json';
import Integrations from '../integrations/index';
import BenefitList from './BenefitList';
import LoginBanner from './LoginBanner';
import RegisterBanner from './RegisterBanner';
import Sandbox from './Sandbox';
import SolutionsSection from './SolutionsSection';

function HomeAPIPortal() {
  const navigate = useNavigate();

  const [{ validateSession }] = useAuthorizer();
  useEffect(() => {
    validateSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Invocada cuando se hace click en registrarse
  const goToRegister = () => {
    navigate('registro');
  };

  return (
    <Box>
      <HeaderBase
        breadcrumb={[
          { id: '0', name: plainText.breadcrumb.homeAPIPortal.itemName1, linkTo: '/' },
          { id: '1', name: plainText.breadcrumb.homeAPIPortal.itemName2 },
          { id: '2', name: plainText.breadcrumb.homeAPIPortal.itemName3 },
        ]}
      />
      <RegisterBanner onRegister={goToRegister} />
      <LoginBanner onLogin={goToRegister} />
      <BenefitList />
      <SolutionsSection />
      <Sandbox onCreateAccount={goToRegister} />
      <Integrations />
    </Box>
  );
}

export default HomeAPIPortal;
