/* eslint-disable import/no-unresolved */
import { getToken } from '@react-ib-mf/style-guide-ui';
import axios from 'axios';

import { mockConsolidadSaldo } from '../../test/__mocks__/ConsolidateBalance/mock';
import { ConsolidadoSaldo } from '../Interfaces/IConsolidateBalance';

axios.defaults.baseURL = process.env.API_BASE_URL;
axios.defaults.headers.common.Authorization = `Bearer ${getToken()}`;
if (process.env.API_USER_KEY) {
  axios.defaults.headers.common.user_key = process.env.API_USER_KEY;
}

const getConsolidadoSaldos = () =>
  axios.get('home/saldos/consolidado').then(res => {
    return res.data.data;
  });

const getConsolidadoSaldosMock = () => {
  return new Promise<ConsolidadoSaldo[]>(resolve => {
    setTimeout(() => {
      return resolve(mockConsolidadSaldo);
    }, 3000);
  });
};

export { getConsolidadoSaldos, getConsolidadoSaldosMock };
