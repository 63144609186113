import { Flex } from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { useErrorMessage } from '@react-ib-mf/style-guide-ui';
import { PaginationState } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import useSelectCheckbox from '../../hooks/useSelectCheckbox';
import { IMovementsDayInput, IMovementsDayTable } from '../../Interfaces/IMovementsDay';
import { getAccounts, postMovementsDay } from '../../services/api';
import TablaMovimientos from '../ConsolidadoMovimientosTabla/TablaMovimientos';
import CustomSelectCheckbox from '../CustomSelectCheckbox/CustomSelectCheckbox';

const tableInitialState = {
  movimientosCuentas: [],
  page: {
    cantidadElementosXPagina: 0,
    paginaActual: 0,
    totalElementos: 0,
    totalPaginas: 0,
  },
};

function ContentMovimientosDia() {
  const { moneda } = useParams();
  const { showErrorMessage } = useErrorMessage();
  const [dataTable, setDataTable] = useState<IMovementsDayTable>(tableInitialState);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 15,
  });
  const [input, setInput] = useState<IMovementsDayInput>({
    cuentas: [],
    page: 0,
    size: 15,
    sortProperty: 'nombreBanco',
    sortDirection: 'asc',
  });

  const queryClient = useQueryClient();

  const {
    data: accountsFilters,
    isError: accountsFiltersError,
    isFetching: accountsFiltersFetching,
    error: errorFetchingAccountsFilters,
  } = useQuery(['AccountsFilter'], () => getAccounts('IyRMovimientos', 'MOVIMIENTOS_DIA', moneda).then(res => res.data.data));

  const {
    data: accountsTable,
    isError: accountsTableError,
    isFetching: accountsTableFetching,
    error: errorFetchingAccountsTable,
  } = useQuery(['AccountsTable'], async () => {
    if (input.cuentas.length > 0) {
      const res = await postMovementsDay(input);

      return res.data.data;
    }
    return [];
  });

  const { dataOptions, isDisabledFilters, selectedOptions, onClearOptions, onSelectOption, setAccounts } = useSelectCheckbox();

  const onApplyFilters = () => {
    if (pageIndex > 0) {
      setPagination({ pageIndex: 0, pageSize });
    } else {
      const selectedAccounts = selectedOptions.map(account => account.value);
      const newInput = {
        ...input,
        cuentas: selectedAccounts,
      };
      setInput(newInput);
    }
  };

  useEffect(() => {
    if (errorFetchingAccountsFilters || errorFetchingAccountsTable) {
      showErrorMessage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorFetchingAccountsFilters, errorFetchingAccountsTable]);

  useEffect(() => {
    if (selectedOptions.length === 0 && pageIndex > 0) setPagination({ pageIndex: 0, pageSize });
    if (selectedOptions.length === 0 && input?.cuentas.length !== dataOptions.length && pageIndex === 0) {
      const newInput = {
        ...input,
        cuentas: accountsFilters,
      };
      setInput(newInput);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);

  // Maneja la paginacion de la tabla
  useEffect(() => {
    const selectedAccounts = selectedOptions.map(account => account.value);
    if (selectedAccounts.length > 0) {
      const newInput = {
        ...input,
        page: pageIndex,
        cuentas: selectedAccounts,
      };
      setInput(newInput);
    } else {
      const newInput = {
        ...input,
        page: pageIndex,
        cuentas: accountsFilters,
      };
      setInput(newInput);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex]);

  useEffect(() => {
    if (accountsFiltersError) {
      setAccounts([]);
    }

    if (accountsTableError) {
      setDataTable(tableInitialState);
    }
  }, [accountsFiltersError, setAccounts, accountsTableError]);

  useEffect(() => {
    if (!accountsFiltersFetching && accountsFilters !== undefined) {
      setAccounts(accountsFilters);
    }
    if (!accountsTableFetching && accountsTable !== undefined) {
      setDataTable(accountsTable);
    }
  }, [accountsFilters, accountsFiltersFetching, setAccounts, accountsTable, accountsTableFetching, dataTable]);

  useEffect(() => {
    const prevData: IMovementsDayTable = queryClient.getQueryData(['AccountsTable']);
    if (dataOptions.length > 0 && selectedOptions.length === 0 && !prevData?.movimientosCuentas) {
      const newInput = {
        ...input,
        cuentas: accountsFilters,
      };
      setInput(newInput);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataOptions, selectedOptions]);

  useEffect(() => {
    if (input?.cuentas !== undefined) {
      queryClient.fetchQuery(['AccountsTable']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input]);

  useEffect(() => {
    const prevData = queryClient.getQueryData(['AccountsFilter']);
    if (prevData !== undefined) {
      queryClient.resetQueries({ queryKey: 'AccountsFilter', exact: true });
    }
  }, [queryClient]);

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  return (
    <>
      <Flex w='full' mt={10} flexDirection='row'>
        <CustomSelectCheckbox
          listOptions={dataOptions}
          onSelectOption={onSelectOption}
          selectedOptions={selectedOptions}
          onClearOptions={onClearOptions}
          isDisabledFilters={isDisabledFilters}
          onApplyFilters={onApplyFilters}
          isLoadingData={accountsTableFetching}
        />
      </Flex>
      <TablaMovimientos data={dataTable} pagination={pagination} setPagination={setPagination} />
    </>
  );
}

export default ContentMovimientosDia;
