import styled from 'styled-components';

import BannerBackground from '../../assets/images/welcome-banner.png';
import {
  FONT_WELCOME_BANNER_DESCRIPTION,
  FONT_WELCOME_BANNER_TITLE_BIGGER,
  FONT_WELCOME_BANNER_TITLE_LOWER,
} from '../../designTokens/fonts';

const StyledWelcomeBanner = styled.div`
  .welcome-banner {
    background-image: url(${BannerBackground});
    background-position-x: 66%;
    background-position-y: center;
    height: 96px;
    box-shadow:
      0px 4px 8px 3px rgba(0, 0, 0, 0.15),
      0px 1px 3px rgba(0, 0, 0, 0.3);
    border-radius: 12px;
    margin-right: 40px;
    margin-bottom: 25px;
    background-repeat: no-repeat;
    display: flex;
    position: relative;

    &__content {
      padding: 0px 48px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      &__text {
        display: flex;
        flex-flow: column;

        &__title {
          display: flex;
          align-items: baseline;
          color: var(--chakra-colors-grey-120);
          margin-bottom: 8px;
          width: 315px;
          justify-content: space-between;

          &__bigger {
            font-family: ${FONT_WELCOME_BANNER_TITLE_BIGGER.fontFamily};
            font-style: ${FONT_WELCOME_BANNER_TITLE_BIGGER.fontStyle};
            font-weight: ${FONT_WELCOME_BANNER_TITLE_BIGGER.fontWeight};
            font-size: ${FONT_WELCOME_BANNER_TITLE_BIGGER.fontSize};
            line-height: ${FONT_WELCOME_BANNER_TITLE_BIGGER.lineHeight};
          }

          &__lower {
            font-family: ${FONT_WELCOME_BANNER_TITLE_LOWER.fontFamily};
            font-style: ${FONT_WELCOME_BANNER_TITLE_LOWER.fontStyle};
            font-weight: ${FONT_WELCOME_BANNER_TITLE_LOWER.fontWeight};
            font-size: ${FONT_WELCOME_BANNER_TITLE_LOWER.fontSize};
          }
        }

        &__description {
          font-family: ${FONT_WELCOME_BANNER_DESCRIPTION.fontFamily};
          font-style: ${FONT_WELCOME_BANNER_DESCRIPTION.fontStyle};
          font-weight: ${FONT_WELCOME_BANNER_DESCRIPTION.fontWeight};
          font-size: ${FONT_WELCOME_BANNER_DESCRIPTION.fontSize};
          line-height: ${FONT_WELCOME_BANNER_DESCRIPTION.lineHeight};
          letter-spacing: 0.4px;
          color: var(--chakra-colors-grey-120);
          width: 370px;
        }
      }

      &__enter-button {
        height: 40px;
        width: 163px;
        color: white;
      }
    }

    &__exit-button {
      background-color: transparent;
      border: 0;
      border-radius: 6px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      position: absolute;
      right: 0;
      top: 0;
      padding: 0px;
      width: 30px;
      height: 30px;
      margin: 12px 12px 0px 0px;
      color: var(--chakra-colors-tertiary-100);

      &:hover {
        background: var(--chakra-colors-grey-40);
      }
    }
  }
`;

export default StyledWelcomeBanner;
