import { Image, Skeleton } from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { isForbiddenError, pushAnalyticsEvent, pushAnalyticsFromError, useErrorMessage } from '@react-ib-mf/style-guide-ui';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import rightArrow from '../../assets/images/right-arrow.svg';
import plainText from '../../lang/es.json';
import { getDayMovements } from '../../services/api';
import StyledBox from '../../views/StyledBox';
import ChartCardError from '../ChartCardError/ChartCardError';
import PermissionMessage from '../PermissionMessage';
import { IMovements } from './IMovementsCard';
import MovementsCard from './MovementsCard';

MovementsCardsView.propTypes = {
  userMovementsPermission: PropTypes.bool,
};

function MovementsCardsView(props) {
  const {
    data: dataMovements,
    error,
    isLoading,
  }: IMovements = useQuery(
    'movimientos',
    async () => {
      if (userMovementsPermission) {
        const res = await getDayMovements();
        return { data: res.data.data };
      }
      return {
        data: [],
        error: false,
        isLoading: false,
      };
    },
    { retry: 2, onError: pushAnalyticsFromError },
  );

  const currencyValues = [
    { key: plainText.label.currencyKeyPesos, text: `${plainText.label.pesos} ${plainText.label.currencyKeyARS}`, disabled: true },
    { key: plainText.label.currencyKeyUSD, text: `${plainText.label.dollars} (${plainText.label.currencyKeyUSD})`, disabled: true },
  ];

  const { userMovementsPermission } = props;
  const [selectedCurrency, setSelectedCurrency] = useState('$');
  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [total, setTotal] = useState<number>();
  const page2 = `/movimientos-dia/${selectedCurrency}`;
  const [currencies] = useState(currencyValues);
  const [defaultCurrency, setDefaultCurrency] = useState(currencyValues[0].key);
  const { showErrorMessage } = useErrorMessage();

  const style = getComputedStyle(document.body);

  const labels = [plainText.label.debits, plainText.label.credits];
  const { permissionMessage } = plainText.message.chartCardConsolidateBalanceError;

  const [chartData, setChartData] = useState({
    labels,
    showTooltips: true,
    datasets: [
      {
        label: plainText.label.balance,
        data: [],
        fill: true,
        backgroundColor: [style?.getPropertyValue('--chakra-colors-tertiary-40'), style?.getPropertyValue('--chakra-colors-tertiary-60')],
        borderColor: style?.getPropertyValue('--chakra-colors-tertiary-40'),
      },
    ],
  });

  useEffect(() => {
    if (dataMovements !== undefined && dataMovements.data?.length > 0) {
      const changeData = chartData;
      const index = 0;
      changeData.datasets[0].data = [dataMovements.data[index].credito, dataMovements.data[index].debito];
      setTotal(dataMovements.data[index].total);
      setChartData(changeData);
    }
  }, [chartData, dataMovements]);

  useEffect(() => {
    if (error) {
      const errorDescription = error?.response?.data?.exception || error?.message;
      setErrorText(errorDescription);

      if (!isForbiddenError(error)) showErrorMessage({ error });
      setShowError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const changeDataset = (selection: string) => {
    setSelectedCurrency(selection);
    const changeData = chartData;
    const index = selection === '$' ? 0 : 1;
    changeData.datasets[0].data = [dataMovements.data[index].credito, dataMovements.data[index].debito];
    setTotal(dataMovements.data[index].total);
    setChartData(changeData);
  };

  useEffect(() => {
    if (dataMovements && dataMovements.data) {
      dataMovements.data.forEach(element => {
        if (element.moneda === '$') {
          currencies[0].disabled = false;
        }
        if (element.moneda === 'USD') {
          currencies[1].disabled = false;
        }
      });

      const activeCurrencies = currencies.filter(c => !c.disabled);
      if (activeCurrencies && activeCurrencies.length > 0) {
        setDefaultCurrency(activeCurrencies[0].key);
      }

      if (dataMovements.data.length === 0 && userMovementsPermission) {
        setErrorText('noMovementsAccount');
        setShowError(true);
      }
    }
  }, [dataMovements, currencies, userMovementsPermission]);

  if (isForbiddenError(error)) {
    return null;
  }

  const pushAnalyticsMovimientos = () => {
    pushAnalyticsEvent({
      event: 'select_content',
      content_group: 'Home',
      content_type: 'Tarjetas personalizadas',
      item_id: 'Card - Consolidado movimientos',
    });
  };

  return (
    <StyledBox className='two_of_four px-0 pb-0 chart_box'>
      <Skeleton isLoaded={!isLoading} startColor='grey.50' endColor='grey.30' height='100%'>
        <div className='box_head heading-title'>
          <div className='header-title'>
            <h3 className='card-title'>{plainText.message.movementCardTitle}</h3>
            <Link className='link' to={page2}>
              <Image onClick={pushAnalyticsMovimientos} src={rightArrow} />
            </Link>
          </div>
        </div>
        <div className='box_content'>
          {userMovementsPermission && !showError && (
            <MovementsCard
              data={chartData}
              onChange={changeDataset}
              total={total}
              currencies={currencies}
              defaultCurrency={defaultCurrency}
            />
          )}
          {showError && <ChartCardError info={errorText} />}
        </div>
        {!userMovementsPermission && <PermissionMessage message={permissionMessage} />}
      </Skeleton>
    </StyledBox>
  );
}

export default MovementsCardsView;
