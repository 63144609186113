import { Text } from '@chakra-ui/react';
import React from 'react';

import StyledPermissionMessage from './styled';

interface PermissionMessageProps {
  message: string;
}

export default function PermissionMessage({ message }: PermissionMessageProps) {
  return (
    <StyledPermissionMessage>
      <Text textStyle='titleMdBold'>{message}</Text>
    </StyledPermissionMessage>
  );
}
