import { Badge, Icon } from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { OpenLink, pushAnalyticsEvent } from '@react-ib-mf/style-guide-ui';
import { CardBody } from '@wow/card';
import { FiChevronRight } from 'react-icons/fi';

import { IThirdLevelMenuItem } from '../../Interfaces/IThirdLevelMenu';
import plainText from '../../lang/es.json';
import { CardHover, StyledThirdLevelCard, StyledThirdLevelCardWrapper } from './styled';

function ThirdLevelCard({
  titulo: cardTitle,
  url: cardURL,
  openMode: cardOpenMode,
  breadcrumb,
  newOption,
  icono,
  descripcion,
}: IThirdLevelMenuItem) {
  const pushAnalyticsThirdLevel = () => {
    pushAnalyticsEvent({
      event: 'select_content',
      content_group: breadcrumb,
      content_type: 'Menu sub nivel',
      item_id: cardTitle,
    });
  };

  const pushAnalyticsThirdLevelError = () => {
    pushAnalyticsEvent({
      event: 'impression_modal',
      content_group: breadcrumb,
      modal_name: 'Error de Configuración - Menú lateral - Sub nivel',
    });
  };

  const getCardContent = () => (
    <StyledThirdLevelCard>
      <CardHover>
        <CardBody>
          <div className='third-level-card'>
            <div className='third-level-card__content'>
              {icono && (
                <div className='third-level-card__content__png'>
                  <figure className='third-level-card__content__png'>
                    <img src={icono} alt=' ' width='44' height='44' />
                  </figure>
                </div>
              )}

              <div className='third-level-card__content__text'>
                <div className='third-level-card__content__title'> {cardTitle} </div>
                {descripcion && <p className='third-level-card__content__description'>{descripcion}</p>}
              </div>
              {newOption && (
                <Badge variant='brand-dark' className='third-level-card__content__new-option'>
                  {plainText.badge.new}
                </Badge>
              )}
              <div>
                <Icon as={FiChevronRight} h={6} w={6} />
              </div>
            </div>
          </div>
        </CardBody>
      </CardHover>
    </StyledThirdLevelCard>
  );

  const getCardBody = () => (
    <OpenLink url={cardURL} openMode={cardOpenMode} reuseTab onError={pushAnalyticsThirdLevelError}>
      {getCardContent()}
    </OpenLink>
  );

  // Conditional rendering
  if (!cardTitle?.length) {
    return null;
  }

  return <StyledThirdLevelCardWrapper onClick={pushAnalyticsThirdLevel}>{getCardBody()}</StyledThirdLevelCardWrapper>;
}

export default ThirdLevelCard;
