import { Avatar, Skeleton, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FiHelpCircle } from 'react-icons/fi';

import cardTransferAmountIcon from '../../assets/icons/exchange.svg';
import cardDownloadNumberIcon from '../../assets/icons/set-as-resume.svg';
import cardTransferNumberIcon from '../../assets/icons/sorting-arrows-horizontal.svg';
import { ConsumptionCardsDataProps, IConsumptionCardsData } from '../../Interfaces/IConsumption';
import plainText from '../../lang/es.json';
import { formatNumberDecimals } from '../../utils/numbers';
import { containValueInJSON, groupBy } from '../../utils/objectUtils';
import ActionIcon from '../BankBoxesHeader/ActionIcon';
import ErrorSection from '../ErrorSection';
import StyledAmount from '../StyledAmount';
import StyledConsumptionCards from './styled';

function ConsumptionCards({
  consumptionCardsDataResponse,
  consumptionCardsStatus,
  consumptionCardsRefetch,
  consumptionCardsIsFetching,
  showContentCards,
  setShowContentCards,
}: ConsumptionCardsDataProps) {
  const defaultValue = {
    download: {
      quantity: '',
      quantityLimit: '',
    },
    transfer: {
      amountPesos: '',
      limitPesos: '',
      quantity: '',
      quantityLimit: '',
    },
  };

  const UNLIMITED_STRING = 'Ilimitado';

  const [consumptionCardsData, setConsumptionCardsData] = useState<IConsumptionCardsData>(defaultValue);

  // Build the info to create the cards
  const consumptionCardsInfo = [
    {
      id: 'cardTransferAmount',
      icon: cardTransferAmountIcon,
      title: plainText.consumption.cardTransferAmount.title,
      tooltipText: plainText.consumption.cardTransferAmount.tooltipText,
      content: [
        {
          id: 'cardTransferAmountContentPesos',
          title: plainText.consumption.cardTransferAmount.contentTitlePeso,
          currentValueTitle: plainText.consumption.cardTransferAmount.currentValueTitle,
          limitValueTitle: plainText.consumption.cardTransferAmount.limitValueTitle,
          currentValue: consumptionCardsData?.transfer?.amountPesos,
          limitValue: consumptionCardsData?.transfer?.limitPesos,
          denom: '$',
          decimals: 2,
        },
        {
          id: 'cardTransferAmountContentDollars',
          title: plainText.consumption.cardTransferAmount.contentTitleDollar,
          currentValueTitle: plainText.consumption.cardTransferAmount.currentValueTitle,
          limitValueTitle: plainText.consumption.cardTransferAmount.limitValueTitle,
          currentValue: consumptionCardsData?.transfer?.amountDollars,
          limitValue: consumptionCardsData?.transfer?.limitDollars,
          denom: 'u$s',
          decimals: 2,
        },
      ],
    },
    {
      id: 'cardTransferNumber',
      icon: cardTransferNumberIcon,
      title: plainText.consumption.cardTransferNumber.title,
      tooltipText: plainText.consumption.cardTransferNumber.tooltipText,
      content: [
        {
          id: 'cardTransferNumberContent',
          title: plainText.consumption.cardTransferNumber.contentTitle,
          currentValueTitle: plainText.consumption.cardTransferNumber.currentValueTitle,
          limitValueTitle: plainText.consumption.cardTransferNumber.limitValueTitle,
          currentValue: consumptionCardsData?.transfer?.quantity,
          limitValue: consumptionCardsData?.transfer?.quantityLimit,
          decimals: 0,
        },
      ],
    },
    {
      id: 'cardDownloadNumber',
      icon: cardDownloadNumberIcon,
      title: plainText.consumption.cardDownloadNumber.title,
      tooltipText: plainText.consumption.cardDownloadNumber.tooltipText,
      content: [
        {
          id: 'cardDownloadNumberContent',
          title: plainText.consumption.cardDownloadNumber.contentTitle,
          currentValueTitle: plainText.consumption.cardDownloadNumber.currentValueTitle,
          limitValueTitle: plainText.consumption.cardDownloadNumber.limitValueTitle,
          currentValue: consumptionCardsData?.download?.quantity,
          limitValue: consumptionCardsData?.download?.quantityLimit,
          decimals: 0,
        },
      ],
    },
  ];

  let isDataComplete = false;
  const [showCardContentTitles, setShowCardContentTitles] = useState(false);

  useEffect(() => {
    if (consumptionCardsDataResponse) {
      if (containValueInJSON(consumptionCardsDataResponse, ['-', '', ' ', null, undefined])) {
        isDataComplete = false;
      } else {
        isDataComplete = true;

        // Get transfer amounts for USD and $
        const consumptionCardsDataTransferAmounts = groupBy(consumptionCardsDataResponse?.transfer?.amounts, ({ currency }) => currency);
        setShowCardContentTitles(
          !!consumptionCardsDataTransferAmounts.USD?.[0].quantity && !!consumptionCardsDataTransferAmounts.USD?.[0].limit,
        );

        // Update consumption data after service response successfully
        setConsumptionCardsData({
          download: {
            quantity: consumptionCardsDataResponse?.download?.quantity,
            quantityLimit: consumptionCardsDataResponse?.download?.quantityLimit,
          },
          transfer: {
            amountPesos: consumptionCardsDataTransferAmounts.$?.[0].quantity,
            limitPesos: consumptionCardsDataTransferAmounts.$?.[0].limit,
            amountDollars: consumptionCardsDataTransferAmounts.USD?.[0].quantity,
            limitDollars: consumptionCardsDataTransferAmounts.USD?.[0].limit,
            quantity: consumptionCardsDataResponse?.transfer?.quantity,
            quantityLimit: consumptionCardsDataResponse?.transfer?.quantityLimit,
          },
        });
      }
    }
  }, [consumptionCardsDataResponse]);

  useEffect(() => {
    if (consumptionCardsStatus === 'error') {
      setShowContentCards(false);
    } else {
      setShowContentCards(true);
    }
    // We decided not add the useState setter in the array then I left this comment.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consumptionCardsIsFetching, isDataComplete, consumptionCardsStatus]);

  useEffect(() => {
    // Add style to body element in order to avoid horizontal scroll bar
    const lastBodyOverflowX = document.body.style.overflowX;
    document.body.style.overflowX = 'hidden';

    return () => {
      // Removing style from body element when the component unmounts
      document.body.style.overflowX = lastBodyOverflowX || null;
    };
  }, []);

  if (showContentCards) {
    return (
      <StyledConsumptionCards>
        <div className='consumption-card-container'>
          {consumptionCardsInfo.map(consumptionCardInfo => (
            <Skeleton key={consumptionCardInfo.id} isLoaded={consumptionCardsStatus === 'success'} startColor='grey.50' endColor='grey.30'>
              <div className='consumption-card-container__card'>
                <div className='consumption-card-container__card__title'>
                  <Avatar
                    src={consumptionCardInfo.icon}
                    className='consumption-card-container__card__icon'
                    data-testid='avatar-consumptionCard'
                  />

                  <Text
                    className='consumption-card-container__card__title__text'
                    textStyle='titleMdBold'
                    data-testid='txt-consumptionCardTitle'
                  >
                    {consumptionCardInfo.title}
                  </Text>

                  <ActionIcon
                    customClassName='consumption-card-container__card__title__tooltip'
                    data-testid='icon-consumptionCard'
                    icon={FiHelpCircle}
                    tooltip={consumptionCardInfo.tooltipText}
                    tooltipPlacement='top'
                  />
                </div>

                {consumptionCardInfo.content.map(cardContent =>
                  cardContent.currentValue !== undefined && cardContent.limitValue !== undefined ? (
                    <div className='consumption-card-container__card__content' key={cardContent.id}>
                      {showCardContentTitles && (
                        <Text
                          textStyle='titleSmBold'
                          data-testid='txt-consumptionCardContentTitle'
                          className='consumption-card-container__card__content__text__title'
                        >
                          {cardContent.title}
                        </Text>
                      )}

                      <div className='consumption-card-container__card__content__text'>
                        <Text textStyle='bodyLg' data-testid='txt-consumptionCardCurrentValueTitle'>
                          {cardContent.currentValueTitle}
                        </Text>

                        <StyledAmount
                          number={formatNumberDecimals({ numberToFormat: cardContent.currentValue, decimalsPlaces: cardContent.decimals })}
                          symbol={cardContent.currentValue === '' || cardContent.currentValue === UNLIMITED_STRING ? '' : cardContent.denom}
                          customDataTestId='txt-consumptionCardCurrentValue'
                          wholeNumberAdditionalStyle={{ fontWeight: 700 }}
                        />
                      </div>

                      <div className='consumption-card-container__card__content__text'>
                        <Text textStyle='bodyMd' data-testid='txt-consumptionCardLimitValueTitle'>
                          {cardContent.limitValueTitle}
                        </Text>

                        <StyledAmount
                          number={formatNumberDecimals({ numberToFormat: cardContent.limitValue, decimalsPlaces: cardContent.decimals })}
                          symbol={cardContent.limitValue === '' || cardContent.limitValue === UNLIMITED_STRING ? '' : cardContent.denom}
                          customDataTestId='txt-consumptionCardLimitValue'
                          wholeNumberTextStyle='labelXl'
                        />
                      </div>
                    </div>
                  ) : null,
                )}
              </div>
            </Skeleton>
          ))}
        </div>
      </StyledConsumptionCards>
    );
  }

  return (
    <ErrorSection
      errorPictureDataTestId=''
      titleText={plainText.consumption.errorPage.title}
      titleDataTestId=''
      descriptionText={plainText.consumption.errorPage.description}
      descriptionDataTestId=''
      buttonText={plainText.consumption.errorPage.cardsButtonText}
      buttonDataTestId=''
      callbackAction={consumptionCardsRefetch}
    />
  );
}

export default ConsumptionCards;
