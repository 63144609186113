/* eslint-disable import/no-unresolved */
import { Card } from '@wow/card';
import { themeChakra } from '@wow/themes';
import styled from 'styled-components';

import {
  FONT_THIRD_LEVEL_MENU_ACTION,
  FONT_THIRD_LEVEL_MENU_CARD_DESCRIPTION,
  FONT_THIRD_LEVEL_MENU_CARD_TITLE,
  FONT_THIRD_LEVEL_MENU_ERROR_DESCRIPTION,
  FONT_THIRD_LEVEL_MENU_ERROR_TITLE,
} from '../../designTokens/fonts';

const {
  colors: { brandGray },
} = themeChakra;

const StyledThirdLevel = styled.div`
  .third-level-menu {
    margin-right: 24px;

    &__is-error {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;

      &__picture {
        margin-top: 60px;
        margin-bottom: 19px;
      }

      &__title {
        font-family: ${FONT_THIRD_LEVEL_MENU_ERROR_TITLE.fontFamily};
        font-style: ${FONT_THIRD_LEVEL_MENU_ERROR_TITLE.fontStyle};
        font-weight: ${FONT_THIRD_LEVEL_MENU_ERROR_TITLE.fontWeight};
        font-size: ${FONT_THIRD_LEVEL_MENU_ERROR_TITLE.fontSize};
        line-height: ${FONT_THIRD_LEVEL_MENU_ERROR_TITLE.lineHeight};
        color: var(--chakra-colors-grey-120);
        width: 422px;
        margin-bottom: 16px;
      }

      &__description {
        font-family: ${FONT_THIRD_LEVEL_MENU_ERROR_DESCRIPTION.fontFamily};
        font-style: ${FONT_THIRD_LEVEL_MENU_ERROR_DESCRIPTION.fontStyle};
        font-weight: ${FONT_THIRD_LEVEL_MENU_ERROR_DESCRIPTION.fontWeight};
        font-size: ${FONT_THIRD_LEVEL_MENU_ERROR_DESCRIPTION.fontSize};
        line-height: ${FONT_THIRD_LEVEL_MENU_ERROR_DESCRIPTION.lineHeight};
        text-align: center;
        color: var(--chakra-colors-grey-120);
        margin-bottom: 40px;
      }

      &__action {
        width: 121px;
        height: 40px;
        background: white;
        color: var(--chakra-colors-primary-100);
        border: 1px solid var(--chakra-colors-primary-100);
        border-radius: 8px;
        font-family: ${FONT_THIRD_LEVEL_MENU_ACTION.fontFamily};
        font-style: ${FONT_THIRD_LEVEL_MENU_ACTION.fontStyle};
        font-weight: ${FONT_THIRD_LEVEL_MENU_ACTION.fontWeight};
        font-size: ${FONT_THIRD_LEVEL_MENU_ACTION.fontSize};
        line-height: ${FONT_THIRD_LEVEL_MENU_ACTION.lineHeight};
        letter-spacing: ${FONT_THIRD_LEVEL_MENU_ACTION.letterSpacing};
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          border: solid 1px var(--chakra-colors-primary-110);
          color: var(--chakra-colors-primary-110);
          cursor: pointer;
        }
      }
    }

    &__header {
      margin-bottom: 9px;
      position: sticky;
      top: 98px;
      background: white;
      width: 100%;
      padding-bottom: 15px;
    }

    &__cards-container {
      margin-bottom: 68px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 34px;
    }
  }
`;

const StyledThirdLevelCard = styled.div`
  .third-level-card {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: '1 0 0 ';
    padding: 10px;

    &__content {
      width: 95%;
      display: flex;
      align-items: center;

      &__icon {
        color: var(--chakra-colors-tertiary-100);
      }

      &__png {
        display: flex;
        justify-content: center;
        padding: 5px 0;
        margin: 0;
      }
      &__figure {
        display: flex;
        align-items: center;
      }
      &__text {
        flex-grow: 1;
        margin-left: 20px;
      }
      &__title {
        padding-top: 12px;
        font-family: ${FONT_THIRD_LEVEL_MENU_CARD_TITLE.fontFamily};
        font-style: ${FONT_THIRD_LEVEL_MENU_CARD_TITLE.fontStyle};
        font-weight: ${FONT_THIRD_LEVEL_MENU_CARD_TITLE.fontWeight};
        font-size: ${FONT_THIRD_LEVEL_MENU_CARD_TITLE.fontSize};
        line-height: ${FONT_THIRD_LEVEL_MENU_CARD_TITLE.lineHeight};
        color: var(--chakra-colors-grey-120);
      }

      &__description {
        font-family: ${FONT_THIRD_LEVEL_MENU_CARD_DESCRIPTION.fontFamily};
        font-style: ${FONT_THIRD_LEVEL_MENU_CARD_DESCRIPTION.fontStyle};
        font-weight: ${FONT_THIRD_LEVEL_MENU_CARD_DESCRIPTION.fontWeight};
        font-size: ${FONT_THIRD_LEVEL_MENU_CARD_DESCRIPTION.fontSize};
        line-height: ${FONT_THIRD_LEVEL_MENU_CARD_DESCRIPTION.lineHeight};
        letter-spacing: ${FONT_THIRD_LEVEL_MENU_CARD_DESCRIPTION.letterSpacing};
        color: var(--chakra-colors-grey-120);
        margin: 0;
        padding-top: 8px;
      }

      &__new-option {
        margin-left: 8px;
        height: 20px;
      }
    }

    &__button {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--chakra-colors-primary-100);
      transition: all 0.3s ease-in-out;
    }
  }
`;

export const CardHover = styled(Card)`
  &:hover {
    background-color: ${brandGray};
    transition: all ease-in-out 100ms;
  }
`;

const StyledThirdLevelCardWrapper = styled.div`
  .third-level-card-wrapper {
    text-decoration: none;
  }
`;

export { StyledThirdLevel, StyledThirdLevelCard, StyledThirdLevelCardWrapper };
