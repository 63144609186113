// eslint-disable-next-line import/no-unresolved
import { WithPermissions } from '@react-ib-mf/style-guide-ui';
import { ErrorComponent } from '@wow/error';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import HomeApiPortal from './components/apiPortal/home';
import RegisterSuccess from './components/apiPortal/registerSuccess/index';
import { FONT_APP } from './designTokens/fonts';
import message from './lang/es.json';
import Dashboard from './layouts/dashboard';
import { permissionsApiPortal } from './utils/constants';
import ConsolidadoSaldos from './views/consolidadoSaldos';
import Consumption from './views/consumption';
import EmbeddedView from './views/EmbeddedView';
import FrequentQuestion from './views/frequentQuestions';
import Home from './views/home';
import MovimientosDia from './views/movimientosDia';
import SigninAPIPortal from './views/signinAPIPortal';
import ThirdLevelMenuView from './views/thirdLevelMenuView';

function App() {
  const errorPageTitleStyle = { textShadow: '0px 0px 0px black', letterSpacing: FONT_APP.letterSpacing };

  return (
    <Router>
      <Routes>
        <Route path='/' element={<Dashboard />}>
          <Route path='/' element={<Home />} />
          <Route path='/consumo' element={<Consumption />} />
          <Route path='/preguntas-frecuentes' element={<FrequentQuestion />} />
          <Route path='/consolidado-saldos/:moneda' element={<ConsolidadoSaldos />} />
          <Route path='/movimientos-dia/:moneda' element={<MovimientosDia />} />
          <Route
            path='/api-portal'
            element={
              <WithPermissions permission={permissionsApiPortal} showErrorPage titleStyle={errorPageTitleStyle}>
                <HomeApiPortal />
              </WithPermissions>
            }
          />
          <Route
            path='/api-portal/registro'
            element={
              <WithPermissions permission={permissionsApiPortal} showErrorPage titleStyle={errorPageTitleStyle}>
                <SigninAPIPortal />
              </WithPermissions>
            }
          />
          <Route
            path='/api-portal/registro/exitoso'
            element={
              <WithPermissions permission={permissionsApiPortal} showErrorPage titleStyle={errorPageTitleStyle}>
                <RegisterSuccess />
              </WithPermissions>
            }
          />

          <Route path='/menu/:menuName/:menuId' element={<ThirdLevelMenuView />} />
          <Route path='/open/*' element={<EmbeddedView />} />
          <Route path='*' element={<ErrorComponent bodyText={message.consumption.errorPage.body} redirectTo='/' isRouter />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
