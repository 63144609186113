import { Box } from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { useErrorMessage } from '@react-ib-mf/style-guide-ui';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import ConsolidadoDeSaldos from '../components/ConsolidadoSaldos';
import HeaderSib from '../components/HeaderSib/HeaderSib';
import plainText from '../lang/es.json';
import { getConsolidadoSaldos } from '../services/api';
import { formatNumberDecimals } from '../utils/numbers';

interface IConsolidado {
  moneda?: string;
  saldoContable?: number;
  saldoInicioDia?: number;
  saldoOperativoActual?: number;
  proyeccion24Hs?: number;
  proyeccion48Hs?: number;
}

function ConsolidadoSaldos() {
  const { showErrorMessage } = useErrorMessage();
  const { moneda } = useParams();
  const { data: dataConsolidados, error } = useQuery(['consolidadoSaldos'], getConsolidadoSaldos);
  const [dataTotal, setDataTotal] = useState<IConsolidado>({
    moneda: '',
    saldoContable: 0,
    saldoInicioDia: 0,
    saldoOperativoActual: 0,
    proyeccion24Hs: 0,
    proyeccion48Hs: 0,
  });

  useEffect(() => {
    if (dataConsolidados) {
      const infoSaldos = dataConsolidados?.data.find(item => item.moneda === moneda);
      setDataTotal({ ...infoSaldos });
    }
  }, [dataConsolidados, moneda]);

  useEffect(() => {
    if (error) {
      showErrorMessage({ error });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <Box w='full' h='full'>
      <HeaderSib
        title={plainText.message.consolidadoSaldosTitle}
        subtitle={
          <span>
            {plainText.label.actualOperating}
            <span style={{ color: dataTotal?.saldoOperativoActual < 0 ? 'red' : 'inherit' }}>{`${
              dataTotal?.moneda || ''
            } ${formatNumberDecimals({ numberToFormat: dataTotal?.saldoOperativoActual, defaultValue: '0,00' })}`}</span>
          </span>
        }
      />
      <ConsolidadoDeSaldos />
    </Box>
  );
}

export default ConsolidadoSaldos;
