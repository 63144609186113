import { Badge, Flex, Icon, Link, Stack, Text } from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { OpenLink, OpenMode, useErrorMessage } from '@react-ib-mf/style-guide-ui';
import { PaginationState } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { FiChevronRight } from 'react-icons/fi';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { FONT_CONSOLIDADO_SALDOS } from '../../designTokens/fonts';
import usePermissions from '../../hooks/usePermissions';
import useSelectCheckbox from '../../hooks/useSelectCheckbox';
import { IConsolidadoSaldosInput, IConsolidatedBalanceTable } from '../../Interfaces/IConsSaldos';
import plainText from '../../lang/es.json';
import { getAccounts, postConsolidatedBalanceTableData } from '../../services/api';
import { getBancosSinActualizar } from '../../services/apiConsolidadoSaldos';
import TablaConsolidado from '../ConsolidadoSaldosTabla/TablaConsolidado';
import ShowBanksNotUpdated from '../ConsolidateBalance/ShowBanksNotUpdated';
import CustomSelectCheckbox from '../CustomSelectCheckbox/CustomSelectCheckbox';

const tableInitialState = {
  consolidadoSaldos: [],
  page: {
    cantidadElementosXPagina: 0,
    paginaActual: 0,
    totalElementos: 0,
    totalPaginas: 0,
  },
};
function ConsolidadoDeSaldos() {
  const { moneda } = useParams();
  const [dataTable, setDataTable] = useState<IConsolidatedBalanceTable>(tableInitialState);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 15,
  });
  const [showModal, setShowModal] = useState(false);

  const [input, setInput] = useState<IConsolidadoSaldosInput>({
    cuentas: [],
    page: 0,
    size: 15,
    sortProperty: 'saldoContable',
    sortDirection: 'asc',
  });

  const { hasUnreportedInterfacesPermission } = usePermissions();

  const queryClient = useQueryClient();

  const {
    data: accountsFilters,
    isError: accountsFiltersError,
    isFetching: accountsFiltersFetching,
  } = useQuery(['AccountsFilter'], () => getAccounts('IyRConsolidadoSaldos', 'CONSOLIDADO_SALDOS', moneda).then(res => res.data.data));

  const {
    data: accountsTable,
    isError: accountsTableError,
    isFetching: accountsTableFetching,
  } = useQuery(['AccountsTable'], async () => {
    if (input.cuentas.length > 0) {
      const res = await postConsolidatedBalanceTableData(input);

      return res.data.data;
    }
    return [];
  });

  const { dataOptions, isDisabledFilters, selectedOptions, onClearOptions, onSelectOption, setAccounts } = useSelectCheckbox();

  const onApplyFilters = () => {
    if (pageIndex > 0) {
      setPagination({ pageIndex: 0, pageSize });
    } else {
      const selectedAccounts = selectedOptions.map(account => account.value);
      const newInput = {
        ...input,
        cuentas: selectedAccounts,
      };
      setInput(newInput);
    }
  };
  const modalBancosSinActualizar = () => {
    setShowModal(true);
  };

  const { data: banks, status: bankStatus } = useQuery(['BancosSinActualizar'], () =>
    hasUnreportedInterfacesPermission ? getBancosSinActualizar('CONSOLIDADO_SALDOS', moneda).then(res => res.data.data) : [],
  );

  useEffect(() => {
    if (selectedOptions.length === 0 && pageIndex > 0) setPagination({ pageIndex: 0, pageSize });
    if (selectedOptions.length === 0 && input.cuentas.length !== dataOptions.length && pageIndex === 0) {
      const newInput = {
        ...input,
        cuentas: accountsFilters,
      };
      setInput(newInput);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);

  // Maneja la paginacion de la tabla
  useEffect(() => {
    const selectedAccounts = selectedOptions.map(account => account.value);
    if (selectedAccounts.length > 0) {
      const newInput = {
        ...input,
        page: pageIndex,
        cuentas: selectedAccounts,
      };
      setInput(newInput);
    } else {
      const newInput = {
        ...input,
        page: pageIndex,
        cuentas: accountsFilters,
      };
      setInput(newInput);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex]);

  useEffect(() => {
    if (accountsFiltersError) {
      setAccounts([]);
    }
    if (accountsTableError) {
      setDataTable(tableInitialState);
    }
  }, [accountsFiltersError, setAccounts, accountsTableError]);

  const { showErrorMessage } = useErrorMessage();
  useEffect(() => {
    if (accountsFiltersError || accountsTableError) {
      showErrorMessage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountsFiltersError, accountsTableError]);

  useEffect(() => {
    if (!accountsFiltersFetching && accountsFilters !== undefined) {
      setAccounts(accountsFilters);
    }
    if (!accountsTableFetching && accountsTable !== undefined) {
      setDataTable(accountsTable);
    }
  }, [accountsFilters, accountsFiltersFetching, setAccounts, accountsTable, accountsTableFetching, dataTable]);

  useEffect(() => {
    const prevData: IConsolidatedBalanceTable = queryClient.getQueryData(['AccountsTable']);
    if (dataOptions.length > 0 && selectedOptions.length === 0 && !prevData?.consolidadoSaldos) {
      const newInput = {
        ...input,
        cuentas: accountsFilters,
      };
      setInput(newInput);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataOptions, selectedOptions]);

  useEffect(() => {
    if (input?.cuentas !== undefined) {
      queryClient.fetchQuery(['AccountsTable']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input]);

  useEffect(() => {
    const prevData = queryClient.getQueryData(['AccountsFilter']);
    if (prevData !== undefined) {
      queryClient.resetQueries({ queryKey: 'AccountsFilter', exact: true });
    }
  }, [queryClient]);

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  return (
    <>
      <Flex w='full' mt={10} flexDirection='row'>
        <CustomSelectCheckbox
          listOptions={dataOptions}
          onSelectOption={onSelectOption}
          selectedOptions={selectedOptions}
          onClearOptions={onClearOptions}
          isDisabledFilters={isDisabledFilters}
          onApplyFilters={onApplyFilters}
          isLoadingData={accountsTableFetching}
        />
        <Stack ml='auto' mr={6}>
          <Text textStyle='h3' fontSize={FONT_CONSOLIDADO_SALDOS.fontSize} m={0}>
            {plainText.message.consolidadoSaldosText}
          </Text>
          {bankStatus === 'success' ? (
            <Badge as={Link} p={1} _hover={{ cursor: 'pointer', textDecoration: 'none' }} onClick={modalBancosSinActualizar}>
              {plainText.badge.consolidadoSaldosBanksToUpdate}
            </Badge>
          ) : null}

          <OpenLink url={OpenMode({ module: 'confeccionarTransferencia' })} openMode='urlComplete' reuseTab>
            <Badge
              style={{
                display: 'inline-flex',
                height: '24px',
                alignItems: 'center',
              }}
            >
              {plainText.badge.consolidadoSaldosMakeTransfers} <Icon as={FiChevronRight} />
            </Badge>
          </OpenLink>
        </Stack>
      </Flex>
      <ShowBanksNotUpdated showModal={showModal} callBack={() => setShowModal(false)} banks={banks} />
      <TablaConsolidado datosTabla={dataTable} pagination={pagination} setPagination={setPagination} />
    </>
  );
}

export default ConsolidadoDeSaldos;
