// eslint-disable-next-line import/no-unresolved
import { pushAnalyticsEvent } from '@react-ib-mf/style-guide-ui';

import ThirdLevelMenuErrorPicture from '../../assets/images/third-level-menu-error-picture.png';
import { IThirdLevelMenuError } from '../../Interfaces/IThirdLevelMenu';
import plainText from '../../lang/es.json';

const pushAnalyticsErrorThirdLevel = menuId => {
  pushAnalyticsEvent({
    event: 'impression_modal',
    modal_name: 'Error de Servicio - Menú lateral - Sub nivel',
    content_group: menuId,
  });
};

function ThirdLevelMenuError({ refetch, menuId }: IThirdLevelMenuError) {
  const thirdLevelMenuData = {
    picture: ThirdLevelMenuErrorPicture,
    title: plainText.message.thirdLevelMenuError.title,
    description: plainText.message.thirdLevelMenuError.description,
    content_group: menuId,
  };

  const pushAnalyticsReintentar = () => {
    pushAnalyticsEvent({
      event: 'click_modal',
      modal_name: 'Error de Servicio - Menú lateral - Sub nivel',
      item_id: 'CTA Reintentar',
      content_group: menuId,
    });
    refetch();
  };

  return (
    <div className='third-level-menu__is-error'>
      <img
        className='third-level-menu__is-error__picture'
        alt='Third level menu error'
        src={thirdLevelMenuData.picture}
        onLoad={() => pushAnalyticsErrorThirdLevel(menuId)}
      />

      <div className='third-level-menu__is-error__title'>{thirdLevelMenuData.title}</div>

      <div className='third-level-menu__is-error__description'>{thirdLevelMenuData.description}</div>

      <div
        className='third-level-menu__is-error__action'
        onClick={() => pushAnalyticsReintentar()}
        aria-hidden='true'
        data-testid='reset-button'
      >
        {plainText.button.retry}
      </div>
    </div>
  );
}

export default ThirdLevelMenuError;
