import styled from 'styled-components';

const StyledPostOnboardingSection = styled.div`
  margin: 0px 40px 62px 0px;

  .post-onboarding-section {
    &__title {
      margin-bottom: 20px;
    }

    &__tablist {
      margin-bottom: 10px;

      &__tab {
        flex: 1;
      }
    }

    &__tab-panel {
      border-radius: 12px;
      border: 1px solid var(--chakra-colors-secondary-60);
      background: white;
      padding: 20px 12px;
      display: flex;
      justify-content: space-between;

      &__text {
        margin-left: 12px;

        &__title {
          margin: 0px;
        }
        &__description {
          margin: 0px;
        }
      }

      &__action {
        display: flex;
        align-items: center;
      }
    }
  }

  .last-tab {
    margin: 0px;
  }
`;

export default StyledPostOnboardingSection;
