// eslint-disable-next-line import/no-unresolved
import { useScrollTo } from '@react-ib-mf/style-guide-ui';
import { Outlet, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import CriticalError from '../components/CriticalError';

function Dashboard() {
  const { pathname } = useLocation();
  useScrollTo({ top: 0, left: 0 }, [pathname]);

  return (
    <DashboardPanel>
      <CriticalError />
      <Outlet />
    </DashboardPanel>
  );
}

export default Dashboard;

const DashboardPanel = styled.div`
  padding-top: 24px;
  padding-left: 28px;
`;
