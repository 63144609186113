import { Button } from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { useWindowDimensions } from '@react-ib-mf/style-guide-ui';
import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import styled from 'styled-components';

import {
  FONT_API_PORTAL_SOLUTION_SECTION_DESCRIPTION,
  FONT_API_PORTAL_SOLUTION_SECTION_LINK,
  FONT_API_PORTAL_SOLUTION_SECTION_TITLE,
} from '../../../designTokens/fonts';
import plainText from '../../../lang/es.json';
import SolutionSectionCards from './SolutionSectionCards';

function SolutionsSection() {
  // Define data to build cards
  const informativeCardsInfo = [
    {
      order: 1,
      subtitle: plainText.solutionsSectionCards.cardBalance.subtitle,
      title: plainText.solutionsSectionCards.cardBalance.title,
      description: plainText.solutionsSectionCards.cardBalance.description,
      version: plainText.solutionsSectionCards.cardBalance.version,
    },
    {
      order: 2,
      subtitle: plainText.solutionsSectionCards.cardMovements.subtitle,
      title: plainText.solutionsSectionCards.cardMovements.title,
      description: plainText.solutionsSectionCards.cardMovements.description,
      version: plainText.solutionsSectionCards.cardMovements.version,
    },
    {
      order: 3,
      subtitle: plainText.solutionsSectionCards.cardTransfer.subtitle,
      title: plainText.solutionsSectionCards.cardTransfer.title,
      description: plainText.solutionsSectionCards.cardTransfer.description,
      version: plainText.solutionsSectionCards.cardTransfer.version,
    },
    {
      order: 4,
      subtitle: plainText.solutionsSectionCards.cardExtract.subtitle,
      title: plainText.solutionsSectionCards.cardExtract.title,
      description: plainText.solutionsSectionCards.cardExtract.description,
      version: plainText.solutionsSectionCards.cardExtract.version,
    },
    {
      order: 5,
      subtitle: plainText.solutionsSectionCards.cardAccount.subtitle,
      title: plainText.solutionsSectionCards.cardAccount.title,
      description: plainText.solutionsSectionCards.cardAccount.description,
      version: plainText.solutionsSectionCards.cardAccount.version,
    },
  ];

  const maxCardNum = 5;
  const informativeCards = informativeCardsInfo.map((cardInfo, cardIndex) => {
    return <SolutionSectionCards key={cardInfo.order} cardInfo={cardInfo} cardIndex={cardIndex} maxCardNum={maxCardNum} />;
  });

  // Cards created in row (used only if the carousel is not required)
  let cardsContainer = <div className='solution-section__cards-container__row'>{informativeCards}</div>;

  const { width } = useWindowDimensions();
  const itemWidth = 294; // px
  const unusableScreenSection = 185; // px sidebar space
  const usableScreenSection = width - unusableScreenSection;
  const itemsPerGroup = Math.floor(usableScreenSection / itemWidth); // (carouselWidth) / itemWidth
  const showCarousel = itemsPerGroup < informativeCardsInfo.length;

  // Build carousel only if it's required
  if (showCarousel) {
    const carouselItems = [];
    const numItemsGroup = Math.ceil(informativeCards.length / itemsPerGroup);
    let itemsCounter = 0;

    for (let i = 0; i < numItemsGroup; i += 1) {
      const carouselItemsAux = [];
      for (let j = 0; j < itemsPerGroup; j += 1) {
        if (itemsCounter < informativeCards.length) {
          carouselItemsAux.push(informativeCards[itemsCounter]);
          itemsCounter += 1;
        } else {
          break;
        }
      }
      carouselItems.push(
        <div className='carousel-bank-boxes' key={i}>
          {carouselItemsAux}
        </div>,
      );
    }

    const renderCarouselPrevButton = ({ isDisabled }) => {
      if (!isDisabled) {
        return <Button className='alice-carousel__control-btn' leftIcon={<FiChevronLeft />} />;
      }
      return null;
    };

    const renderCarouselNextButton = ({ isDisabled }) => {
      if (!isDisabled) {
        return <Button className='alice-carousel__control-btn' leftIcon={<FiChevronRight />} />;
      }
      return null;
    };

    const renderCarouselDotsItem = ({ isActive }) => {
      return <StyledCarouselDot className={isActive ? 'active-dot' : 'inactive-dot'} />;
    };

    cardsContainer = (
      <AliceCarousel
        items={carouselItems}
        autoWidth
        renderDotsItem={renderCarouselDotsItem}
        renderPrevButton={renderCarouselPrevButton}
        renderNextButton={renderCarouselNextButton}
      />
    );
  }

  return (
    <StyledSolutionSection>
      <div className='solution-section'>
        <div className='solution-section__header'>
          <div className='solution-section__header__info'>
            <div className='solution-section__header__info__title'>{plainText.message.solutionsSectionAPIPortal.title}</div>
            <div className='solution-section__header__info__description'>{plainText.message.solutionsSectionAPIPortal.description}</div>
          </div>
          {/* <div className='solution-section__header__link'>Ver más</div> */}
        </div>

        <div className='solution-section__cards-container'>{cardsContainer}</div>
      </div>
    </StyledSolutionSection>
  );
}

export default SolutionsSection;

const StyledSolutionSection = styled.div`
  .solution-section {
    margin-bottom: 20px;

    &__header {
      margin-top: 50px;
      margin-bottom: 28px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__info {
        &__title {
          font-family: ${FONT_API_PORTAL_SOLUTION_SECTION_TITLE.fontFamily};
          font-style: ${FONT_API_PORTAL_SOLUTION_SECTION_TITLE.fontStyle};
          font-weight: ${FONT_API_PORTAL_SOLUTION_SECTION_TITLE.fontWeight};
          font-size: ${FONT_API_PORTAL_SOLUTION_SECTION_TITLE.fontSize};
          line-height: ${FONT_API_PORTAL_SOLUTION_SECTION_TITLE.lineHeight};
          margin-bottom: 10px;
        }

        &__description {
          font-family: ${FONT_API_PORTAL_SOLUTION_SECTION_DESCRIPTION.fontFamily};
          font-style: ${FONT_API_PORTAL_SOLUTION_SECTION_DESCRIPTION.fontStyle};
          font-weight: ${FONT_API_PORTAL_SOLUTION_SECTION_DESCRIPTION.fontWeight};
          font-size: ${FONT_API_PORTAL_SOLUTION_SECTION_DESCRIPTION.fontSize};
          line-height: ${FONT_API_PORTAL_SOLUTION_SECTION_DESCRIPTION.lineHeight};
        }
      }

      &__link {
        font-family: ${FONT_API_PORTAL_SOLUTION_SECTION_LINK.fontFamily};
        font-style: ${FONT_API_PORTAL_SOLUTION_SECTION_LINK.fontStyle};
        font-weight: ${FONT_API_PORTAL_SOLUTION_SECTION_LINK.fontWeight};
        font-size: ${FONT_API_PORTAL_SOLUTION_SECTION_LINK.fontSize};
        line-height: ${FONT_API_PORTAL_SOLUTION_SECTION_LINK.lineHeight};
        letter-spacing: ${FONT_API_PORTAL_SOLUTION_SECTION_LINK.letterSpacing};
        text-decoration-line: underline;
        color: var(--chakra-colors-primary-100);
        padding-right: 16px;

        &:hover {
          cursor: pointer;
        }
      }
    }

    &__cards-container {
      &__row {
        display: flex;
        align-items: center;
      }
    }
  }

  .alice-carousel {
    &__stage-item {
      width: auto !important;
    }

    &__wrapper {
      height: 360px;
    }

    &__stage {
      height: 360px;
    }

    &__dots {
      margin: 0px;
      margin-top: 18px;
    }

    &__control-btn {
      height: 40px;
      width: 40px;
      min-width: 40px;
      cursor: pointer;
      color: var(--chakra-colors-grey-40);
      background: var(--chakra-colors-primary-100);
      border-radius: 8px;
      transition: all 0.3s ease-in-out;
      display: flex;
      justify-content: center;
      align-items: center;

      & .chakra-button__icon {
        margin: 0px;
        & svg {
          height: 26px;
          width: 26px;
        }
      }

      &:hover {
        background-color: var(--chakra-colors-primary-110);
      }
    }

    &__prev-btn {
      padding: 0px;
      width: 0px;
      position: absolute;
      top: 160px;
      left: -20px;
    }

    &__next-btn {
      padding: 0px;
      width: 0px;
      position: absolute;
      top: 160px;
      right: 24px; //20px;
    }
  }

  .carousel-bank-boxes {
    display: flex;
    justify-content: start;

    & .account-box {
      margin-right: 24px;
    }
  }
`;

const StyledCarouselDot = styled.div`
  margin: 0 5px;

  &.active-dot {
    background: var(--chakra-colors-primary-100);
    border-radius: 99px;
    height: 10px;
    width: 25px;
  }

  &.inactive-dot {
    background: var(--chakra-colors-grey-60);
    border-radius: 50%;
    height: 10px;
    width: 10px;
  }
`;
