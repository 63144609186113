import styled from 'styled-components';

import { FONT_BANK_BOXES_HEADER_BTN, FONT_BANK_BOXES_HEADER_TEXT } from '../../designTokens/fonts';

const StyledBankBoxesHeader = styled.div`
  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    button {
      font-family: ${FONT_BANK_BOXES_HEADER_BTN.fontFamily};
      font-style: ${FONT_BANK_BOXES_HEADER_BTN.fontStyle};
      font-weight: ${FONT_BANK_BOXES_HEADER_BTN.fontWeight};
      font-size: ${FONT_BANK_BOXES_HEADER_BTN.fontSize};
      line-height: ${FONT_BANK_BOXES_HEADER_BTN.lineHeight};
      color: var(--chakra-colors-primary-100);
      display: flex;
      align-items: center;
      background-color: transparent;
      border: 0;
      gap: 10px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      border-radius: 8px;
      i {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        img {
          width: 100%;
          height: 100%;
        }
      }

      &:disabled {
        color: var(--chakra-colors-primary-70);
        cursor: default;

        &:hover {
          background-color: transparent;
        }
      }
    }

    &__my-balance {
      display: flex;
      align-items: center;

      &__text {
        font-family: ${FONT_BANK_BOXES_HEADER_TEXT.fontFamily};
        font-style: ${FONT_BANK_BOXES_HEADER_TEXT.fontStyle};
        font-weight: ${FONT_BANK_BOXES_HEADER_TEXT.fontWeight};
        font-size: ${FONT_BANK_BOXES_HEADER_TEXT.fontSize};
        line-height: ${FONT_BANK_BOXES_HEADER_TEXT.lineHeight};
      }

      &__icon {
        padding: 0px;
        margin: 1px 0px 0px 0px;
      }

      &__button {
        width: 44px;
        display: flex;
        justify-content: center;
        margin-top: 1px;
      }
    }

    &__order-cards {
      display: flex;
      justify-content: end;
      & button {
        padding: 8px 12px 8px 12px;
      }
    }
  }
`;

export default StyledBankBoxesHeader;
