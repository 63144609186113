/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Modal, ModalContent, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import { useEffect } from 'react';

import plainText from '../../lang/es.json';
import { StyledModalBody, StyledModalFooter, StyledModalHeader } from './styled';

function ShowBanksNotUpdated({ showModal, callBack, banks }: { showModal: boolean; callBack: () => void; banks: any[] }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  useEffect(() => {
    if (showModal) {
      onOpen();
    }
  }, [onOpen, showModal]);

  const closeModal = () => {
    callBack();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent>
        <StyledModalHeader>
          <h1>{plainText.message.consolidateBalanceShowBanksNotUpdate.title}</h1>
        </StyledModalHeader>
        <StyledModalBody>
          {banks && banks.length > 0 ? (
            <p>
              {plainText.message.consolidateBalanceShowBanksNotUpdate.banksNotUpdate}
              {` ${banks.map(banco => banco.bcoNomcor).join(', ')}`}
            </p>
          ) : (
            <p>{plainText.message.consolidateBalanceShowBanksNotUpdate.allBanksUpdated}</p>
          )}
        </StyledModalBody>

        <StyledModalFooter>
          <Button
            data-testid='back-button'
            _focus={{ boxShadow: 'none' }}
            className='back-btn'
            variant='tertiary-outline'
            onClick={closeModal}
          >
            {plainText.button.backUppercase}
          </Button>
        </StyledModalFooter>
      </ModalContent>
    </Modal>
  );
}
export default ShowBanksNotUpdated;
