export default function useOidcStorage() {
  function getOidcStoraged() {
    const getOidcStorage =
      sessionStorage.getItem(`oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`) || '';
    return getOidcStorage ? JSON.parse(getOidcStorage) : undefined;
  }

  const oidc = getOidcStoraged();
  return {
    oidc,
  };
}
