import { Box, Text } from '@chakra-ui/react';
import styled from 'styled-components';

import BannerBackground from '../../../assets/images/register-banner-background.png';
import { FONT_API_PORTAL_LOGIN_BANNER } from '../../../designTokens/fonts';
import plainText from '../../../lang/es.json';
import Button from './Button';

interface LoginBannerProps {
  onLogin: () => void;
}

function LoginBanner({ onLogin }: LoginBannerProps) {
  return (
    <Background>
      <Box px='8' py='6'>
        <Text fontSize={FONT_API_PORTAL_LOGIN_BANNER.fontSizeTitle} fontWeight={FONT_API_PORTAL_LOGIN_BANNER.fontWeight} m='0'>
          {plainText.message.loginBannerAPIPortal.title}
        </Text>
        <Text
          fontSize={FONT_API_PORTAL_LOGIN_BANNER.fontSizeDescription}
          fontWeight={FONT_API_PORTAL_LOGIN_BANNER.fontWeight}
          letterSpacing={FONT_API_PORTAL_LOGIN_BANNER.letterSpacing}
          maxW='25%'
          m='0'
          pb='6'
        >
          {plainText.message.loginBannerAPIPortal.description}
        </Text>
        <Button onClick={onLogin} text={plainText.button.access} />
      </Box>
    </Background>
  );
}

const Background = styled.div`
  background: linear-gradient(90deg, rgba(0, 46, 104, 1) 25%, rgba(0, 212, 255, 0) 75%), url(${BannerBackground});
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  color: white;
  background-position: 28% 72%;
  background-size: cover;
  width: 100%;
  border-radius: 12px;
`;

export default LoginBanner;
