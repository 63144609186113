import styled from 'styled-components';

const StyledBankBoxes = styled.div`
  .boxes {
    display: flex;
    justify-content: start;
    margin-bottom: 48px;
    margin-top: 50px;
  }

  .alice-carousel {
    height: 235px;

    &__stage-item {
      width: auto !important;
    }

    &__stage {
      top: 24px;
    }
    &__wrapper {
      height: 195px;
    }
    &__dots {
      margin: 0px;
      margin-top: 0px;
    }
    &__control-btn {
      position: absolute;
      top: 83px;
      cursor: pointer;
      color: var(--chakra-colors-tertiary-110);
      background: transparent;
      transition: all 0.3s ease-in-out;
      width: 32px;
      height: 32px;
      padding: 0px;
      min-width: 32px;

      & .chakra-button__icon {
        margin: 0px;
        & svg {
          height: 32px;
          width: 32px;
        }
      }

      &:hover {
        background-color: var(--chakra-colors-grey-40);
        border-radius: 8px;
      }
    }
    &__prev-btn {
      padding: 0px;
      width: 0px;
      & button {
        left: -32px;

        &:focus {
          background: transparent;
        }
      }
    }
    &__next-btn {
      padding: 0px;
      width: 0px;
      & button {
        right: -32px;

        &:focus {
          background: transparent;
        }
      }
    }
  }

  .carousel-bank-boxes {
    display: flex;
    justify-content: start;

    & .account-box {
      margin-right: 24px;
    }
  }
`;

const StyledCarouselDot = styled.div`
  margin: 0 5px;

  &.active-dot {
    background: var(--chakra-colors-tertiary-110);
    border-radius: 99px;
    height: 10px;
    width: 25px;
  }
  &.inactive-dot {
    background: var(--chakra-colors-secondary-60);
    border-radius: 50%;
    height: 10px;
    width: 10px;
  }
`;

const StyledBankBoxesContainer = styled.div`
  margin-right: 30px;
  #bank-boxes-container {
    margin-right: 9px;
  }
`;

const StyledBankBoxesErrorSection = styled.div`
  padding: 31px 0px;

  p {
    margin: 0px;
  }
`;

export { StyledBankBoxes, StyledBankBoxesContainer, StyledBankBoxesErrorSection, StyledCarouselDot };
