import { Avatar, Tooltip } from '@chakra-ui/react';
import React from 'react';

import defaultBankLogo from '../../assets/images/defaultBankLogo.svg';
import { Banks } from '../../Interfaces/IBankBoxes';
import bankNameFormat from '../../utils/bankNameInitial';

interface BankLogo {
  bank: Banks;
  showTooltip?: boolean;
  shouldRenderDefaultLogo?: boolean;
}

function Logo({ bank, showTooltip, shouldRenderDefaultLogo }: BankLogo) {
  return showTooltip ? (
    <Tooltip label={bank.bcoNomcor} hasArrow placement='top'>
      <Avatar
        data-testid='bankLogo'
        className='bankLogo'
        name={bankNameFormat(bank.bcoNomcor)}
        style={{ zIndex: 2 }}
        src={`${process.env.URL_BANK_LOGOS}logo${bank.bcoIdbco}.png`}
        bg='tertiary.30'
      />
    </Tooltip>
  ) : (
    <Avatar
      data-testid='bankLogo'
      className='bankLogo'
      style={{ zIndex: 2 }}
      name={bankNameFormat(bank.bcoNomcor)}
      src={shouldRenderDefaultLogo ? defaultBankLogo : `${process.env.URL_BANK_LOGOS}logo${bank.bcoIdbco}.png`}
      bg='tertiary.30'
    />
  );
}

export default Logo;
