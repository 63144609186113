import { Badge, Button, Image, Skeleton, Text } from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { OpenLink, pushAnalyticsEvent } from '@react-ib-mf/style-guide-ui';
import React, { useEffect } from 'react';

import consumptionIcon from '../../assets/icons/consumption.png';
import { ConsumptionPlanBannerProps } from '../../Interfaces/IConsumption';
import plainText from '../../lang/es.json';
import { formatNumberDecimals, isNumeric } from '../../utils/numbers';
import ErrorSection from '../ErrorSection';
import StyledConsumptionPlanBanner from './styled';

function ConsumptionPlanBanner({
  consumptionPlanBannerDataResponse,
  consumptionPlanBannerStatus,
  consumptionPlanBannerRefetch,
  showContentPlanBanner,
  setShowContentPlanBanner,
}: ConsumptionPlanBannerProps) {
  const pushAnalyticsEventUpgradePlan = () => {
    pushAnalyticsEvent({
      event: 'upgrade_plan',
      content_group: 'Mi consumo',
      content_type: 'Información plan',
      item_id: 'Mejora tu plan',
    });
  };
  const CONTRACTED_PLAN_URL = 'menuRoot%3Dadmin.abm%26menuSelection%3Dadmin%7Cabm%7CadmOfferingContratado';

  const parseAmounts = amount => {
    if (isNumeric(amount)) return `$ ${formatNumberDecimals({ numberToFormat: Number(amount), decimalsPlaces: 2 })}`;
    return amount;
  };

  useEffect(() => {
    if (
      consumptionPlanBannerStatus === 'error' ||
      (consumptionPlanBannerStatus === 'success' &&
        (!consumptionPlanBannerDataResponse?.denomination ||
          (!consumptionPlanBannerDataResponse?.previous?.amount && !consumptionPlanBannerDataResponse?.amount)))
    ) {
      setShowContentPlanBanner(false);
    } else {
      setShowContentPlanBanner(true);
    }
    // We decided not add the useState setter in the array then I left this comment.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consumptionPlanBannerStatus, consumptionPlanBannerDataResponse]);

  if (!showContentPlanBanner) {
    return (
      <ErrorSection
        showErrorPicture={false}
        titleText={plainText.consumption.errorPage.title}
        titleDataTestId=''
        descriptionText={plainText.consumption.errorPage.description}
        descriptionDataTestId=''
        buttonText={plainText.consumption.errorPage.buttonText}
        buttonDataTestId=''
        callbackAction={consumptionPlanBannerRefetch}
      />
    );
  }

  return (
    <StyledConsumptionPlanBanner>
      <Skeleton isLoaded={consumptionPlanBannerStatus === 'success'} height='90px' mb={8} startColor='grey.50' endColor='grey.30'>
        <div className='consumption-plan-banner' data-testid='div-consumptionPlanBanner'>
          <div className='consumption-plan-banner__hired-plan'>
            <Image className='consumption-plan-banner__hired-plan__icon' data-testid='avatar-consumptionPlanBanner' src={consumptionIcon} />

            <div className='consumption-plan-banner__hired-plan__text'>
              <Text textStyle='overlineLg' className='unmargined-text' data-testid='txt-consumptionPlanBannerHiredPlanTitle'>
                {plainText.consumption.planBanner.hiredPlanTitle}
              </Text>

              <Text textStyle='h6' className='unmargined-text' data-testid='txt-consumptionPlanBannerHiredPlanText'>
                {consumptionPlanBannerDataResponse?.denomination}
              </Text>
            </div>
          </div>

          <div className='consumption-plan-banner__monthly-payment'>
            <Text textStyle='overlineLg' className='unmargined-text' data-testid='txt-consumptionPlanBannerMonthlyPaymentTitle'>
              {plainText.consumption.planBanner.monthlyPaymentTitle}
            </Text>

            <Text textStyle='titleMdBold' className='unmargined-text' data-testid='txt-consumptionPlanBannerMonthlyPaymentText'>
              {consumptionPlanBannerDataResponse?.bonification
                ? parseAmounts(consumptionPlanBannerDataResponse?.previous?.amount)
                : parseAmounts(consumptionPlanBannerDataResponse?.amount)}
            </Text>
          </div>

          {consumptionPlanBannerDataResponse?.bonification ? (
            <div className='consumption-plan-banner__bonus-data'>
              <div className='consumption-plan-banner__bonus-data__upper-data'>
                <Badge variant='success-light' data-testid='badge-consumptionPlanBannerBonus'>
                  {consumptionPlanBannerDataResponse?.bonification?.title}
                </Badge>

                <Text textStyle='labelXs' className='unmargined-text' data-testid='txt-consumptionPlanBannerBonusDate'>
                  {plainText.consumption.planBanner.bonusDateLegend} {consumptionPlanBannerDataResponse?.bonification?.endDate}
                </Text>
              </div>

              <Text textStyle='labelXs' className='unmargined-text' data-testid='txt-consumptionPlanBannerBonusPrice'>
                {plainText.consumption.planBanner.bonusPriceLegend} {parseAmounts(consumptionPlanBannerDataResponse?.amount)}
              </Text>
            </div>
          ) : (
            <div className='consumption-plan-banner__bonus-data' style={{ minWidth: '78px' }} />
          )}

          <div className='consumption-plan-banner__upgrade-limit'>
            <Text textStyle='labelLg' className='unmargined-text' data-testid='txt-consumptionPlanBannerUpgrade'>
              {consumptionPlanBannerDataResponse?.hasNextOffering ? plainText.consumption.planBanner.upgradeLimits : ''}
            </Text>
          </div>

          <OpenLink url={CONTRACTED_PLAN_URL} openMode='urlComplete' reuseTab>
            <Button
              onClick={pushAnalyticsEventUpgradePlan}
              variant='primary-text'
              data-testid='btn-consumptionPlanBannerUpgrade'
              className='consumption-plan-banner__upgrade-plan'
            >
              {consumptionPlanBannerDataResponse?.hasNextOffering ? plainText.button.upgradePlan : ''}
            </Button>
          </OpenLink>
        </div>
      </Skeleton>
    </StyledConsumptionPlanBanner>
  );
}

export default ConsumptionPlanBanner;
