import { CategoryScale, Chart as ChartJS, Filler, Legend, LinearScale, LineElement, PointElement, Title, Tooltip } from 'chart.js';
import { Line } from 'react-chartjs-2';

import { ConsolidadoSaldo } from '../../Interfaces/IConsolidateBalance';
import plainText from '../../lang/es.json';
import currenncyFormat from '../../utils/currenncy';
import { ConsolidateBalanceStyledBox } from './styled';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

function ConsolidatedBalances({ data }: { data: ConsolidadoSaldo }) {
  const style = getComputedStyle(document.body);
  const { saldoInicioDia, proyeccion24Hs, proyeccion48Hs } = data;

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          color: '',
        },
      },
      y: {
        grid: {
          color: style?.getPropertyValue('--chakra-colors-grey-60'),
        },
        ticks: {
          callback(value) {
            return currenncyFormat.currencyFormatDE(value);
          },
          autoSkip: true,
          maxRotation: 0,
          minRotation: 0,
          display: true,
          maxTicksLimit: 10,
        },
        min: Math.min(saldoInicioDia, proyeccion24Hs, proyeccion48Hs, 0),
      },
      legend: {
        display: false,
      },
    },
  };

  const chartData = {
    labels: [plainText.label.operating, plainText.label.twentyfourH, plainText.label.fortyeightH],
    showTooltips: true,
    datasets: [
      {
        label: plainText.label.balance,
        data: [saldoInicioDia, proyeccion24Hs, proyeccion48Hs],
        fill: true,
        backgroundColor: style?.getPropertyValue('--chakra-colors-tertiary-40'),
        borderColor: style?.getPropertyValue('--chakra-colors-tertiary-60'),
      },
    ],
  };

  return (
    <ConsolidateBalanceStyledBox>
      <div className='graph-style'>
        <Line data={chartData} options={options} />
      </div>
    </ConsolidateBalanceStyledBox>
  );
}

export default ConsolidatedBalances;
