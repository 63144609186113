import { Text } from '@chakra-ui/react';
import React from 'react';

import StyledPermissionMessage from './styled';

interface PermissionMessageIconProps {
  icon: string;
  title: string;
  message: string;
}

export default function PermissionMessageIcon({ icon, title, message }: PermissionMessageIconProps) {
  return (
    <StyledPermissionMessage>
      <img src={icon} alt='Icon' />
      <Text textStyle='h4Bold'>{title}</Text>
      <Text textStyle='bodyLg'>{message}</Text>
    </StyledPermissionMessage>
  );
}
