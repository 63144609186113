import { Button, Image, Link, Text } from '@chakra-ui/react';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import notFoundImage from '../../assets/images/not-found.png';
import IErrorSection from '../../Interfaces/IErrorSection';
import StyledErrorSection from './styled';

function ErrorSection({
  errorPicture,
  errorPictureDataTestId,
  showErrorPicture = true,
  titleText,
  titleDataTestId,
  descriptionText,
  descriptionDataTestId,
  buttonText,
  buttonDataTestId,
  callbackAction,
  redirectTo,
  redirectCallbackAction,
  titleStyle,
  descriptionStyle,
}: IErrorSection) {
  return (
    <StyledErrorSection>
      <div className='error-section'>
        {showErrorPicture && (
          <Image className='error-section__image' data-testid={errorPictureDataTestId} src={errorPicture || notFoundImage} />
        )}

        <Text textStyle='h4Bold' style={titleStyle} className='error-section__title' data-testid={titleDataTestId}>
          {titleText || 'No podemos mostrar la página'}
        </Text>

        <Text textStyle='bodyLg' style={descriptionStyle} className='error-section__description' data-testid={descriptionDataTestId}>
          {descriptionText}
        </Text>

        {/* If a callback is passed as param it is executed onclick */}
        {callbackAction && (
          <Button variant='primary-text' onClick={() => callbackAction()} className='error-section__action' data-testid={buttonDataTestId}>
            {buttonText || 'Ir al Inicio'}
          </Button>
        )}

        {/* Otherwise if a redirect is passed as param it is redirected onclick */}
        {redirectTo && (
          <Link
            onClick={() => redirectCallbackAction()}
            as={RouterLink}
            className='error-section__action'
            to={redirectTo}
            data-testid={buttonDataTestId}
          >
            {buttonText || 'Ir al Inicio'}
          </Link>
        )}
      </div>
    </StyledErrorSection>
  );
}

export default ErrorSection;
