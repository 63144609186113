interface FormatNumberDecimalsArgs {
  numberToFormat: number | string;
  decimalsPlaces?: number;
  defaultValue?: string;
}

export const isNumeric = (value: number | string) => value !== null && `${value}`.trim().length > 0 && !Number.isNaN(+value);

export const parseNumber = (number: string) => {
  if (!isNumeric(number)) return number;

  return +number;
};

export const formatNumberDecimals = ({ numberToFormat, decimalsPlaces = 2, defaultValue }: FormatNumberDecimalsArgs): string => {
  if (!isNumeric(numberToFormat)) return defaultValue ?? (numberToFormat as string);

  return Intl.NumberFormat('de-DE', { maximumFractionDigits: decimalsPlaces, minimumFractionDigits: decimalsPlaces }).format(
    +numberToFormat,
  );
};
