import { Button as ChakraButton } from '@chakra-ui/react';

interface ButtonProps {
  text: string;
  onClick?: () => void;
}

function Button({ text, onClick }: ButtonProps) {
  return (
    <ChakraButton onClick={onClick} px='6' py='2' _focus={{ boxShadow: 'none' }}>
      {text}
    </ChakraButton>
  );
}

export default Button;
